/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKOnDemandSpecialty from '../../../model/practice/awsdk_on_demand_specialty';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Wrapper around a collection of {@link model.AWSDKOnDemandSpecialty}s
 * @property {model.AWSDKOnDemandSpecialty[]} specialties the {@link model.AWSDKOnDemandSpecialty}s
 * @extends model.AWSDKResponse
 */
class AWSDKOnDemandSpecialtiesResponse extends AWSDKResponse {
  get specialties() {
    return this.__data.specialties.map(item => GenericParser.parseObject(item, AWSDKOnDemandSpecialty));
  }
}

export default AWSDKOnDemandSpecialtiesResponse;
