/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKError from './../error/awsdk_error';
import AWSDKConsumerResponse from '../internal/model/response/awsdk_consumer_response';
import AWSDKHealthSummaryResponse from '../internal/model/response/awsdk_health_summary_response';
import AWSDKDependentResponse from '../internal/model/response/awsdk_dependent_response';
import Service from './service';
import Validator from '../internal/validator/validator';
import AWSDKConsumerRegistration from '../model/registration/awsdk_consumer_registration';
import AWSDKDependentRegistration from '../model/registration/awsdk_dependent_registration';
import AWSDKRegistrationDisclaimerResponse from '../internal/model/response/awsdk_registration_disclaimer_response';
import AWSDKResponse from '../internal/model/response/awsdk_response';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKPaymentMethodResponse from '../internal/model/response/awsdk_payment_method_response';
import AWSDKVisitReportDetailResponse from '../internal/model/response/awsdk_visit_report_detail_response';
import AWSDKPaymentRequest from '../model/billing/awsdk_payment_request';
import AWSDKCondition from '../model/health/awsdk_condition';
import AWSDKConditionsResponse from '../internal/model/response/awsdk_conditions_response';
import AWSDKVitalsResponse from '../internal/model/response/awsdk_vitals_response';
import AWSDKMedicationList from '../model/medication/awsdk_medication_list';
import AWSDKMedication from '../model/medication/awsdk_medication';
import AWSDKVitals from '../model/health/awsdk_vitals';
import AWSDKAllergiesResponse from '../internal/model/response/awsdk_allergies_response';
import AWSDKAllergy from '../model/health/awsdk_allergy';
import AWSDKSubscriptionUpdate from '../model/insurance/awsdk_subscription_update';
import AWSDKSubscriptionResponse from '../internal/model/response/awsdk_subscription_response';
import AWSDKHealthPlan from '../model/insurance/awsdk_health_plan';
import AWSDKConsumerUpdate from '../model/consumer/awsdk_consumer_update';
import AWSDKVisitReport from '../model/visit/awsdk_visit_report';
import AWSDKAddressUpdate from '../model/awsdk_address_update';
import AWSDKAddressResponse from '../internal/model/response/awsdk_address_response';
import AWSDKDependentUpdate from '../model/consumer/awsdk_dependent_update';
import AWSDKAuthentication from '../model/authentication/awsdk_authentication';
import AWSDKHealthDocumentRecord from '../model/health/awsdk_health_document_record';
import AWSDKHealthDocumentAttachment from '../model/health/awsdk_health_document_attachment';
import AWSDKHealthDocumentRecordResponse from '../internal/model/response/awsdk_health_document_record_response';
import AWSDKRelationshipToSubscriberCode from '../model/insurance/awsdk_relationship_to_subscriber_code';
import AWSDKEligibilityResponse from '../internal/model/response/awsdk_eligibility_response';
import AWSDKConsumerMiddleNameHandling from '../model/consumer/awsdk_consumer_middle_name_handling';
import AWSDKGender from '../model/consumer/awsdk_gender';
import Util from '../internal/util/util';
import AWSDKDependentsListResponse from '../internal/model/response/awsdk_dependents_list_response';
import AWSDKDependentAccessRequestResponse from '../internal/model/response/awsdk_dependent_access_request_response';
import AWSDKDependentAccessRequest from '../model/consumer/awsdk_dependent_access_request';
import AWSDKNotificationsResponse from '../internal/model/response/awsdk_notifications_response';
import AWSDKConsumerOverrideDetails from '../model/consumer/awsdk_consumer_override_details';
import AWSDKVisit from '../model/visit/awsdk_visit';
import AWSDKBaseConsumerUpdate from '../model/consumer/awsdk_base_consumer_update';
import AWSDKTrackerEntriesList from '../internal/model/response/awsdk_tracker_entries_list';
import AWSDKTrackerRequest from '../model/tracker/awsdk_tracker_request';
import AWSDKTrackerEntryRequest from '../model/tracker/awsdk_tracker_entry_request';
import AWSDKTrackerDataPointRequest from '../model/tracker/awsdk_tracker_data_point_request';
import AWSDKTrackersSearchCriteria from '../model/tracker/awsdk_trackers_search_criteria';
import AWSDKDeviceIntegrationRequest from '../model/device_integration/awsdk_device_integration_request';
import AWSDKExamDataRequest from '../model/device_integration/awsdk_exam_data_request';
import AWSDKTwoFactorConfiguration from '../model/authentication/awsdk_two_factor_configuration';
import AWSDKPostVisitFollowUpItemsTypeFilter from '../model/post_visit_follow_up_items/awsdk_post_visit_follow_up_items_type_filter';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKPaginatedPostVisitFollowUpItemsResponse from '../internal/model/response/awsdk_paginated_post_visit_follow_up_items_response';
import AWSDKPostVisitFollowUpItem from '../model/post_visit_follow_up_items/awsdk_post_visit_follow_up_item';
import AWSDKPaginatedVisitReportsResponse from '../internal/model/response/awsdk_paginated_visit_reports_response';
import AWSDKDisposition from '../model/visit/awsdk_disposition';
import AWSDKPaginatedHealthDocumentRecordsResponse
  from '../internal/model/response/awsdk_paginated_health_document_records_response';
import AWSDKPostVisitFollowUpItemType from '../model/post_visit_follow_up_items/awsdk_post_visit_follow_up_item_type';
import AWSDKPaginatedOnlineVisitFollowUpItemsResponse from '../internal/model/response/awsdk_paginated_online_visit_follow_up_items_response';
import AWSDKOnlineVisitFollowUpItem from '../model/visit/awsdk_online_visit_follow_up_item';
import AWSDKPaginatedPracticeFollowUpItemsResponse from '../internal/model/response/awsdk_paginated_practice_follow_up_items_response';
import AWSDKPracticeFollowUpItem from '../model/visit/awsdk_practice_follow_up_item';
import getMimeType from '../internal/util/mimeTypes';

/**
 * This service handles everything related to a {@link model.AWSDKConsumer|AWSDKConsumer} and supporting infrastructure
 *
 * @since 1.0.0
 * @hideconstructor
 * @extends service.Service
 */
class ConsumerService extends Service {
  constructor(props) {
    super(props);
    const currentFunction = 'ConsumerService.constructor';
    this.__logger.debug(currentFunction, 'Started', props);
    this.__systemConfiguration = props.systemConfiguration;
    this.__countries = props.countries;
    this.__creditCardTypes = props.creditCardTypes;
    if (this.__config.eligibilityPollingInterval == null) {
      this.__config.eligibilityPollingInterval = 3000;
    }
    if (this.__config.eligibilityPollingTimeout == null) {
      this.__config.eligibilityPollingTimeout = 20000;
    }
    // Vitals limits
    this.__minValueVitals = 0.0;
    this.__maxTemperatureValue = 120.0;
    this.__maxSystolicValue = 250.0;
    this.__maxDiastolicValue = 250.0;

	this.__maxTotalWeightMeasurement = this.__systemConfiguration.maxTotalWeightMeasurement || 1500;
	this.__maxTotalHeightMeasurement = this.__systemConfiguration.maxTotalHeightMeasurement || 10;
	this.__maxWeightMinorMeasurement = this.__systemConfiguration.maxWeightMinorMeasurement || 15;
	this.__maxHeightMinorMeasurement = this.__systemConfiguration.maxHeightMinorMeasurement || 11;

	this.__weightMeasurementTotalMustBeGreaterThan = this.__systemConfiguration.weightMeasurementTotalMustBeGreaterThan || 0;
	this.__heightMeasurementTotalMustBeGreaterThan = this.__systemConfiguration.heightMeasurementTotalMustBeGreaterThan || 0;
	this.__minWeightMinorMeasurement = this.__systemConfiguration.minWeightMinorMeasurement || 0;
	this.__minHeightMinorMeasurement = this.__systemConfiguration.minHeightMinorMeasurement || 0;

  }

  /**
   * Retrieve the {@link model.AWSDKConsumer|AWSDKConsumer} details for the given consumer, via {@link model.AWSDKAuthentication|AWSDKAuthentication}
   * retrieved via {@link service.AuthenticationService#authenticateConsumerWithUsername|AuthenticationService.authenticateConsumerWithUsername} or {@link service.AuthenticationService#authenticateMutualAuthWithToken|AuthenticationService.authenticateMutualAuthWithToken} <br>
   * You must make sure {@link model.AWSDKAuthentication#needsToCompleteRegistration|AWSDKAuthentication#needsToCompleteRegistration} is false for this to
   * succeed.  If it's true you must call {@link service.ConsumerService#completeRegistration} <br>
   *
   * @param {model.AWSDKAuthentication} authentication REQUIRED
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKConsumer|AWSDKConsumer} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If authentication is null or not an valid instance of AWSDKAuthentication</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  getConsumer(authentication) {
    const currentFunction = 'ConsumerService.getConsumer';
    this.__logger.debug(currentFunction, 'Started', authentication);
    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is null or not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(authentication.authenticationLinks, 'memberDetails');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('authentication does not have a valid "memberDetails" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(authentication.consumer);
    return this.executeRequest(options, AWSDKConsumerResponse)
      .then((consumerResponse) => {
        this.__logger.debug(currentFunction, 'Got response', consumerResponse);
        this.updateUserAuthEntry(consumerResponse.consumer, consumerResponse.authToken);
        return consumerResponse.consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieve the updated {@link model.AWSDKConsumer|AWSDKConsumer} details for the given consumer<br>
   *
   * @param {model.AWSDKConsumer} consumer the consumer whose updated information we are retrieving
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKConsumer|AWSDKConsumer} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an valid instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getUpdatedConsumer(consumer) {
    const currentFunction = 'ConsumerService.getUpdatedConsumer';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(consumer.href)) {
      const error = AWSDKError.AWSDKInternalError('The consumer argument does not contain a valid href property');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', consumer.href);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (consumer.isDependent) {
      return this.executeRequest(options, AWSDKDependentResponse)
        .then((dependentResponse) => {
          this.__logger.debug(currentFunction, 'Got response', dependentResponse);
          this.updateUserAuthEntry(dependentResponse.dependent, dependentResponse.authToken);
          return dependentResponse.dependent;
        })
        .catch((error) => {
          this.__logger.error(currentFunction, 'Error', error);
          throw error;
        });
    }

    return this.executeRequest(options, AWSDKConsumerResponse)
      .then((consumerResponse) => {
        this.__logger.debug(currentFunction, 'Got response', consumerResponse);
        this.updateUserAuthEntry(consumerResponse.consumer, consumerResponse.authToken);
        return consumerResponse.consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} to use for registering a new consumer. <br>
   * The {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateRegistration|validateRegistration} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#registerConsumer|registerConsumer} which will always validate it<br>
   * @returns {model.AWSDKConsumerRegistration} returns an instance of a {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} object
   */
  newConsumerRegistration() {
    return new AWSDKConsumerRegistration();
  }

  /**
   * Get an instance of {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} to use for updating an existing consumer <br>
   * The {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateConsumerUpdate|validateConsumerUpdate} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#updateConsumer|updateConsumer} which will always validate it<br>
   * @returns {model.AWSDKConsumerUpdate} returns an instance of a {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} object
   */
  newConsumerUpdate() {
    return new AWSDKConsumerUpdate();
  }

  /**
   * Get an instance of {@link model.AWSDKDependentRegistration|AWSDKDependentRegistration} to use for registering a new dependent. <br>
   * The {@link model.AWSDKDependentRegistration|AWSDKDependentRegistration} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateDependentRegistration(model.AWSDKDependentRegistration)|validateDependentRegistration(dependentRegistration)} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#registerDependent(model.AWSDKDependentRegistration)|registerConsumer(consumerRegistration)} which will always validate it<br>
   * @returns {model.AWSDKDependentRegistration} returns an instance of a {@link model.AWSDKDependentRegistration|AWSDKDependentRegistration} object
   */
  newDependentRegistration() {
    return new AWSDKDependentRegistration();
  }

  /**
   * Get an instance of {@link model.AWSDKDependentUpdate|AWSDKDependentUpdate} to use for updating a dependent. <br>
   * The {@link model.AWSDKDependentUpdate|AWSDKDependentUpdate} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateDependentUpdate(model.AWSDKDependentUpdate)|validateDependentUpdate(dependentUpdate)} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#updateDependent(model.AWSDKDependentUpdate)|updateDependent(dependentUpdate)} which will always validate it<br>
   * @returns {model.AWSDKDependentUpdate} returns an instance of a {@link model.AWSDKDependentUpdate|AWSDKDependentUpdate} object
   */
  newDependentUpdate() {
    return new AWSDKDependentUpdate();
  }

  /**
   * Get an instance of {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} to use for updating the subscription for a member. <br>
   * The {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateSubscriptionUpdate(model.AWSDKSubscriptionUpdate, model.AWSDKConsumer)|validateSubscriptionUpdate(subscriptionUpdate, consumer)} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#updateInsuranceSubscription( model.AWSDKConsumer, model.AWSDKSubscriptionUpdate)|updateInsuranceSubscription(consumer, subscriptionUpdate)} which will always validate it<br>
   * @returns {model.AWSDKSubscriptionUpdate} returns an instance of a {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} object
   */
  newSubscriptionUpdate() {
    return new AWSDKSubscriptionUpdate(false);
  }

  /**
   * Get an instance of {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} to use for updating the subscription for a member. This method is intended for use during the Visit intake flow.<br>
   * Note: when in the context of a Visit, the "ignoreEligibilityChecks" flag on {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} is set to TRUE as the EDI checks happen during cost calculations.<br>
   * The {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} object obtained here can be validated by an (optional) call to the {@link service.ConsumerService#validateSubscriptionUpdate(model.AWSDKSubscriptionUpdate, model.AWSDKConsumer)|validateSubscriptionUpdate(subscriptionUpdate, consumer)} directly or <br>
   * indirectly via a call to {@link service.ConsumerService#updateInsuranceSubscription( model.AWSDKConsumer, model.AWSDKSubscriptionUpdate)|updateInsuranceSubscription(consumer, subscriptionUpdate)} which will always validate it<br>
   * @returns {model.AWSDKSubscriptionUpdate} returns an instance of a {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} object
   */
  newVisitSubscriptionUpdate() {
    return new AWSDKSubscriptionUpdate(true);
  }

  /**
   * Retrieve the registration disclaimer {@link model.AWSDKDisclaimer|AWSDKDisclaimer}<br>
   * Show this to the consumer before registration and record whether or not they accepted it. <br>
   * @returns {Promise<model.AWSDKDisclaimer|error.AWSDKError>} Returns a Promise that resolves to a {@link model.AWSDKDisclaimer|AWSDKDisclaimer} <br>
   * or gets rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getRegistrationDisclaimer() {
    const currentFunction = 'ConsumerService.getRegistrationDisclaimer';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'enrollmentDisclaimer');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "enrollmentDisclaimer" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKRegistrationDisclaimerResponse)
      .then((registrationDisclaimerResponse) => {
        this.__logger.debug(currentFunction, 'Got response', registrationDisclaimerResponse);
        return registrationDisclaimerResponse.registrationDisclaimer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Validate the {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} object<br>
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>legalResidence</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>legalResidence</td>
   * <td>invalid field value</td>
   * <td>Value must be in the list of supported state codes for a legal residence</td>
   * </tr>
   * <tr>
   * <td>email</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>email</td>
   * <td>invalid field format</td>
   * <td>At least 5 chars long, and valid email pattern</td>
   * </tr>
   * <tr>
   * <td>phone</td>
   * <td>invalid field format</td>
   * <td>Must have format '[\\+]?[0-9.-]+' </td>
   * </tr>
   * <tr>
   * <td>address</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration.consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>invalid field format</td>
   * <td>If required, then must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>address2</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. Can be null, but if not, must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. At least 2 char long</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>invalid field value</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. If so, Value must be in the list of supported state codes for address.</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} and {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>invalid field value</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} and {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true. If so, Value must be in the list of supported country codes for address.</td>
   * </tr>
   * <tr>
   * <td>zipCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>zipCode</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. If so, format must be: nnnnn or nnnnn-nnnn</td>
   * </tr>
   * <tr>
   * <td>hasAcceptedDisclaimer</td>
   * <td>wrong field value</td>
   * <td>must be TRUE to register</td>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>invalid field format</td>
   * <td>At least 1 char long</td>
   * </tr>
   * <tr>
   * <td>middleInitial</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'INITIAL'. Can be blank or at most 1 character long. </td>
   * </tr>
   * <tr>
   * <td>middleName</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'FULLNAME'. Can be blank or at most 100 characters long. </td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>invalid field format</td>
   * <td>At least 2 chars long</td>
   * </tr>
   * <tr>
   * <td>gender</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>invalid field format</td>
   * <td>Must be valid date</td>
   * </tr>
   * <tr>
   * <td>sdkUserId</td>
   * <td>invalid field format</td>
   * <td>Must be a non-empty String</td>
   * </tr>
   * <tr>
   * <td>brandings</td>
   * <td>invalid field format</td>
   * <td>Must be an Array of String objects</td>
   * </tr>
   * </table>
   *
   * @param {model.AWSDKConsumerRegistration} consumerRegistration object to validate
   * @throws {error.AWSDKError} if consumerRegistration is null or not an instance of {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} errorCode will be {@link error.AWSDKErrorCode|AWSDKErrorCode.illegalArgument}
   * @throws {error.AWSDKError} if patientMrnId, phone, address2, or (depending on the value of {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling}) middleName, or middleInitial is not set to at least an empty string ( errorCode will be {@link error.AWSDKErrorCode|AWSDKErrorCode.illegalArgument}
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   */
  validateRegistration(consumerRegistration) {
    const currentFunction = 'ConsumerService.validateRegistration';
    this.__logger.debug(currentFunction, 'Started', consumerRegistration);
    const errArrayResult = [];
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (!(consumerRegistration instanceof AWSDKConsumerRegistration)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumerRegistration is not instance of AWSDKConsumerRegistration');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (!Validator.isStringType(consumerRegistration.phone)) {
      const error = AWSDKError.AWSDKIllegalArgument('phone must be a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (this.__systemConfiguration.consumerAddressRequired && !Validator.isStringType(consumerRegistration.address2)) {
      const error = AWSDKError.AWSDKIllegalArgument('address2 must be a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.INITIAL
      && !Validator.isStringType(consumerRegistration.middleInitial)) {
      const error = AWSDKError.AWSDKIllegalArgument('middleInitial must be a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.FULLNAME
      && !Validator.isStringType(consumerRegistration.middleName)) {
      const error = AWSDKError.AWSDKIllegalArgument('middleName must be a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (!consumerRegistration.hasAcceptedDisclaimer) {
      fieldName = 'hasAcceptedDisclaimer';
      reason = 'field is set to wrong value';
      recoverySuggestion = 'Must be true to register. Consumer must accept Terms Of Service to register';
      errArrayResult.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerRegistration, fieldName, reason, recoverySuggestion));
    }
    this.__validateConsumerUpdate(true, consumerRegistration, errArrayResult);
    this.__logger.trace(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Validate the {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} object<br>
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>email</td>
   * <td>invalid field format</td>
   * <td>At least 5 chars long, and valid email pattern</td>
   * </tr>
   * <tr>
   * <td>phone</td>
   * <td>invalid field format</td>
   * <td>Must have format '[\\+]?[0-9.-]+' </td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>invalid field format</td>
   * <td>If required, then must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>address2</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. Can be null, but if not, must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. At least 2 char long</td>
   * </tr>
   * <tr>
   * <td>zipCode</td>
   * <td>invalid field format</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. If so, format must be: nnnnn or nnnnn-nnnn</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>invalid field value</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} is true. If so, Value must be in the list of supported state codes for address.</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} and {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>invalid field value</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#consumerAddressRequired|AWSDKSystemConfiguration.consumerAddressRequired} and {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true. If so, Value must be in the list of supported country codes for address.</td>
   * </tr>
   * <tr>
   * <td>legalResidence</td>
   * <td>invalid field value</td>
   * <td>Value must be in the list of supported state codes for a legal residence</td>
   * </tr>
   * <tr>
   * <td>legalResidenceCountryCode</td>
   * <td>invalid field value</td>
   * <td>Value must be in the list of supported country codes for a legal residence</td>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>invalid field format</td>
   * <td>At least 1 char long</td>
   * </tr>
   * <tr>
   * <td>middleInitial</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'INITIAL'. Can be blank or at most 1 character long. </td>
   * </tr>
   * <tr>
   * <td>middleName</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'FULLNAME'. Can be blank or at most 100 characters long. </td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>invalid field format</td>
   * <td>At least 2 chars long</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>invalid field format</td>
   * <td>Must be valid date</td>
   * </tr>
   * <tr>
   * <td>sdkUserId</td>
   * <td>invalid field format</td>
   * <td>Must be a non-empty String</td>
   * </tr>
   * </table>
   *
   * @param {model.AWSDKConsumerUpdate} consumerUpdate object to validate
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   */
  validateConsumerUpdate(consumerUpdate) {
    const currentFunction = 'ConsumerService.validateConsumerUpdate';
    this.__logger.debug(currentFunction, 'Started', consumerUpdate);
    const errArrayResult = [];
    this.__validateConsumerUpdate(false, consumerUpdate, errArrayResult);
    this.__logger.trace(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Validate the {@link model.AWSDKDependentRegistration|AWSDKDependentRegistration} object<br>
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>invalid field format</td>
   * <td>At least 1 char long</td>
   * </tr>
   * <tr>
   * <td>middleInitial</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'INITIAL'. Can be blank or at most 1 character long. </td>
   * </tr>
   * <tr>
   * <td>middleName</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'FULLNAME'. Can be blank or at most 100 characters long. </td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>invalid field format</td>
   * <td>At least 2 chars long</td>
   * </tr>
   * <tr>
   * <td>gender</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>invalid field format</td>
   * <td>Must be valid date</td>
   * </tr>
   * <tr>
   * <td>sdkUserId</td>
   * <td>invalid field format</td>
   * <td>Must be a non-empty String</td>
   * </tr>
   * </table>
   *
   * @param {model.AWSDKDependentRegistration} dependentRegistration object to validate
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   */
  validateDependentRegistration(dependentRegistration) {
    const currentFunction = 'ConsumerService.validateDependentRegistration';
    this.__logger.debug(currentFunction, 'Started', dependentRegistration);
    const errArrayResult = [];
    this.__validateBaseConsumerUpdate(true, dependentRegistration, errArrayResult);
    this.__logger.trace(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Validate the {@link model.AWSDKDependentUpdate|AWSDKDependentUpdate} object<br>
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>invalid field format</td>
   * <td>At least 1 char long</td>
   * </tr>
   * <tr>
   * <td>middleInitial</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'INITIAL'. Can be blank or at most 1 character long. </td>
   * </tr>
   * <tr>
   * <td>middleName</td>
   * <td>invalid field format</td>
   * <td>Only allowed if {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'FULLNAME'. Can be blank or at most 100 characters long. </td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>invalid field format</td>
   * <td>At least 2 chars long</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>invalid field format</td>
   * <td>Must be valid date</td>
   * </tr>
   * <tr>
   * <td>sdkUserId</td>
   * <td>invalid field format</td>
   * <td>Must be a non-empty String</td>
   * </tr>
   * <tr>
   * <td>genderIdentityKey</td>
   * <td>invalid field format</td>
   * <td>Must be null, empty or non-whitespace-only string</td>
   * </tr>
   * </table>
   *
   * @param {model.AWSDKDependentUpdate} dependentUpdate object to validate
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   */
  validateDependentUpdate(dependentUpdate) {
    const currentFunction = 'ConsumerService.validateDependentUpdate';
    this.__logger.debug(currentFunction, 'Started', dependentUpdate);
    const errArrayResult = [];
    this.__validateBaseConsumerUpdate(false, dependentUpdate, errArrayResult);
    this.__logger.trace(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __checkConsumerProtectedFields(consumer, consumerUpdate) {
    const modifiedProtectedFields = [];
    if (this.__checkModifiedField(consumer.email, consumerUpdate.email) && this.isFieldProtected(consumer, 'email')) {
      modifiedProtectedFields.push('email');
    }
    if (this.__checkModifiedField(consumer.phone, consumerUpdate.phone) && this.isFieldProtected(consumer, 'phone')) {
      modifiedProtectedFields.push('phone');
    }
    const address = consumer.address;
    if (this.__checkModifiedAddress(consumerUpdate, address, 'address1') && this.isFieldProtected(consumer, 'address1')) {
      modifiedProtectedFields.push('address1');
    }
    if (this.__checkModifiedAddress(consumerUpdate, address, 'address2') && this.isFieldProtected(consumer, 'address2')) {
      modifiedProtectedFields.push('address2');
    }
    if (this.__checkModifiedAddress(consumerUpdate, address, 'city') && this.isFieldProtected(consumer, 'city')) {
      modifiedProtectedFields.push('city');
    }
    if (this.__checkModifiedAddress(consumerUpdate, address, 'state') && this.isFieldProtected(consumer, 'state')) {
      modifiedProtectedFields.push('state');
    }
    if (this.__checkModifiedAddress(consumerUpdate, address, 'zipCode') && this.isFieldProtected(consumer, 'zipCode')) {
      modifiedProtectedFields.push('zipCode');
    }
    return modifiedProtectedFields.concat(this.__checkBaseConsumerProtectedFields(consumer, consumerUpdate));
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __checkBaseConsumerProtectedFields(consumer, consumerUpdate) {
    const modifiedProtectedFields = [];
    if (this.__checkModifiedField(consumer.firstName, consumerUpdate.firstName) && this.isFieldProtected(consumer, 'firstName')) {
      modifiedProtectedFields.push('firstName');
    }
    if (this.__checkModifiedField(consumer.middleName, consumerUpdate.middleName) && this.isFieldProtected(consumer, 'middleName')) {
      modifiedProtectedFields.push('middleName');
    }
    if (this.__checkModifiedField(consumer.middleInitial, consumerUpdate.middleInitial) && this.isFieldProtected(consumer, 'middleInitial')) {
      modifiedProtectedFields.push('middleInitial');
    }
    if (this.__checkModifiedField(consumer.lastName, consumerUpdate.lastName) && this.isFieldProtected(consumer, 'lastName')) {
      modifiedProtectedFields.push('lastName');
    }
    if ((consumerUpdate.dob && !Validator.areDatesEqual(consumer.dob, consumerUpdate.dob)) && this.isFieldProtected(consumer, 'dob')) {
      modifiedProtectedFields.push('dob');
    }
    if (this.__checkModifiedField(consumer.gender, consumerUpdate.gender) && this.isFieldProtected(consumer, 'gender')) {
      modifiedProtectedFields.push('gender');
    }
    return modifiedProtectedFields;
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __checkModifiedAddress(consumerUpdate, address, fieldName) {
    return (!address && Validator.isValidString(consumerUpdate[fieldName])) || (address && this.__checkModifiedField(address[fieldName], consumerUpdate[fieldName]));
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __checkModifiedField(originalValue, newValue) {
    return ((newValue !== originalValue) && newValue);
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateConsumerUpdate(fieldsRequired, consumerUpdate, errors) {
    const currentFunction = 'ConsumerService.__validateConsumerUpdate';
    this.__logger.trace(currentFunction, 'Started', fieldsRequired, consumerUpdate, errors);
    let fieldName;
    let reason;
    let recoverySuggestion;
    const sysConfig = this.__systemConfiguration;
    if (Validator.isValidString(consumerUpdate.phone) && !Validator.isPhoneNumberValid(consumerUpdate.phone)) {
      fieldName = 'phone';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (fieldsRequired && !Validator.isValidString(consumerUpdate.email)) {
      fieldName = 'email';
      reason = 'field required';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (consumerUpdate.email && !Validator.isEmailValid(consumerUpdate.email)) {
      fieldName = 'email';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    const isAddressRequired = sysConfig.consumerAddressRequired;
    if (isAddressRequired) {
      if (fieldsRequired && !Validator.isValidString(consumerUpdate.address1)) {
        fieldName = 'address1';
        reason = 'field required';
        recoverySuggestion = 'set to non-empty value';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (Validator.isValidString(consumerUpdate.address1) && !Validator.isAddressValid(consumerUpdate.address1, true)) {
        fieldName = 'address1';
        reason = 'invalid format';
        recoverySuggestion = 'see valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (Validator.isValidString(consumerUpdate.address2) && !Validator.isAddressValid(consumerUpdate.address2, false)) {
        fieldName = 'address2';
        reason = 'invalid format';
        recoverySuggestion = 'see valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !Validator.isValidString(consumerUpdate.city)) {
        fieldName = 'city';
        reason = 'field required';
        recoverySuggestion = 'set to non-empty value';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (Validator.isValidString(consumerUpdate.city) && consumerUpdate.city.length <= 1) {
        fieldName = 'city';
        reason = 'invalid format';
        recoverySuggestion = 'see valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !Validator.isValidString(consumerUpdate.zipCode)) {
        fieldName = 'zipCode';
        reason = 'field required';
        recoverySuggestion = 'set to non-empty value';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (Validator.isValidString(consumerUpdate.zipCode) && !Validator.isZipCodeValid(consumerUpdate.zipCode)) {
        fieldName = 'zipCode';
        reason = 'invalid format';
        recoverySuggestion = 'see valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired || consumerUpdate.countryCode || consumerUpdate.stateCode) {
        this.__validateCountryState(consumerUpdate.countryCode, consumerUpdate.stateCode, errors, true, false);
      }
    }
    if (Validator.isValidString(consumerUpdate.preferredLocale) && !this.__systemConfiguration.supportedLocales.includes(consumerUpdate.preferredLocale)) {
      fieldName = 'preferredLocale';
      reason = 'unsupported locale';
      recoverySuggestion = 'use a supported locale';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (fieldsRequired || consumerUpdate.legalResidenceCountryCode || consumerUpdate.legalResidence) {
      this.__validateCountryState(consumerUpdate.legalResidenceCountryCode, consumerUpdate.legalResidence, errors, false, true, AWSDKConsumerUpdate);
    }
    this.__validateBaseConsumerUpdate(fieldsRequired, consumerUpdate, errors);
    this.__logger.trace(currentFunction, 'Finished', errors);
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __validateBaseConsumerUpdate(fieldsRequired, consumerBaseUpdate, errors) {
    const currentFunction = 'ConsumerService.__validateBaseConsumerUpdate';
    this.__logger.trace(currentFunction, 'Started', fieldsRequired, consumerBaseUpdate, errors);
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (fieldsRequired && !Validator.isValidString(consumerBaseUpdate.firstName, false)) {
      fieldName = 'firstName';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerBaseUpdate.firstName) && !Validator.isFirstNameValid(consumerBaseUpdate.firstName)) {
      fieldName = 'firstName';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    // both middle initial and middle name can be empty even if the system is configured to accept them
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.INITIAL && consumerBaseUpdate.middleInitial != null && !Validator.isMiddleInitialValid(consumerBaseUpdate.middleInitial)) {
      fieldName = 'middleInitial';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.FULLNAME && consumerBaseUpdate.middleName != null && !Validator.isMiddleNameValid(consumerBaseUpdate.middleName)) {
      fieldName = 'middleName';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (fieldsRequired && !Validator.isValidString(consumerBaseUpdate.lastName, false)) {
      fieldName = 'lastName';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerBaseUpdate.lastName) && !Validator.isLastNameValid(consumerBaseUpdate.lastName)) {
      fieldName = 'lastName';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (fieldsRequired && !Validator.isValidString(consumerBaseUpdate.gender)) {
      fieldName = 'gender';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    // allowing gender identity key to be null/unset or empty string, but not all empty chars
    const { genderIdentityKey } = consumerBaseUpdate;
    if (this.__systemConfiguration.genderSupportEnabled && genderIdentityKey != null && genderIdentityKey !== '' && !Validator.isValidString(genderIdentityKey, false)) {
      fieldName = 'genderIdentityKey';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (fieldsRequired && consumerBaseUpdate.dob == null) {
      fieldName = 'dob';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (consumerBaseUpdate.dob != null && (consumerBaseUpdate.dob instanceof Date) && consumerBaseUpdate.dob.getTime() > Date.now()) {
      fieldName = 'dob';
      reason = 'invalid value';
      recoverySuggestion = 'Set to valid value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKBaseConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    if (consumerBaseUpdate.sdkUserId != null && !Validator.isValidString(consumerBaseUpdate.sdkUserId)) {
      fieldName = 'sdkUserId';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    const brandings = consumerBaseUpdate.brandings;
    if (brandings && ((!Array.isArray(brandings)) || (Array.isArray(brandings) && brandings.length > 0 && brandings.some(item => typeof item !== 'string')))) {
      fieldName = 'brandings';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerUpdate, fieldName, reason, recoverySuggestion));
    }
    this.__logger.trace(currentFunction, 'Finished', errors);
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __validateCountryState(countryCode, stateCode, errors, checkAddress, checkResidence, objectType = null) {
    const currentFunction = 'ConsumerService.__validateCountryState';
    this.__logger.trace(currentFunction, 'Started', countryCode, stateCode, errors, checkAddress, checkResidence);
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (this.__systemConfiguration.isMultiCountry && !Validator.isValidString(countryCode)) {
      fieldName = checkResidence ? 'legalResidenceCountryCode' : 'addressCountryCode';
      reason = 'field required';
      recoverySuggestion = 'set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
      this.__logger.trace(currentFunction, 'Finished', errors);
      return;
    }
    if (!Validator.isValidString(stateCode)) {
      fieldName = checkResidence ? 'legalResidenceStateCode' : 'addressStateCode';
      reason = 'field required';
      recoverySuggestion = 'set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
      this.__logger.trace(currentFunction, 'Finished', errors);
      return;
    }

    let country = null;
    if (!Validator.isValidString(countryCode)) {
      country = this.__countries[0];
    } else {
      country = this.__countries.find(element => element.code === countryCode);
    }
    if (!country) {
      fieldName = checkResidence ? 'legalResidenceCountryCode' : 'addressCountryCode';
      reason = 'invalid country code';
      recoverySuggestion = `set to valid value for ${checkResidence ? 'legal residence' : 'address'}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
      this.__logger.trace(currentFunction, 'Finished', errors);
      return;
    }
    if (checkAddress) {
      const state = country.states.find(element => element.code === stateCode && element.legalAddress === true);
      if (!state) {
        fieldName = 'addressStateCode';
        reason = 'invalid state code for address';
        recoverySuggestion = 'set to valid value for address';
        errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
      }
    }
    if (checkResidence) {
      const state = country.states.find(element => element.code === stateCode && element.legalResidence === true);
      if (!state) {
        fieldName = 'legalResidenceStateCode';
        reason = 'invalid state code for legal residence';
        recoverySuggestion = 'set to valid value for legal residence';
        errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
      }
    }
    this.__logger.trace(currentFunction, 'Finished', errors);
  }

  /**
   * Register a new {@link model.AWSDKConsumer|AWSDKConsumer}<br>
   * see {@link service.ConsumerService#validateRegistration(model.AWSDKConsumerRegistration)|validateRegistration(consumerRegistration)} for details on validation of registration fields<br>
   * The given {@link model.AWSDKConsumer} will be authenticated<br>
   * @param {model.AWSDKConsumerRegistration} consumerRegistration REQUIRED the {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} object with registration information about the {@link model.AWSDKConsumer|AWSDKConsumer}
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumerRegistration is null or not a valid instance of AWSDKConsumerRegistration</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the consumerRegistration values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationConsumerUnderage|AWSDKErrorCode.validationConsumerUnderage}</td><td>Cannot register underage consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationEmailInUse|AWSDKErrorCode.validationEmailInUse}</td><td>There's already a user in the system with the given email address.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationSourceIdInUse|AWSDKErrorCode.validationSourceIdInUse}</td><td>When the source id associated with this Consumer already exists for a different Consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.updateConsumerAuthKeyFailed|AWSDKErrorCode.updateConsumerAuthKeyFailed}</td><td>Problem updating the consumerAuthKey.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidTimeZone|AWSDKErrorCode.invalidTimeZone}</td><td>The TimeZone provided is not a valid canonical timezone.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKUnsupportedOperation|AWSDKErrorCode.AWSDKUnsupportedOperation}</td><td>Two-Factor Authentication is enabled, but not supported by this method.</td></tr>
   * </tbody>
   * </table>
   * @deprecated @since 3.3.0 This method does not support two-factor authentication and will throw a {@link error.AWSDKErrorCode.versionIncompatible|AWSDKErrorCode.versionIncompatible} if registration is attempted while two-factor authentication is required or optional.  Please use {@link ConsumerService#register}
   */
  registerConsumer(consumerRegistration) {
    const currentFunction = 'ConsumerService.registerConsumer';
    this.__logger.debug(currentFunction, 'Started', consumerRegistration);

    if (this.__systemConfiguration.twoFactorConfiguration === AWSDKTwoFactorConfiguration.REQUIRED
      || this.__systemConfiguration.twoFactorConfiguration === AWSDKTwoFactorConfiguration.OPTIONAL) {
      const error = AWSDKError.AWSDKUnsupportedOperation('This method does not support two-factor authentication.  Please use the register() method.');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumerRegistration instanceof AWSDKConsumerRegistration)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumerRegistration" must be of type AWSDKConsumerRegistration');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'enrollMember');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "enrollMember" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateRegistration(consumerRegistration);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getSdkAuth();
    options.headers['Content-Type'] = 'application/json';
    options.headers['X-App-Identifier'] = this.__config.sdkApiKey;
    options.headers.Accept = 'application/vnd.amwell-v11.2+json';
    options.body = consumerRegistration.__toRequestBody(this.__systemConfiguration);
    return this.executeRequest(options, AWSDKConsumerResponse)
      .then((consumerResponse) => {
        this.__logger.debug(currentFunction, 'Got response', consumerResponse);
        this.__logger.info(currentFunction, 'Complete');
        // TODO need to call accept disclaimer but we are not authenticated
        return consumerResponse.consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Register a new {@link model.AWSDKConsumer|AWSDKConsumer}<br>
   * see {@link service.ConsumerService#validateRegistration(model.AWSDKConsumerRegistration)|validateRegistration(consumerRegistration)} for details on validation of registration fields<br>
   * The given {@link model.AWSDKConsumer} will be authenticated<br>
   * @param {model.AWSDKConsumerRegistration} consumerRegistration REQUIRED the {@link model.AWSDKConsumerRegistration|AWSDKConsumerRegistration} object with registration information about the {@link model.AWSDKConsumer|AWSDKConsumer}
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean true or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumerRegistration is null or not a valid instance of AWSDKConsumerRegistration</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the consumerRegistration values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationConsumerUnderage|AWSDKErrorCode.validationConsumerUnderage}</td><td>Cannot register underage consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationEmailInUse|AWSDKErrorCode.validationEmailInUse}</td><td>There's already a user in the system with the given email address.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationSourceIdInUse|AWSDKErrorCode.validationSourceIdInUse}</td><td>When the source id associated with this Consumer already exists for a different Consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.updateConsumerAuthKeyFailed|AWSDKErrorCode.updateConsumerAuthKeyFailed}</td><td>Problem updating the consumerAuthKey.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidTimeZone|AWSDKErrorCode.invalidTimeZone}</td><td>The TimeZone provided is not a valid canonical timezone.</td></tr>
   * </tbody>
   * </table>
   * @since 3.3.0
   */
  register(consumerRegistration) {
    const currentFunction = 'ConsumerService.register';
    this.__logger.debug(currentFunction, 'Started', consumerRegistration);

    if (!(consumerRegistration instanceof AWSDKConsumerRegistration)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumerRegistration" must be of type AWSDKConsumerRegistration');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'enrollMember');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "enrollMember" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateRegistration(consumerRegistration);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getSdkAuth();
    options.headers['Content-Type'] = 'application/json';
    options.headers['X-App-Identifier'] = this.__config.sdkApiKey;
    // TODO there is a TODO in Caretalks, this version will likely change
    options.headers.Accept = 'application/vnd.amwell-v12+json';
    options.body = consumerRegistration.__toRequestBody(this.__systemConfiguration);
    return this.executeRequest(options)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Complete the registration for a partially registered {@link model.AWSDKConsumer|AWSDKConsumer}<br>
   *
   * Requires either the original username and password combination or the mutual auth token for authentication.
   *
   * @param {model.AWSDKAuthentication} authentication
   * @param {object} options an object of various options that this api can be used with
   * @param {String} options.legalResidenceState the two character State code (e.g. MA, ME, FL)
   * @param {String} options.legalResidenceCountry the two character Country code (e.g. US)
   * @param {String} options.newUsername the new username for the consumer
   * @param {String} options.newPassword the new password for the consumer
   * @param {String} options.username the original username for the consumer. Required if consumerAuthKey not provided.
   * @param {String} options.password the original password for the consumer. Required if consumerAuthKey not provided.
   * @param {String} options.consumerAuthKey the configured mutual auth token for this user. Required if username and password not provided.
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will resolve to an {@link model.AWSDKAuthentication|AWSDKAuthentication} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If authentication is null or not a valid instance of AWSDKAuthentication</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If legalResidenceCountry or legalResidenceState values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerAlreadyRegistered|AWSDKErrorCode.consumerAlreadyRegistered}</td><td>Consumer is already registered.</td></tr>
   * </tbody>
   * </table>
   */
  completeRegistration(authentication, options = {}) {
    const currentFunction = 'ConsumerService.completeRegistration';
    this.__logger.debug(currentFunction, 'Started', authentication, options);
    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "authentication" must be of type AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!authentication.needsToCompleteRegistration) {
      const error = AWSDKError.AWSDKConsumerAlreadyRegistered();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errors = [];
    this.__validateCountryState(options.legalResidenceCountry, options.legalResidenceState, errors, false, true);
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(options.newUsername)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('Param "options.newUsername" must be provided');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(options.newPassword)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('Param "options.newPassword" must be provided');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    // we need either username/password OR consumerAuthKey
    if (!(Validator.isValidString(options.username) && Validator.isValidString(options.password))
      && !Validator.isValidString(options.consumerAuthKey)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('Params "options.username" and "options.password" OR "options.sdkUserAuthKey" must be provided');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(authentication.authenticationLinks, 'completeEnrollment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('authentication does not have a valid "completeEnrollment" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const requestOptions = this.generateOptions('POST', link.url);
    // if two-factor is not disabled, getUserAuth will return null, and we need to add the auth manually
    requestOptions.auth = this.getUserAuthFrom(authentication);
    requestOptions.form.set('sdkApiKey', this.__config.sdkApiKey);
    requestOptions.form.set('username', options.username);
    requestOptions.form.set('password', options.password);
    requestOptions.form.set('newUsername', options.newUsername);
    requestOptions.form.set('newPassword', options.newPassword);
    requestOptions.form.set('legalResidencyState', options.legalResidenceState);
    requestOptions.form.set('legalResidenceCountry', options.legalResidenceCountry);
    requestOptions.form.set('sdkUserAuthKey', options.consumerAuthKey);
    return this.executeRequest(requestOptions, AWSDKAuthentication)
      .then((updatedAuthentication) => {
        this.__logger.debug(currentFunction, 'Got response', updatedAuthentication);
        this.addUserAuthEntry(updatedAuthentication);
        this.__logger.info(currentFunction, 'Complete');
        return updatedAuthentication;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   *
   * Update an existing {@link model.AWSDKConsumer|AWSDKConsumer}<br>
   * @param {model.AWSDKConsumer} consumer REQUIRED the {@link model.AWSDKConsumer|AWSDKConsumer} object that the {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} is being applied to
   * @param {model.AWSDKConsumerUpdate} consumerUpdate REQUIRED the {@link model.AWSDKConsumerUpdate|AWSDKConsumerUpdate} object with update information about the {@link model.AWSDKConsumer|AWSDKConsumer}
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.modifiedProtectedField|AWSDKErrorCode.modifiedProtectedField}</td><td>The consumer is feed controlled and some fields cannot be modified.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer
   * or consumer update is not a valid instance of AWSDKConsumerUpdate</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the consumerUpdate values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidTimeZone|AWSDKErrorCode.invalidTimeZone}</td><td>The TimeZone provided is not a valid canonical timezone.</td></tr>
   * </tbody>
   * </table>
   */
  updateConsumer(consumer, consumerUpdate) {
    const currentFunction = 'ConsumerService.updateConsumer';
    this.__logger.debug(currentFunction, 'Started', consumer, consumerUpdate);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumerUpdate instanceof AWSDKConsumerUpdate)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumerUpdate" must be of type AWSDKConsumerUpdate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateConsumerUpdate(consumerUpdate);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const modifiedProtectedFields = this.__checkConsumerProtectedFields(consumer, consumerUpdate);
    if (modifiedProtectedFields.length > 0) {
      const error = AWSDKError.AWSDKModifiedProtectedField(modifiedProtectedFields);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', consumer.href, false);
    options.auth = auth;
    options.headers['Content-Type'] = 'application/json';
    options.headers.Accept = 'application/vnd.amwell-v11.2+json';
    options.body = consumerUpdate.__toRequestBody(this.__systemConfiguration, this.__config.ignorePropagation);
    return this.executeRequest(options, AWSDKConsumerResponse)
      .then((consumerResponse) => {
        this.__logger.debug(currentFunction, 'Got response', consumerResponse);
        this.updateUserAuthEntry(consumer, consumerResponse.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return consumerResponse.consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Register a new {@link model.AWSDKConsumer} dependent <br>
   * see {@link service.ConsumerService#validateDependentRegistration(model.AWSDKDependentRegistration)|validateDependentRegistration(dependentRegistration)} for details on validation of registration fields<br>
   * @param {model.AWSDKConsumer} parent REQUIRED the {@link model.AWSDKConsumer|AWSDKConsumer} object with parent information for the {@link model.AWSDKConsumer|AWSDKConsumer} dependent
   * @param {model.AWSDKDependentRegistration} dependentRegistration REQUIRED the {@link model.AWSDKDependentRegistration|AWSDKDependentRegistration} object with registration information about the {@link model.AWSDKConsumer|AWSDKConsumer} dependent
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If parent is not a valid instance of AWSDKConsumer
   * or dependentRegistration is not a valid instance of AWSDKDependentRegistration</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the dependentRegistration values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationSourceIdInUse|AWSDKErrorCode.validationSourceIdInUse}</td><td>When the source id associated with this Consumer already exists for a different Consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationDependentMatchFound|AWSDKErrorCode.validationDependentMatchFound}</td><td>The dependent being registered already exists as a dependent for the Consumer</td></tr>
   * </tbody>
   * </table>
   */
  registerDependent(parent, dependentRegistration) {
    const currentFunction = 'ConsumerService.registerDependent';
    this.__logger.debug(currentFunction, 'Started', parent, dependentRegistration);
    if (!(parent instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "parent" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(dependentRegistration instanceof AWSDKDependentRegistration)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "dependentRegistration" must be of type AWSDKDependentRegistration');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateDependentRegistration(dependentRegistration);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(parent);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'enrollDependent');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "enrollDependent" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = auth;
    options.headers['Content-Type'] = 'application/json';
    options.headers.Accept = 'application/vnd.amwell-v11.2.1+json, application/json';
    dependentRegistration.encryptedMemberId = parent.id.encryptedId;

    options.body = dependentRegistration.__toRequestBody(this.__systemConfiguration);
    return this.executeRequest(options, AWSDKDependentResponse)
      .then((dependentResponse) => {
        this.__logger.debug(currentFunction, 'Got response', dependentResponse);
        this.updateUserAuthEntry(parent, dependentResponse.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return dependentResponse.dependent;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Update an existing {@link model.AWSDKConsumer} dependent <br>
   * see {@link service.ConsumerService#validateDependentUpdate(model.AWSDKDependentUpdate)|validateDependentUpdate(dependentUpdate)} for details on validation of update-able fields<br>
   * @param {model.AWSDKConsumer} dependent REQUIRED the {@link model.AWSDKConsumer|AWSDKConsumer} object that updates will be applied to
   * @param {model.AWSDKDependentUpdate} dependentUpdate REQUIRED the {@link model.AWSDKDependentUpdate|AWSDKDependentUpdate} object containing the updates to {@link model.AWSDKConsumer|AWSDKConsumer} dependent
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.modifiedProtectedField|AWSDKErrorCode.modifiedProtectedField}</td><td>The consumer is feed controlled and some fields cannot be modified.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If dependent is not a valid instance of AWSDKConsumer
   * or dependentUpdate is not a valid instance of AWSDKDependentUpdate</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the dependentUpdate values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationSourceIdInUse|AWSDKErrorCode.validationSourceIdInUse}</td><td>When the source id associated with this Consumer already exists for a different Consumer.</td></tr>
   * </tbody>
   * </table>
   */
  updateDependent(dependent, dependentUpdate) {
    const currentFunction = 'ConsumerService.updateDependent';
    this.__logger.debug(currentFunction, 'Started', dependent, dependentUpdate);
    if (!(dependent instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "dependent" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(dependentUpdate instanceof AWSDKDependentUpdate)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "dependentUpdate" must be of type AWSDKDependentUpdate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateDependentUpdate(dependentUpdate);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const modifiedProtectedFields = this.__checkBaseConsumerProtectedFields(dependent, dependentUpdate);
    if (modifiedProtectedFields.length > 0) {
      const error = AWSDKError.AWSDKModifiedProtectedField(modifiedProtectedFields);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(dependent);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', dependent.href, false);
    options.auth = auth;
    options.headers['Content-Type'] = 'application/json';
    options.headers.Accept = 'application/vnd.amwell-v11.2.1+json, application/json';
    options.body = dependentUpdate.__toRequestBody(this.__systemConfiguration);
    return this.executeRequest(options, AWSDKDependentResponse)
      .then((dependentResponse) => {
        this.__logger.debug(currentFunction, 'Got response', dependentResponse);
        this.updateUserAuthEntry(dependent, dependentResponse.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return dependentResponse.dependent;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method retrieves a list of {@link model.AWSDKConsumer|AWSDKConsumer} objects associated with a given {@link model.AWSDKConsumer|AWSDKConsumer}, which represent his/her dependents.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose dependents we want to retrieve.
   * @returns {Promise<model.AWSDKConsumer[]|error.AWSDKError>} a promise that will resolve to a list of dependents associated with the consumer or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  getDependents(consumer) {
    const currentFunction = 'ConsumerService.getDependents';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'dependents');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "dependents" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKDependentsListResponse)
      .then((dependentsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', dependentsResponse);
        this.updateUserAuthEntry(consumer, dependentsResponse.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return dependentsResponse.dependents;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method retrieves a notifications object for a {@link model.AWSDKConsumer|AWSDKConsumer}, which represents the user's upcoming appointments, outstanding dependent access requests, or unread secure messages.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose notifications we want to retrieve.
   * @returns {Promise<model.AWSDKNotifications|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKNotifications|AWSDKNotifications} or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  getNotifications(consumer) {
    const currentFunction = 'ConsumerService.getNotifications';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'notifications');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "notifications" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKNotificationsResponse)
      .then((notificationsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', notificationsResponse);
        this.updateUserAuthEntry(consumer, notificationsResponse.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return notificationsResponse.notifications;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method requests dependent access from one parent to another via email.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} who is requesting access to a dependent from another parent.
   * @param {string} otherParentEmail the email address of the parent under which the dependent is currently registered
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean true or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer or email is invalid
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  requestDependentAccess(consumer, otherParentEmail) {
    const currentFunction = 'ConsumerService.requestDependentAccess';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isEmailValid(otherParentEmail)) {
      const error = AWSDKError.AWSDKIllegalArgument('otherParentEmail is invalid');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'requestDependentAccess');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "requestDependentAccess" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    options.form.set('emailAddress', otherParentEmail);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method gets the latest pending dependent access request made to the guardian of the dependent.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} the parent who is the guardian of the dependent
   * @returns {Promise<model.AWSDKDependentAccessRequest|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKDependentAccessRequest|AWSDKDependentAccessRequest} or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  getDependentAccessRequest(consumer) {
    const currentFunction = 'ConsumerService.getDependentAccessRequest';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'dependentAccessNotification');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "dependentAccessNotification" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKDependentAccessRequestResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return response.dependentAccessRequest;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method accepts the latest pending dependent access request made to the guardian of the dependent.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} the parent who is the guardian of dependent.
   * @param {model.AWSDKDependentAccessRequest} accessRequest the {@link model.AWSDKDependentAccessRequest|AWSDKDependentAccessRequest} the access request that guardian is accepting.
   * @param {model.AWSDKConsumer[]} dependents the list of dependents that the guardian wishes to grant the requestor access to.
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  acceptDependentAccessRequest(consumer, accessRequest, dependents) {
    const currentFunction = 'ConsumerService.acceptDependentAccessRequest';
    this.__logger.debug(currentFunction, 'Started', consumer, accessRequest, dependents);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(accessRequest instanceof AWSDKDependentAccessRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "accessRequest" must be of type AWSDKDependentAccessRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Array.isArray(dependents) || dependents.length === 0 ||
      dependents.filter(item => !(item instanceof AWSDKConsumer)).length > 0) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "dependents" must be an array of AWSDKConsumer elements');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(accessRequest.links, 'acceptDependentAccessRequest');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('accessRequest param does not have a "acceptDependentAccessRequest" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    dependents.forEach((dependent) => {
      options.form.append('dependentIds', dependent.id.encryptedId);
    });
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method declines the latest pending access to dependent request made to the guardian of the dependent.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} the parent who is the guardian of the dependent.
   * @param {model.AWSDKDependentAccessRequest} accessRequest the {@link model.AWSDKDependentAccessRequest|AWSDKDependentAccessRequest} the access request that guardian is declining.
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean or rejected with an instance of an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  declineDependentAccessRequest(consumer, accessRequest) {
    const currentFunction = 'ConsumerService.declineDependentAccessRequest';
    this.__logger.debug(currentFunction, 'Started', consumer, accessRequest);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(accessRequest instanceof AWSDKDependentAccessRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "accessRequest" must be of type AWSDKDependentAccessRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(accessRequest.links, 'declineDependentAccessRequest');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('accessRequest param does not have a "declineDependentAccessRequest" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Upon retrieval, an instance of {@link model.AWSDKAuthentication|AWSDKAuthentication} may contain a non-null value for
   * {@link model.AWSDKAuthentication#getOutstandingDisclaimer|AWSDKAuthentication.getOutstandingDisclaimer}, which signifies that the disclaimer has
   * been updated since the last time the Consumer accepted it.
   * Call this method to confirm acceptance of the updated Consumer disclaimer.
   * Be sure to test for a non-null value for {@link model.AWSDKAuthentication#getOutstandingDisclaimer|AWSDKAuthentication#getOutstandingDisclaimer}
   * before calling this method, or you will receive an IllegalArgumentException.
   *
   * @param {model.AWSDKConsumer} consumer The {@link model.AWSDKConsumer|AWSDKConsumer} who is accepting any outstanding disclaimers
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>When the consumer is not authenticated.</td></tr>
   * </tbody>
   * </table>
   */
  acceptOutstandingDisclaimer(consumer) {
    const currentFunction = 'ConsumerService.acceptOutstandingDisclaimer';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'outstandingDisclaimer');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "outstandingDisclaimer" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('acceptedMemberDisclaimer', true);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Updates the medical conditions of a {@link model.AWSDKConsumer}.
   *
   * @param {model.AWSDKConsumer} consumer The {@link model.AWSDKConsumer} to update
   * @param {model.AWSDKCondition[]} conditions The array of {@link model.AWSDKCondition}
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that will resolve with the given {@link model.AWSDKConsumer|AWSDKConsumer} object or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer
   * or conditions is not an array of AWSDKCondition</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * </tbody>
   * </table>
   */
  updateConditions(consumer, conditions) {
    const currentFunction = 'ConsumerService.updateConditions';
    this.__logger.debug(currentFunction, 'Started', consumer, conditions);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (conditions === null || !(conditions instanceof Array)) {
      const error = AWSDKError.AWSDKIllegalArgument('conditions is null or not an array of AWSDKCondition');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const termIds = [];
    for (let i = 0; i < conditions.length; i += 1) {
      const condition = conditions[i];
      if (condition === null || !(condition instanceof AWSDKCondition)) {
        const error = AWSDKError.AWSDKIllegalArgument('conditions is null or not an array of AWSDKCondition');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      if (condition.isCurrent === true) {
        termIds.push(condition.termId);
      }
    }
    const link = this.findNamedLink(consumer.links, 'conditions');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "conditions" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    for (let i = 0; i < termIds.length; i += 1) {
      options.form.append('conditions', termIds[i]);
    }
    return this.executeRequest(options, AWSDKConditionsResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.__logger.info(currentFunction, 'Complete');
        return consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Updates the {@link model.AWSDKVitals} for a given {@link model.AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer The {@link model.AWSDKConsumer} to update
   * @param {model.AWSDKVitals} vitals The {@link model.AWSDKVitals} of the consumer
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKConsumer} <br>
   * or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the vital values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer
   * or vitals is not an instance of AWSDKVitals</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   *
   * Note: The current version of the SDK only supports the imperial measurement system.
   * for weight measurement, the major unit is pound, the minor unit is ounce
   * for height measurement, the major unit is foot, the minor unit is inch
   *
   * Additionally, the Amwell Home Platform must be at version 12.2.0 or higher to use heightMinor, heightMajor, weightMinor, and weightMajor.
   */
  updateVitals(consumer, vitals) {
    const currentFunction = 'ConsumerService.updateConditions';
    this.__logger.debug(currentFunction, 'Started', consumer, vitals);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(vitals instanceof AWSDKVitals)) {
      const error = AWSDKError.AWSDKIllegalArgument('vitals is null or not an instance of AWSDKVitals');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateVitals(vitals);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'vitals');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "vitals" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error('consumer service.updateVitals', 'Error', error);
      return Promise.reject(error);
    }
    if (typeof vitals.systolic === 'number' && typeof vitals.diastolic === 'number') {
      options.form.set('systolic', vitals.systolic);
      options.form.set('diastolic', vitals.diastolic);
    }
    if (typeof vitals.temperature === 'number') {
      options.form.set('temperature', vitals.temperature);
    }
    if (vitals.weightMajor || vitals.weightMinor) { // set this measurement as long as one property is not empty. Note it has passed the validation
      options.form.set('weightMajor', vitals.weightMajor || 0);
      options.form.set('weightMinor', vitals.weightMinor || 0);
    }
    if (vitals.heightMajor || vitals.heightMajor) { // set this measurement as long as one property is not empty. Note it has passed the validation
      options.form.set('heightMajor', vitals.heightMajor || 0);
      options.form.set('heightMinor', vitals.heightMinor || 0);
    }

    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method validates the fields in the {@link model.AWSDKVitals|AWSDKVitals}
   * Temperature values are expected to be in fahrenheit (f).
   * Diastolic and Systolic values  are expected to be in millimeters of mercury (mmHg).
   * @param {model.AWSDKVitals} vitals the vitals with updated information for validation
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>temperature</td>
   * <td>invalid format</td>
   * <td>Set to valid number within the range of 0.0 to 120.0 F</td>
   * </tr>
   * <tr>
   * <td>temperature</td>
   * <td>out of range field</td>
   * <td>Set to valid number within the range of 0.0 to 120.0 F</td>
   * </tr>
   * <tr>
   * <td>weight</td>
   * <td>invalid format</td>
   * <td>Set to valid number within the range of allowed weight, specified in the system configurations</td>
   * </tr>
   * <tr>
   * <td>weight</td>
   * <td>out of range field</td>
   * <td>Set to valid number within the range of allowed weight, specified in the system configurations</td>
   * </tr>
   * <tr>
   * <td>systolic</td>
   * <td>invalid format</td>
   * <td>Set to valid number within the range of 0.0 to 250.0 mmHg</td>
   * </tr>
   * <tr>
   * <td>systolic</td>
   * <td>out of range field</td>
   * <td>Set to valid number within the range of 0.0 to 250.0 mmHg</td>
   * </tr>
   * <tr>
   * <td>diastolic</td>
   * <td>invalid format</td>
   * <td>Set to valid number within the range of 0.0 to 250.0 mmHg</td>
   * </tr>
   * <tr>
   * <td>diastolic</td>
   * <td>out of range field</td>
   * <td>Set to valid number within the range of 0.0 to 250.0 mmHg</td>
   * </tr>
   * <tr>
   * <td>diastolic</td>
   * <td>diastolic not less than systolic</td>
   * <td>Set to valid number below Systolic value</td>
   * </tr>
   * <tr>
   * <td>diastolic</td>
   * <td>field part of set</td>
   * <td>Diastolic and Systolic must be provided together</td>
   * </tr>
   * <tr>
   * <td>systolic</td>
   * <td>field part of set</td>
   * <td>Diastolic and Systolic must be provided together</td>
   * </tr>
   */
  validateVitals(vitals) {
    this.__logger.info('consumerService in validateVitals()');
    this.__logger.debug('Found vitals ', vitals);
    const errors = [];
    if (!(vitals instanceof AWSDKVitals)) {
      throw AWSDKError.AWSDKIllegalArgument('vitals is not instance of AWSDKVitals');
    }
    this.__validateVitals(vitals, errors);
    return errors;
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateVitals(vitals, errors) {
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (vitals.temperature && typeof vitals.temperature !== 'number') {
      fieldName = 'temperature';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxTemperatureValue} f`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.temperature > this.__maxTemperatureValue || vitals.temperature < this.__minValueVitals) {
      fieldName = 'temperature';
      reason = 'out of range field';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxTemperatureValue} f`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.systolic && typeof vitals.systolic !== 'number') {
      fieldName = 'systolic';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxSystolicValue} mmHg`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.systolic > this.__maxSystolicValue || vitals.systolic < this.__minValueVitals) {
      fieldName = 'systolic';
      reason = 'out of range field';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxSystolicValue} mmHg`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.diastolic && typeof vitals.diastolic !== 'number') {
      fieldName = 'diastolic';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxDiastolicValue} mmHg`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.diastolic > this.__maxDiastolicValue || vitals.diastolic < this.__minValueVitals) {
      fieldName = 'diastolic';
      reason = 'out of range field';
      recoverySuggestion = `Set to valid value within range of ${this.__minValueVitals} to ${this.__maxDiastolicValue}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.diastolic && vitals.systolic && vitals.diastolic >= vitals.systolic) {
      fieldName = 'diastolic';
      reason = 'diastolic not less than systolic';
      recoverySuggestion = 'Set to valid value below Systolic value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (!vitals.diastolic && vitals.systolic) {
      fieldName = 'diastolic';
      reason = 'field part of set';
      recoverySuggestion = 'Diastolic and Systolic must be provided together';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (!vitals.systolic && vitals.diastolic) {
      fieldName = 'systolic';
      reason = 'field part of set';
      recoverySuggestion = 'Diastolic and Systolic must be provided together';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.weightMajor && typeof vitals.weightMajor !== 'number') {
      fieldName = 'weightMajor';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__weightMeasurementTotalMustBeGreaterThan} to ${this.__maxTotalWeightMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.weightMinor && typeof vitals.weightMinor !== 'number') {
      fieldName = 'weightMinor';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__minWeightMinorMeasurement} to ${this.__maxWeightMinorMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.weightMinor && (vitals.weightMinor > this.__maxWeightMinorMeasurement || vitals.weightMinor < this.__minWeightMinorMeasurement)) {
      fieldName = 'weightMinor';
      reason = 'out of range field';
      recoverySuggestion = `Set to valid value within range of ${this.__minWeightMinorMeasurement} to ${this.__maxWeightMinorMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (typeof vitals.weightMinor === 'number' || typeof vitals.weightMajor === 'number') {
      const major = vitals.weightMajor || 0;
      const minor = vitals.weightMinor || 0;
      // FIXME: hardcoded to 16 for ounces - should be configurable for proper i18n
      const totalWeight = (major + (minor / 16));
      if (totalWeight > this.__maxTotalWeightMeasurement || totalWeight <= this.__weightMeasurementTotalMustBeGreaterThan) {
        fieldName = 'weightMajor';
        reason = 'out of range for combined fields';
        recoverySuggestion = `Set to valid value within range of ${this.__weightMeasurementTotalMustBeGreaterThan} to ${this.__maxTotalWeightMeasurement}`;
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
        fieldName = 'weightMinor';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
      }
    }

    if (vitals.heightMajor && typeof vitals.heightMajor !== 'number') {
      fieldName = 'heightMajor';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__heightMeasurementTotalMustBeGreaterThan} to ${this.__maxTotalHeightMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.heightMinor && typeof vitals.heightMinor !== 'number') {
      fieldName = 'heightMinor';
      reason = 'invalid format';
      recoverySuggestion = `Set to valid value within range of ${this.__minHeightMinorMeasurement} to ${this.__maxHeightMinorMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (vitals.heightMinor && (vitals.heightMinor > this.__maxHeightMinorMeasurement || vitals.heightMinor < this.__minHeightMinorMeasurement)) {
      fieldName = 'heightMinor';
      reason = 'out of range field';
      recoverySuggestion = `Set to valid value within range of ${this.__minHeightMinorMeasurement} to ${this.__maxHeightMinorMeasurement}`;
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
    }
    if (typeof vitals.heightMinor === 'number' || typeof vitals.heightMajor === 'number') {
      const major = vitals.heightMajor || 0;
      const minor = vitals.heightMinor || 0;
      // FIXME: hardcoded to 12 for inches - should be configurable for proper i18n
      const totalHeight = (major + (minor / 12));
      if (totalHeight > this.__maxTotalHeightMeasurement || totalHeight <= this.__heightMeasurementTotalMustBeGreaterThan) {
        fieldName = 'heightMajor';
        reason = 'out of range for combined fields';
        recoverySuggestion = `Set to valid value within range of ${this.__heightMeasurementTotalMustBeGreaterThan} to ${this.__maxTotalHeightMeasurement}`;
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
        fieldName = 'heightMinor';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKVitals, fieldName, reason, recoverySuggestion));
      }
    }
  }

  /**
   * Retrieves a full list of {@link model.AWSDKCondition}s available in the system.
   * Conditions marked as 'current' are ones associated with the given {@link model.AWSDKConsumer}
   *
   * @param {model.AWSDKConsumer} consumer The {@link model.AWSDKConsumer}
   * @returns {Promise<model.AWSDKCondition[]|error.AWSDKError>} a promise that will resolve with an array of the system's {@link model.AWSDKCondition}s or rejected with {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getConditions(consumer) {
    const currentFunction = 'ConsumerService.getConditions';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'conditions');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "conditions" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error('consumer service.getConditions', 'Error', error);
      return Promise.reject(error);
    }

    return this.executeRequest(options, AWSDKConditionsResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.conditions;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieves the {@link model.AWSDKVitals} for a given {@link model.AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer The {@link model.AWSDKConsumer} to get the vitals for
   * @returns {Promise<model.AWSDKVitals|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKVitals} <br>
   * or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getVitals(consumer) {
    const currentFunction = 'ConsumerService.getVitals';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'vitals');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "vitals" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error('consumer service.getVitals', 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKVitalsResponse)
      .then((vitalsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', vitalsResponse);
        this.updateUserAuthEntry(consumer, vitalsResponse.authToken);
        return vitalsResponse.vitals;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get the current {@link model.AWSDKPaymentMethod|AWSDKPaymentMethod} for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   *
   * @param consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to use to fetch the payment on file
   * @returns {Promise<model.AWSDKPaymentMethod|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKPaymentMethod} <br>
   * or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.noPaymentInformationFound|AWSDKErrorCode.noPaymentInformationFound}</td><td>No Payment information found</td></tr>
   * </tbody>
   * </table>
   */
  getPaymentMethod(consumer) {
    const currentFunction = 'ConsumerService.getPaymentMethod';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'payment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "payment" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKPaymentMethodResponse)
      .then((paymentMethodResponse) => {
        this.__logger.debug(currentFunction, 'Got response', paymentMethodResponse);
        this.updateUserAuthEntry(consumer, paymentMethodResponse.authToken);
        return paymentMethodResponse.paymentMethod;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method allows for a search of medications by name. It takes as argument a {@link model.AWSDKConsumer|AWSDKConsumer} and a free-form String text that represents the name of the medication being searched.
   * @param {model.AWSDKConsumer} consumer the consumer who is authenticated at this particular point
   * @param {String} medicationText the free form text representing the medication being searched. Needs to be a minimum of 3 char for search to be performed.
   * @returns {Promise<model.AWSDKMedicationList|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKMedicationList|AWSDKMedicationList} reject with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.medicationSearchTextTooShort|AWSDKErrorCode.medicationSearchTextTooShort}</td><td>The medication search text is too short</td></tr>
   * </tbody>
   * </table>
   */
  searchMedications(consumer, medicationSearchText) {
    const currentFunction = 'ConsumerService.searchMedications';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is of wrong type');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (medicationSearchText == null) {
      const error = AWSDKError.AWSDKIllegalArgument('medicationSearchText is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isStringType(medicationSearchText)) {
      const error = AWSDKError.AWSDKIllegalArgument('medicationSearchText is of wrong type');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (medicationSearchText.length < 3) {
      const error = AWSDKError.AWSDKMedicationSearchTextTooShort('medicationSearchText', 3);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'medicationSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "medicationSearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.form.set('medication', medicationSearchText);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKMedicationList)
      .then((medicationsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', medicationsResponse);
        this.updateUserAuthEntry(consumer, medicationsResponse.authToken);
        return medicationsResponse;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * This service method allows callers to retrieve a list of medications ({@link model.AWSDKMedicationList|AWSDKMedicationList}) pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose medications list we want to retrieve
   * @returns {Promise<model.AWSDKMedicationList|error.AWSDKError>} a Promise that resolves to a {@link model.AWSDKMedicationList|AWSDKMedicationList} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not an instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getMedications(consumer) {
    const currentFunction = 'ConsumerService.getMedications';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null ');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'medications');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "medications" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKMedicationList)
      .then((medicationsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', medicationsResponse);
        this.updateUserAuthEntry(consumer, medicationsResponse.authToken);
        return medicationsResponse;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This service allows a {@link model.AWSDKConsumer|AWSDKConsumer} to update their medications list.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose medications list we want to update
   * @param {model.AWSDKMedication[]} medications an array of medications representing the medications to be updated.
   * @returns {Promise<boolean|error.AWSDKError>} returns a Promise that will resolve to a boolean true or reject with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not an instance of AWSDKConsumer or
   * medications in not an array of AWSDKMedication</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  updateMedications(consumer, medications) {
    const currentFunction = 'ConsumerService.updateMedications';
    this.__logger.debug(currentFunction, 'Started', consumer, medications);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null ');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (medications == null) {
      const error = AWSDKError.AWSDKIllegalArgument('medications is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Array.isArray(medications)) {
      const error = AWSDKError.AWSDKIllegalArgument('medications is not an array');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const indexOfErr = medications.find(item => !(item instanceof AWSDKMedication));
    if (indexOfErr) {
      const error = AWSDKError.AWSDKIllegalArgument(`medications at index: ${indexOfErr} is not an instance of AWSDKMedication`);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'medications');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "medications" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    const medicationIds = medications.map(item => item.id.encryptedId);
    for (let i = 0; i < medicationIds.length; i += 1) {
      options.form.append('medications', medicationIds[i]);
    }
    return this.executeRequest(options)
      .then(() => {
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method MUST be called before calling the {@link service.ConsumerService#updatePaymentMethod|updatePaymentMethod}
   * After obtaining the {@link model.AWSDKPaymentRequest|AWSDKPaymentRequest} object, fill out the desired fields ({@link service.ConsumerService#validatePaymentRequest|validatePaymentRequest} for
   * a description of desired fields) and call the {@link service.ConsumerService#updatePaymentMethod|updatePaymentMethod}
   * to update the consumer payment information.
   * @returns {model.AWSDKPaymentRequest} object which will include the payment information to update for the {@link model.AWSDKConsumer|AWSDKConsumer}
   */
  newPaymentRequest() {
    return new AWSDKPaymentRequest();
  }

  /**
   * This method is used to update the payment information on file pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}. The caller of this method MUST call the {@link service.ConsumerService#newPaymentRequest|newPaymentRequest} <br>
   * and fill the request form with new credit card information. Additionally, the caller can (optionally) validate the {@link model.AWSDKPaymentRequest|AWSDKPaymentRequest} by explicitly calling {@link service.ConsumerService#validatePaymentRequest|validatePaymentRequest} <br>
   * The {@link model.AWSDKPaymentRequest|AWSDKPaymentRequest} will ALWAYS be validated when calling this update method.
   * @param {model.AWSDKConsumer} consumer the authenticated consumer whose payment information to update.
   * @param {model.AWSDKPaymentRequest} paymentRequest the completed payment request form
   * @returns {Promise<model.AWSDKPaymentMethod|error.AWSDKError>} a promise that resolves to a {@link model.AWSDKPaymentMethod|AWSDKPaymentMethod} or is rejected as {@link error.AWSDKError|AWSDKError} if there are issues with the call
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardDeclinedError|AWSDKErrorCode.creditCardDeclinedError}</td><td>Credit card declined</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardInvalidZipCode|AWSDKErrorCode.creditCardInvalidZipCode}</td><td>Invalid zip code</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardInvalidCVV|AWSDKErrorCode.creditCardInvalidCVV}</td><td>Invalid CVV code</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardValidationError|AWSDKErrorCode.creditCardValidationError}</td><td>Invalid credit card number</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is not a valid instance of AWSDKConsumer or
   * paymentRequest in not a valid instance of AWSDKPaymentRequest</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.noPaymentInformationFound|AWSDKErrorCode.noPaymentInformationFound}</td><td>No payment information found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the paymentRequest values are invalid. The errors property will return the array of validation errors.</td></tr>
   * </tbody>
   * </table>
   */
  updatePaymentMethod(consumer, paymentRequest) {
    const currentFunction = 'ConsumerService.updatePaymentMethod';
    this.__logger.debug(currentFunction, 'Started', consumer, paymentRequest);
    if (!(paymentRequest instanceof AWSDKPaymentRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('paymentRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errors = this.validatePaymentRequest(paymentRequest);
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'payment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "payment" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    const body = {
      creditCardNumber: paymentRequest.creditCardNumber,
      creditCardMonth: paymentRequest.creditCardMonth - 1, // server uses 0 based index
      creditCardYear: paymentRequest.creditCardYear,
      creditCardSecCode: paymentRequest.creditCardSecCode,
      creditCardZip: paymentRequest.creditCardZip,
      nameOnCard: paymentRequest.nameOnCard,
      country: paymentRequest.country,
      state: paymentRequest.state,
      city: paymentRequest.city,
      address1: paymentRequest.address1,
      address2: paymentRequest.address2,
    };
    options.body = JSON.stringify(body);
    return this.executeRequest(options, AWSDKPaymentMethodResponse)
      .then((paymentResponse) => {
        this.__logger.debug(currentFunction, 'Got response', paymentResponse);
        this.updateUserAuthEntry(consumer, paymentResponse.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return paymentResponse.paymentMethod;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method validates the fields in the {@link model.AWSDKPaymentRequest|AWSDKPaymentRequest}
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>nameOnCard</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>creditCardNumber</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>creditCardNumber</td>
   * <td>invalid field format</td>
   * <td></td>
   * </tr>
   * <tr>
   * <td>creditCardSecCode</td>
   * <td>field required</td>
   * <td>set to non-empty field</td>
   * </tr>
   * <tr>
   * <td>creditCardSecCode</td>
   * <td>invalid field format</td>
   * <td></td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>invalid field format</td>
   * <td>if non-empty, must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>required field</td>
   * <td>set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>address2</td>
   * <td>invalid field format</td>
   * <td>If non-empty, then must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>invalid field format</td>
   * <td>if non-empty, must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>required field</td>
   * <td>set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>field required</td>
   * <td>set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>stateCode</td>
   * <td>invalid field value</td>
   * <td>Value must be in the list of supported state codes for address.</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>field required</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true</td>
   * </tr>
   * <tr>
   * <td>countryCode</td>
   * <td>invalid field value</td>
   * <td>Only required if {@link model.AWSDKSystemConfiguration#isMultiCountry|AWSDKSystemConfiguration.isMultiCountry} is true. If so, Value must be in the list of supported country codes for address.</td>
   * </tr>
   * <tr>
   * <td>creditCardZip</td>
   * <td>invalid field format</td>
   * <td>format must be: nnnnn or nnnnn-nnnn</td>
   * </tr>
   * <tr>
   * <td>creditCardMonth</td>
   * <td>field required</td>
   * <td>set to non-empty field</td>
   * </tr>
   * <tr>
   * <td>creditCardMonth</td>
   * <td>invalid field format</td>
   * <td>number >= 1 and number <= 12. If in same year, this cannot be in the past</td>
   * </tr>
   * <tr>
   * <td>creditCardYear</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>creditCardYear</td>
   * <td>invalid field format</td>
   * <td>format: yyyy, Please note that year cannot be in the past/td>
   * </tr>
   * </table>
   * @param {model.AWSDKPaymentRequest} paymentRequest the payment request form with updated payment information for validation
   * @returns {Array.<error.AWSDKError>} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   */
  validatePaymentRequest(paymentRequest) {
    const currentFunction = 'ConsumerService.validatePaymentRequest';
    this.__logger.debug(currentFunction, 'Started', paymentRequest);
    let fieldName;
    let reason;
    let recoverySuggestion;
    const errors = [];
    if (!Validator.isValidString(paymentRequest.nameOnCard)) {
      fieldName = 'nameOnCard';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (!Validator.isValidString(paymentRequest.creditCardNumber)) {
      fieldName = 'creditCardNumber';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (!Validator.isValidString(paymentRequest.creditCardSecCode)) {
      fieldName = 'creditCardSecCode';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(paymentRequest.creditCardNumber) && Validator.isValidString(paymentRequest.creditCardSecCode)) {
      this.__validateCreditCardAndCvv(paymentRequest.creditCardNumber, paymentRequest.creditCardSecCode, errors, AWSDKPaymentRequest);
    }
    if (!Validator.isValidString(paymentRequest.address1)) {
      fieldName = 'address1';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    } else if (!Validator.isAddressValid(paymentRequest.address1, false)) {
      fieldName = 'address1';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (!Validator.isAddressValid(paymentRequest.address2, false)) {
      fieldName = 'address2';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (!Validator.isValidString(paymentRequest.creditCardZip)) {
      fieldName = 'creditCardZip';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    } else if (!Validator.isZipCodeValid(paymentRequest.creditCardZip)) {
      fieldName = 'creditCardZip';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if (!Validator.isValidString(paymentRequest.city)) {
      fieldName = 'city';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    } else if (paymentRequest.city.length <= 1) {
      fieldName = 'city';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }

    this.__validateCountryState(paymentRequest.country, paymentRequest.state, errors, false, false, AWSDKPaymentRequest);

    if (!paymentRequest.creditCardMonth) {
      fieldName = 'creditCardMonth';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    } else if (paymentRequest.creditCardMonth < 1 || paymentRequest.creditCardMonth > 12) {
      fieldName = 'creditCardMonth';
      reason = 'out of range field';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    const currentDate = new Date();
    const year = paymentRequest.creditCardYear;
    const currentMonth = parseInt(currentDate.getMonth(), 10) + 1;
    const currentYear = parseInt(currentDate.getFullYear(), 10);
    if (!year) {
      fieldName = 'creditCardYear';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    } else if ((year && year.toString().length !== 4)) {
      fieldName = 'creditCardYear';
      reason = 'out of range field';
      recoverySuggestion = 'See correct range in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    if ((paymentRequest.creditCardYear < currentYear)
      || (paymentRequest.creditCardYear === currentYear
        && paymentRequest.creditCardMonth < currentMonth)) {
      fieldName = 'creditCardYear';
      reason = 'invalid format';
      recoverySuggestion = 'See comments in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKPaymentRequest, fieldName, reason, recoverySuggestion));
    }
    this.__logger.trace(currentFunction, 'Finished', errors);
    return errors;
  }

  __validateCreditCardAndCvv(cardNumber, cvvNumber, errors, objectType) {
    let fieldName = '';
    let reason = '';
    let recoverySuggestion = '';

    const cardType = this.__creditCardTypes.find(cc => cardNumber.toString().match(cc.regex));
    if (!cardType) {
      fieldName = 'creditCardNumber';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
    } else if (!Validator.isCVV(cvvNumber, cardType.cvvLength)) {
      fieldName = 'creditCardSecCode';
      reason = 'invalid format';
      recoverySuggestion = 'See correct format in documentation';
      errors.push(AWSDKError.AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion));
    }
  }

  /**
   * Returns an array of {@link model.AWSDKAllergy} for a given {@link model.AWSDKConsumer}. The allergies that are pertinent to
   * the current consumer have the property 'isCurrent' set to true.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer} whose allergies we are intersted in fetching
   * @returns {Promise<model.AWSDKAllergy[]|error.AWSDKError>} an Promise that will be resolved to an array of allergies or rejected as error.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated }</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not a valid instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getAllergies(consumer) {
    const currentFunction = 'ConsumerService.getAllergies';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'allergies');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "allergies" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKAllergiesResponse)
      .then((allergiesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', allergiesResponse);
        this.updateUserAuthEntry(consumer, allergiesResponse.authToken);
        return allergiesResponse.allergies;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Returns a {@link model.AWSDKSubscription} for a given {@link model.AWSDKConsumer}.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer} whose insurance subscription we're interested in fetching
   * @returns {Promise<model.AWSDKSubscription|error.AWSDKError>} an Promise that will be resolved to a subscription or rejected as an error.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If consumer is null or not a valid instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getInsuranceSubscription(consumer) {
    const currentFunction = 'ConsumerService.getInsuranceSubscription';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'insurance');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "insurance" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = auth;
    return this.executeRequest(options, AWSDKSubscriptionResponse)
      .then((subscriptionResponse) => {
        this.__logger.debug(currentFunction, 'Got response', subscriptionResponse);
        this.updateUserAuthEntry(consumer, subscriptionResponse.authToken);
        return subscriptionResponse.insuranceDetails;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Remove the existing {@link model.AWSDKSubscription} from the given {@link model.AWSDKConsumer|AWSDKConsumer}. <br>
   * @param {model.AWSDKConsumer} consumer the consumer whose insurance subscription should be removed
   * @returns {Promise<Boolean|error.AWSDKError>} a promise that resolves successfully if the subscription was removed or is rejected as {@link error.AWSDKError|AWSDKError} if there are issues with the call
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.authenticationAccessDenied|AWSDKErrorCode.authenticationAccessDenied}</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  removeInsuranceSubscription(consumer) {
    const currentFunction = 'ConsumerService.removeInsuranceSubscription';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'insurance');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "insurance" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, true);
    options.auth = auth;
    options.form.set('healthPlanId', null);
    return this.executeRequest(options, AWSDKSubscriptionResponse)
      .then((subscriptionResponse) => {
        this.__logger.debug(currentFunction, 'Got response', subscriptionResponse);
        this.__logger.info(currentFunction, 'Completed');
        this.updateUserAuthEntry(consumer, subscriptionResponse.authToken);
        return subscriptionResponse.insuranceDetails === null;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method is used to update the insurance information on file pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}. <br>
   * and fill the request form with new insurance information. If the 'ignoreEligibilityChecks' attribute on the
   * {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate} is set to false this will automatically check for eligibility after updating. <br>
   * @param {model.AWSDKConsumer} consumer the consumer whose insurance subscription is being updated
   * @param {model.AWSDKSubscriptionUpdate} subscriptionUpdate the completed insurance form
   * @returns {Promise<model.AWSDKSubscription|error.AWSDKError>} a promise that resolves to a {@link model.AWSDKSubscription|AWSDKSubscription} or is rejected as {@link error.AWSDKError|AWSDKError} if there are issues with the call
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.authenticationAccessDenied|AWSDKErrorCode.authenticationAccessDenied}</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.healthPlanIsFeedControlled|AWSDKErrorCode.healthPlanIsFeedControlled}</td><td>The consumer's existing health plan is feed controlled</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.eligibilityCheckError|AWSDKErrorCode.eligibilityCheckError}</td><td>There was a problem during the eligibility check process</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.pollingTimeout|AWSDKErrorCode.pollingTimeout}</td><td>The eligibility check exceeded its allotted polling time</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.inaccurateSubscriberInfo|AWSDKErrorCode.inaccurateSubscriberInfo}</td><td>Inaccurate subscriber information was rejected during the eligibility check</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.healthPlanSubscriptionEnrollmentError|AWSDKErrorCode.healthPlanSubscriptionEnrollmentError}</td><td>There was some issue with either the subscriber id or the suffix id of the plan.</td></tr>
   * </tbody>
   * </table>
   */
  updateInsuranceSubscription(consumer, subscriptionUpdate) {
    const currentFunction = 'ConsumerService.updateInsuranceSubscription';
    this.__logger.debug(currentFunction, 'Started', consumer, subscriptionUpdate);
    if (!(subscriptionUpdate instanceof AWSDKSubscriptionUpdate)) {
      const error = AWSDKError.AWSDKIllegalArgument('subscriptionUpdate is null or not an instance of AWSDKSubscriptionUpdate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const currentHealthPlan = consumer.subscription ? consumer.subscription.healthPlan : null;
    if (currentHealthPlan && currentHealthPlan.feedControlled) {
      const error = AWSDKError.AWSDKHealthPlanIsFeedControlled();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errors = this.validateSubscriptionUpdate(subscriptionUpdate, consumer);
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'insurance');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "insurance" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, true);

    options.auth = auth;
    options.form.set('healthPlanId', subscriptionUpdate.healthPlan ? subscriptionUpdate.healthPlan.id.encryptedId : null);
    options.form.set('subscriberId', subscriptionUpdate.subscriberId);
    options.form.set('subscriberSuffix', subscriptionUpdate.subscriberSuffix);
    options.form.set('eligibilityRequestId', subscriptionUpdate.eligibilityRequestId);
    options.form.set('relationshipToSubscriber', subscriptionUpdate.relationshipToSubscriberCode.warehouseValue);

    if (subscriptionUpdate.relationshipToSubscriberCode.displayName !== 'SUBSCRIBER') {
      options.form.set('primarySubscriberFirstName', subscriptionUpdate.primarySubscriberFirstName);
      options.form.set('primarySubscriberLastName', subscriptionUpdate.primarySubscriberLastName);
      options.form.set('primarySubscriberDob', Util.formatISODate(subscriptionUpdate.primarySubscriberDateOfBirth));
    }

    options.form.set('ignoreEligibility', subscriptionUpdate.ignoreEligibilityChecks);
    options.form.set('firstName', consumer.firstName);
    options.form.set('lastName', consumer.lastName);
    options.form.set('middleInitial', consumer.middleInitial);
    options.form.set('gender', consumer.genderEnum === AWSDKGender.FEMALE ? 'FEMALE' : 'MALE');
    options.form.set('dob', Util.formatISODate(consumer.dob));

    return this.executeRequest(options, AWSDKSubscriptionResponse)
      .then((subscriptionResponse) => {
        this.__logger.debug(currentFunction, 'Got response', subscriptionResponse);
        this.updateUserAuthEntry(consumer, subscriptionResponse.authToken);
        const subscription = subscriptionResponse.insuranceDetails;
        const eligibilityCheck = subscription ? subscription.eligibilityCheck : null;

        // check for and initiate polling for eligibility
        if (eligibilityCheck && eligibilityCheck.eligibilityRequestId && !eligibilityCheck.eligibilityCheckCompleted) {
          return this.__waitForEligibilityCheckToFinish(consumer, eligibilityCheck)
            .then((eligibilityCheckResult) => {
              if (eligibilityCheckResult.eligibilityError) {
                return Promise.reject(AWSDKError.AWSDKEligibilityCheckError(eligibilityCheckResult.eligibilityError));
              }
              const newUpdate = subscriptionUpdate;
              newUpdate.eligibilityRequestId = eligibilityCheckResult.eligibilityRequestId;
              this.__logger.info(currentFunction, 'Eligibility Check Completed');
              return this.updateInsuranceSubscription(consumer, newUpdate);
            }).catch((error) => {
              this.__logger.error(currentFunction, 'Error', error);
              throw error;
            });
        }
        this.__logger.info(currentFunction, 'Completed');
        return subscription;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Internal Use only!!
   * @private
   */
  __waitForEligibilityCheckToFinish(consumer, eligibilityCheck) {
    const currentFunction = 'ConsumerService.__waitForEligibilityCheckToFinish';
    this.__logger.debug(currentFunction, 'Started', consumer, eligibilityCheck);
    const interval = this.__config.eligibilityPollingInterval;
    const endTime = Number(new Date()) + (this.__config.eligibilityPollingTimeout);
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.__checkEligibility(consumer, eligibilityCheck)
        .then((eligibilityCheckResult) => {
          if (eligibilityCheckResult.eligibilityCheckCompleted) {
            this.__logger.info(currentFunction, 'Completed');
            resolve(eligibilityCheckResult);
          } else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
          } else {
            const error = AWSDKError.AWSDKPollingTimeout();
            errorCount += 3;
            this.__logger.error(currentFunction, 'Polling request timed out', error);
            reject(error);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'Error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }
  /**
   * Internal Use only!!
   * @private
   */
  __checkEligibility(consumer, eligbilityCheck) {
    const currentFunction = 'ConsumerService.__checkEligibility';
    this.__logger.debug(currentFunction, 'Started', consumer, eligbilityCheck);
    const link = this.findNamedLink(eligbilityCheck.links, 'status');
    if (!Validator.isValidLink(link)) {
      // no need to log as it is logged in __waitForEligibilityCheckToFinish
      return Promise.reject(AWSDKError.AWSDKInternalError('eligbilityCheck does not have a valid "status" link entry'));
    }
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      // no need to log as it is logged in __waitForEligibilityCheckToFinish
      return Promise.reject(AWSDKError.AWSDKConsumerNotAuthenticated());
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = auth;
    options.form.set('id', eligbilityCheck.eligibilityRequestId);
    return this.executeRequest(options, AWSDKEligibilityResponse)
      .then((eligibilityResponse) => {
        this.__logger.trace(currentFunction, 'Got response', eligibilityResponse);
        this.updateUserAuthEntry(consumer, eligibilityResponse.authToken);
        return eligibilityResponse.eligibilityCheck;
      });
  }
  /**
   * This method updates the list of known allergies for a given consumer.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer} whose allergies list we want to update
   * @param {model.AWSDKAllergy[]} allergies the list of allergies to update for the consumer
   * @returns {Boolean|error.AWSDKError} boolean true if the update succeeded, error otherwise
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode#RequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode#ValidationError}</td><td>validation failed for parameter</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode#consumerNotAuthenticated}</td><td>Unauthenticated consumer</td></tr>
   * </tbody>
   * </table>
   */
  updateAllergies(consumer, allergies) {
    const currentFunction = 'ConsumerService.updateAllergies';
    this.__logger.debug(currentFunction, 'Started', consumer, allergies);
    if (consumer == null) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('consumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (allergies == null) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('allergies');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKValidationError('consumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Array.isArray(allergies)) {
      const error = AWSDKError.AWSDKValidationError('"allergies" must be a non-empty array');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const allergyIds = [];
    for (let i = 0; i < allergies.length; i += 1) {
      const item = allergies[i];
      if (!(item instanceof AWSDKAllergy)) {
        const error = AWSDKError.AWSDKValidationError('"allergies" contains an invalid type');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      if (item.isCurrent) {
        allergyIds.push(item.termId);
      }
    }
    const link = this.findNamedLink(consumer.links, 'allergies');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "allergies" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    allergyIds.forEach((id) => {
      options.form.append('allergies', id);
    });
    options.form.allergies = allergyIds;
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method validates the fields in the {@link model.AWSDKSubscriptionUpdate|AWSDKSubscriptionUpdate}
   * @param {model.AWSDKSubscriptionUpdate} subscriptionUpdate the subscription request form with updated subscription information for validation
   * @param {model.AWSDKConsumer} consumer the consumer for whom subscription is being changed for
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>healthPlan</td>
   * <td>invalid value healthPlan is feed controlled</td>
   * <td>Set to a valid value</td>
   * </tr>
   * <tr>
   * <td>healthPlan</td>
   * <td>invalid value</td>
   * <td>Set to a healthPlan that is not feed controlled</td>
   * </tr>
   * <tr>
   * <td>subscriberSuffix</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>subscriberId</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>subscriberId</td>
   * <td>invalid value</td>
   * <td>{errorMessage}</td>
   * </tr>
   * <tr>
   * <td>relationshipToSubscriberCode</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>relationshipToSubscriberCode</td>
   * <td>invalid value</td>
   * <td>Set to valid value</td>
   * </tr>
   * <tr>
   * <td>relationshipToSubscriberCode</td>
   * <td>invalid value for dependent</td>
   * <td>Set to valid value for dependent</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberFirstName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberFirstName</td>
   * <td>invalid value</td>
   * <td>Set to valid value</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberLastName</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberLastName</td>
   * <td>invalid value</td>
   * <td>Set to valid value</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberDateOfBirth</td>
   * <td>field required</td>
   * <td>Set to non-empty value</td>
   * </tr>
   * <tr>
   * <td>primarySubscriberDateOfBirth</td>
   * <td>invalid value</td>
   * <td>Set to valid value</td>
   * </tr>
   * <tr>
   * </table>
   */
  validateSubscriptionUpdate(subscriptionUpdate, consumer) {
    this.__logger.info('consumerService in validateSubscriptionUpdate()');
    this.__logger.debug('Found subscriptionUpdate ', subscriptionUpdate);
    const errors = [];
    if (!(subscriptionUpdate instanceof AWSDKSubscriptionUpdate)) {
      throw AWSDKError.AWSDKIllegalArgument('subscriptionUpdate is not instance of AWSDKSubscriptionUpdate');
    }
    this.__validateSubscriptionUpdate(subscriptionUpdate, consumer, errors);
    return errors;
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateSubscriptionUpdate(subscriptionUpdate, consumer, errors) {
    let fieldName;
    let reason;
    let recoverySuggestion;
    const healthPlan = subscriptionUpdate.healthPlan;
    if (healthPlan && !(healthPlan instanceof AWSDKHealthPlan)) {
      fieldName = 'healthPlan';
      reason = 'invalid value';
      recoverySuggestion = 'Set to valid value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKHealthPlan, fieldName, reason, recoverySuggestion));
    }
    if (healthPlan && (healthPlan instanceof AWSDKHealthPlan)) {
      if (healthPlan.feedControlled) {
        fieldName = 'healthPlan';
        reason = 'invalid value healthPlan is feed controlled';
        recoverySuggestion = 'Set to a healthPlan that is not feed controlled';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
      }
      if (healthPlan.usesSuffix && !Validator.isValidString(subscriptionUpdate.subscriberSuffix)) {
        fieldName = 'subscriberSuffix';
        reason = 'field required';
        recoverySuggestion = 'Set to non-empty value';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
      }
      if (!Validator.isValidString(subscriptionUpdate.subscriberId)) {
        fieldName = 'subscriberId';
        reason = 'field required';
        recoverySuggestion = 'Set to non-empty value';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
      } else if (healthPlan.payerInfo && Validator.isValidString(healthPlan.payerInfo.subscriberIdPattern)) {
        const pattern = new RegExp(healthPlan.payerInfo.subscriberIdPattern);
        if (!pattern.test(subscriptionUpdate.subscriberId)) {
          fieldName = 'subscriberId';
          reason = 'invalid value';
          recoverySuggestion = healthPlan.payerInfo.subscriberIdPatternErrorMessage;
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
      }
    }
    const relationship = subscriptionUpdate.relationshipToSubscriberCode;
    if (!relationship) {
      fieldName = 'relationshipToSubscriberCode';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
    }
    if (relationship && !(relationship instanceof AWSDKRelationshipToSubscriberCode)) {
      fieldName = 'relationshipToSubscriberCode';
      reason = 'invalid value';
      recoverySuggestion = 'Set to valid value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
    }
    if (relationship && (relationship instanceof AWSDKRelationshipToSubscriberCode)) {
      if ((consumer.isDependent) && !relationship.isValidForMinorAccount) {
        fieldName = 'relationshipToSubscriberCode';
        reason = 'invalid value for dependent';
        recoverySuggestion = 'Set to valid value for dependent';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
      }
      if (subscriptionUpdate.relationshipToSubscriberCode.displayName !== 'SUBSCRIBER') {
        if (!Validator.isValidString(subscriptionUpdate.primarySubscriberFirstName, false)) {
          fieldName = 'primarySubscriberFirstName';
          reason = 'field required';
          recoverySuggestion = 'Set to non-empty value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
        if (!Validator.isFirstNameValid(subscriptionUpdate.primarySubscriberFirstName)) {
          fieldName = 'primarySubscriberFirstName';
          reason = 'invalid value';
          recoverySuggestion = 'Set to valid value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
        if (!Validator.isValidString(subscriptionUpdate.primarySubscriberLastName, false)) {
          fieldName = 'primarySubscriberLastName';
          reason = 'field required';
          recoverySuggestion = 'Set to non-empty value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
        if (!Validator.isLastNameValid(subscriptionUpdate.primarySubscriberLastName)) {
          fieldName = 'primarySubscriberLastName';
          reason = 'invalid value';
          recoverySuggestion = 'Set to valid value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
        if (subscriptionUpdate.primarySubscriberDateOfBirth == null) {
          fieldName = 'primarySubscriberDateOfBirth';
          reason = 'field required';
          recoverySuggestion = 'Set to non-empty value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
        if (subscriptionUpdate.primarySubscriberDateOfBirth != null && (subscriptionUpdate.primarySubscriberDateOfBirth instanceof Date) && subscriptionUpdate.primarySubscriberDateOfBirth.getTime() > Date.now()) {
          fieldName = 'primarySubscriberDateOfBirth';
          reason = 'invalid value';
          recoverySuggestion = 'Set to valid value';
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKSubscriptionUpdate, fieldName, reason, recoverySuggestion));
        }
      }
    }
  }

  /**
   * This method is used to add a service key to a {@link model.AWSDKConsumer|AWSDKConsumer}.
   * @param {model.AWSDKConsumer} consumer the authenticated consumer that the service key will be added too.
   * @param {String} serviceKey the service key to add
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a promise that resolves to a {@link model.AWSDKConsumer|AWSDKConsumer} or is rejected as {@link error.AWSDKError|AWSDKError} if there are issues with the call
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.validationError}</td><td>Consumer is not a valid instance of AWSDKConsumer or serviceKey is null.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidServiceKeyError|AWSDKErrorCode.invalidServiceKeyError}</td><td>Invalid service key</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  addServiceKey(consumer, serviceKey) {
    const currentFunction = 'ConsumerService.addServiceKey';
    this.__logger.debug(currentFunction, 'Started', consumer, serviceKey);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (serviceKey == null) {
      const error = AWSDKError.AWSDKIllegalArgument('serviceKey is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'serviceKey');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "serviceKey" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(serviceKey)) {
      const error = AWSDKError.AWSDKInvalidServiceKeyError();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('serviceKey', serviceKey);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Get the current {@link model.AWSDKHealthSummary|AWSDKHealthSummary} for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   *
   * @param consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to use to fetch the payment on file
   * @returns {Promise<model.AWSDKHealthSummary|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKHealthSummary} <br>
   * or rejected with an {@link error.AWSDKError}
   * <tr><td>{@link error.AWSDKErrorCode.authenticationAccessDenied|AWSDKErrorCode.authenticationAccessDenied}</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getHealthSummary(consumer) {
    const currentFunction = 'ConsumerService.getHealthSummary';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      return Promise.reject(AWSDKError.AWSDKIllegalArgument('consumerRegistration is null or not an instance of AWSDKConsumer'));
    }
    const link = this.findNamedLink(consumer.links, 'healthSummary');
    if (!Validator.isValidLink(link)) {
      return Promise.reject(AWSDKError.AWSDKInternalError('consumer does not have a "healthSummary" link entry'));
    }
    const options = this.generateOptions('GET', link.url, false);
    const auth = this.getUserAuth(consumer);
    if (auth == null) {
      return Promise.reject(AWSDKError.AWSDKConsumerNotAuthenticated());
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKHealthSummaryResponse)
      .then((healthSummaryResponse) => {
        this.updateUserAuthEntry(consumer, healthSummaryResponse.authToken);
        return healthSummaryResponse.healthSummary;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /** This service method allows the fetching of an image card from an {@link model.AWSDKHealthPlan|AWSDKHealthPlan}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} subscribing to the health plan
   * @param {model.AWSDKHealthPlan} healthPlan the insurance health plan to which the consumer is a subscriber and whose image card we seek to fetch.
   * @returns {Promise<Blob|error.AWSDKError>} a Promise that will resolve to a {@link Blob} of data or reject to an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getHealthPlanCardImage(consumer, healthPlan) {
    const currentFunction = 'HealthPlanService.getHealthPlanCardImage';
    this.__logger.debug(currentFunction, 'Started', consumer, healthPlan);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(healthPlan instanceof AWSDKHealthPlan)) {
      const error = AWSDKError.AWSDKIllegalArgument('healthPlan is null or not an instance of AWSDKHealthPlan');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(healthPlan.links, 'cardImage');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('healthPlan does not have a valid "cardImage" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options)
      .then((blob) => {
        this.__logger.debug(currentFunction, 'Got response', blob);
        return blob;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method fetches a {@link model.AWSDKVisitReport|AWSDKVisitReport} for a consumer. The visit report is in PDF format and pertains to a previous visit <br>
   * between this consumer and a given provider.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose visit report we want to fetch
   * @param {model.AWSDKVisitReport} visitReport the object representing a {@link model.AWSDKVisitReport|AWSDKVisitReport} with links to its PDF resource
   * @returns {Promise<Blob|error.AWSDKError>} a Promise that will resolve to a Blob or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>consumer is not an instance of AWSDKConsumer or visitReport is not an instance of AWSDKVisitReport</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  getVisitReportPDF(consumer, visitReport) {
    const currentFunction = 'ConsumerService.getVisitReportPDF';
    this.__logger.debug(currentFunction, 'Started', consumer, visitReport);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(visitReport instanceof AWSDKVisitReport)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitReport is not an instance of AWSDKVisitReport');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visitReport.links, 'report');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visitReport does not have a valid "report" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.headers.Accept = 'application/pdf';
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options)
      .then((blob) => {
        this.__logger.debug(currentFunction, 'Got response', blob);
        return blob;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method fetches a {@link model.AWSDKVisitReportDetail|AWSDKVisitReportDetail} for a consumer.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose visit report we want to fetch
   * @param {model.AWSDKVisitReport} visitReport the object representing a {@link model.AWSDKVisitReport|AWSDKVisitReport} with links to its detail resource
   * @returns {Promise<model.AWSDKVisitReportDetail|error.AWSDKError>} a Promise that will resolve to a {@link model.AWSDKVisitReportDetail|AWSDKVisitReportDetail} or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>consumer is not an instance of AWSDKConsumer or visitReport is not an instance of AWSDKVisitReport</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.0
   */
  getVisitReportDetail(consumer, visitReport) {
    const currentFunction = 'ConsumerService.getVisitReportDetail';
    this.__logger.debug(currentFunction, 'Started', consumer, visitReport);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(visitReport instanceof AWSDKVisitReport)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitReport is not an instance of AWSDKVisitReport');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visitReport.links, 'data');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visitReport does not have a valid "data" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options, AWSDKVisitReportDetailResponse)
      .then((visitReportDetailResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitReportDetailResponse);
        this.updateUserAuthEntry(consumer, visitReportDetailResponse.authToken);
        return visitReportDetailResponse.visitReportDetail;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method allows the currently authenticated {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve the attachment associated with the {@link model.AWSDKHealthDocumentRecord|AWSDKHealthDocumentRecord}
   * @argument {model.AWSDKConsumer} consumer the currently authenticated consumer
   * @argument {model.AWSDKHealthDocumentRecord} healthDocRecord the health document record whose attachment you want to get.
   * @returns {Promise<Blob|error.AWSDKError>} a promise that will resolve to a Blob of data or rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedMimeType|AWSDKErrorCode.unsupportedMimeType}</td><td>The mime type of data is not supported. See {@link model.AWSDKSystemConfiguration#mimeTypeWhitelist|AWSDKSystemConfiguration.mimeTypeWhitelist}</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  getHealthDocumentRecordAttachment(consumer, healthDocRecord) {
    const currentFunction = 'ConsumerService.getHealthDocumentRecordAttachment';
    this.__logger.debug(currentFunction, 'Started', consumer, healthDocRecord);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(healthDocRecord instanceof AWSDKHealthDocumentRecord)) {
      const error = AWSDKError.AWSDKIllegalArgument('healthDocRecord is not an instance of AWSDKHealthDocumentRecord');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const attachment = healthDocRecord.attachment;
    if (!(attachment instanceof AWSDKHealthDocumentAttachment)) {
      const error = AWSDKError.AWSDKIllegalArgument('healthDocRecord.attachment is not an instance of AWSDKHealthDocumentAttachment');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(attachment.links, 'getAttachment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('attachment does not have a valid "getAttachment" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    const mimeType = attachment.type;
    const currAccHeader = options.headers.Accept;
    options.headers.Accept = `${currAccHeader}, ${mimeType}`;
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options)
      .then((blob) => {
        if (!(blob instanceof Blob)) {
          return new Blob([blob], { type: mimeType });
        }
        this.__logger.info(currentFunction, 'Completed');
        return blob;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method allows the currently authenticated {@link model.AWSDKConsumer|AWSDKConsumer} to remove an existing {@link model.AWSDKHealthDocumentRecord|AWSDKHealthDocumentRecord} from consumer's health history
   * @argument {model.AWSDKConsumer} consumer the currently authenticated consumer whose record we want to remove
   * @argument {model.AWSDKHealthDocumentRecord} healthDocRecord the health document we want to remove from health history
   * @returns {Promise<boolean|error.AWSDKError>} returns a Promise that will resolve to a boolean true or reject with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  removeHealthDocumentRecord(consumer, healthDocRecord) {
    const currentFunction = 'ConsumerService.removeHealthDocumentRecord';
    this.__logger.debug(currentFunction, 'Started', consumer, healthDocRecord);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (healthDocRecord == null) {
      const error = AWSDKError.AWSDKIllegalArgument('healthDocRecord is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(healthDocRecord instanceof AWSDKHealthDocumentRecord)) {
      const error = AWSDKError.AWSDKIllegalArgument('healthDocRecord is not an instance of AWSDKHealthDocumentRecord');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(healthDocRecord.links, 'delete');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('healthDocRecord does not have a valid "delete" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('DELETE', link.url, false);
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options)
      .then(() => {
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method adds a health document record to the {@link model.AWSDKConsumer|AWSDKConsumer}'s files.
   * @argument {model.AWSDKConsumer} consumer the consumer whose health documents we want to update.
   * @argument {Blob|File} data a blob of data represented by a blob or a file.
   * @argument {String} fileName the name of the file we want to update.
   * @argument {model.AWSDKVisit} [visit] an optional visit whose health documents we want to update.
   * @argument {String} [comment] an optional comment about the health document we want to update.
   * @returns {Promise<model.AWSDKHealthDocumentRecord|error.AWSDKError>} a promise that will be resolved to a {@link model.AWSDKHealthDocumentRecord|AWSDKHealthDocumentRecord} or <br>
   * rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.attachmentSizeTooLarge|AWSDKErrorCode.attachmentSizeTooLarge}</td><td>The size of data is larger than supported. See {@link model.AWSDKSystemConfiguration#secureMessageAttachmentMaxSizeKB|AWSDKSystemConfiguration.secureMessageAttachmentMaxSizeKB}</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedMimeType|AWSDKErrorCode.unsupportedMimeType}</td><td>The mime type of data is not supported. See {@link model.AWSDKSystemConfiguration#mimeTypeWhitelist|AWSDKSystemConfiguration.mimeTypeWhitelist}</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.failedVirusScan|AWSDKErrorCode.failedVirusScan}</td><td>The attachment has a virus detcted by the telehealth platform. </td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.fileExists|AWSDKErrorCode.fileExists}</td><td>The attachment already exists on the telehealth platform. </td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.fileReadError|AWSDKErrorCode.fileReadError}</td><td>The attachment could not be uploaded to the telehealth platform. </td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  async addHealthDocumentAttachment(consumer, data, fileName, visit, comment) {
    const currentFunction = 'ConsumerService.addHealthDocumentAttachment';
    this.__logger.debug(currentFunction, 'Started', consumer, data, fileName);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(data instanceof Blob) && !(data instanceof File)) {
      const error = AWSDKError.AWSDKIllegalArgument('data must be a Blob or File');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(fileName)) {
      const error = AWSDKError.AWSDKIllegalArgument('filename is not an instance of String');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'addHealthDocumentRecord');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "addHealthDocumentRecord" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const size = data.size;
    const maxSizeInKb = this.__systemConfiguration.secureMessageAttachmentMaxSizeKB;
    if (maxSizeInKb < (size / 1024)) {
      const error = AWSDKError.AWSDKAttachmentSizeTooLarge('data', maxSizeInKb);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (visit && !(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit optional argument is not an instance of AWSDKVisit');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (comment && !Validator.isValidString(comment)) {
      const error = AWSDKError.AWSDKIllegalArgument('comment optional argument is not an instance of String');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    const type = await getMimeType(data);
    if (!this.__systemConfiguration.mimeTypeWhitelist.includes(type)) {
      const error = AWSDKError.AWSDKUnsupportedMimeType('data');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.headers.Accept = `${options.headers.Accept}, ${type}`;
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    const fd = new FormData();
    fd.append('media', data, fileName);
    fd.append('filename', fileName);
    if (visit) fd.append('engagementId', visit.id.encryptedId);
    if (comment) fd.append('comment', comment);
    options.body = fd;
    return this.executeRequest(options, AWSDKHealthDocumentRecordResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return response.documentRecord;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }


  /**
   * Get the current shipping {@link model.AWSDKAddress|AWSDKAddress} for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   *
   * @param consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose shipping address to fetch
   * @returns {Promise<model.AWSDKAddress|error.AWSDKError>} returns a promise that will be resolved to an {@link model.AWSDKAddress} <br>
   * or rejected with an {@link error.AWSDKError}
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Unauthenticated consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>consumer is not a valid instance of AWSDKConsumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.noShippingAddressFound|AWSDKErrorCode.noShippingAddressFound}</td><td>No shipping address was found for the consumer</td></tr>
   * </tbody>
   * </table>
   */
  getShippingAddress(consumer) {
    const currentFunction = 'ConsumerService.getShippingAddress';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'shipping');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "shipping" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKAddressResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.address;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Update the given {@link model.AWSDKConsumer}'s shipping address to the data provided in the given {@link model.AWSDKAddressUpdate} object
   * @param {model.AWSDKConsumer} consumer the consumer to update
   * @param {model.AWSDKAddressUpdate} shippingAddress the consumer's shipping address to update
   * @returns {Promise<model.AWSDKAddress|error.AWSDKError>} a Promise that resolves to the updated {@link model.AWSDKAddress|AWSDKAddress}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If the zipCode of the shippingAddress argument is invalid.</td></tr>
   * </tbody>
   * </table>
   */
  updateShippingAddress(consumer, shippingAddress) {
    const currentFunction = 'ConsumerService.updateShippingAddress';
    const errors = [];
    this.__logger.debug(currentFunction, 'Started', consumer, shippingAddress);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (shippingAddress == null) {
      const error = AWSDKError.AWSDKIllegalArgument('shippingAddress is null');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(shippingAddress instanceof AWSDKAddressUpdate)) {
      const error = AWSDKError.AWSDKIllegalArgument('shippingAddress argument is not an instance AWSDKAddressUpdate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (Validator.isValidString(shippingAddress.zipCode) && !Validator.isZipCodeValid(shippingAddress.zipCode)) {
      const fieldName = 'shippingZipCode';
      const reason = 'invalid format';
      const recoverySuggestion = 'see valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerRegistration, fieldName, reason, recoverySuggestion));
      const error = AWSDKError.AWSDKValidationErrors(errors);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'shipping');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "shipping" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    options.form.set('address1', shippingAddress.address1);
    options.form.set('address2', shippingAddress.address2);
    options.form.set('city', shippingAddress.city);
    options.form.set('state', shippingAddress.stateCode);
    options.form.set('zipCode', shippingAddress.zipCode);
    options.form.set('country', shippingAddress.countryCode);
    return this.executeRequest(options, AWSDKAddressResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return response.address;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKAddressUpdate|AWSDKAddressUpdate} to use for updating a consumer's shipping address. <br>
   * @param {String} address1 the first line of the address
   * @param {String} address2 the second line of the address
   * @param {String} city  the city name
   * @param {model.AWSDKState} geographicalState the actual underlying {@link model.AWSDKState} of the address
   * @param {String} zipCode  the zip (postal) code for this physical location
   * @returns {model.AWSDKAddressUpdate} returns an instance of a {@link model.AWSDKAddressUpdate|AWSDKAddressUpdate}
   * @throws {error.AWSDKError} with an {@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument} if the state is not an instance of {@link model.AWSDKState|AWSDKState}
   */
  newAddressUpdate(address1, address2, city, state, zipCode) {
    return new AWSDKAddressUpdate(address1, address2, city, state, zipCode);
  }

  /**
   * Checks whether a given field on a given consumer is considered protected (i.e. it's feed controlled) <br>
   * @param {model.AWSDKConsumer} consumer the consumer whose field to check
   * @param {String} fieldName the name of the field on the consumer to check
   * @returns {Boolean} returns TRUE if the given field is protected on the given provider
   */
  isFieldProtected(consumer, fieldName) {
    const protectedMap = {
      dob: 'details.birthDate',
      email: 'details.emailAddress',
      firstName: 'details.firstName',
      gender: 'details.gender',
      lastName: 'details.lastName',
      middleInitial: 'details.middleInitial',
      middleName: 'details.middleInitial',
      phone: 'details.phoneNumber',
      address1: 'details.primaryAddress.address1',
      address2: 'details.primaryAddress.address2',
      city: 'details.primaryAddress.city',
      state: 'details.primaryAddress.state',
      zipCode: 'details.primaryAddress.zipCode',
    };
    return consumer.memberType === 'HP' && this.__systemConfiguration.protectedFieldNames.includes(protectedMap[fieldName]);
  }

  /**
   * This method validates the fields in the {@link model.AWSDKTrackerRequest|AWSDKTrackerRequest}
   * @param {model.AWSDKTrackerRequest} trackerRequest the tracker request to be validated
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>data.value</td>
   * <td>out of range field</td>
   * <td>Set to a valid number within the range of the minimum and maximum values of the trackerComponent</td>
   * </tr>
   * @since 1.4.0
   */
  validateTrackerRequest(trackerRequest) {
    const currentFunction = 'ConsumerService.validateTrackerRequest';
    this.__logger.debug(currentFunction, 'Started', trackerRequest);
    const errArrayResult = [];
    this.__validateTrackerRequest(trackerRequest, errArrayResult);
    this.__logger.debug(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateTrackerRequest(trackerRequest, errors) {
    const currentFunction = 'ConsumerService.__validateTrackerRequest';
    this.__logger.debug(currentFunction, 'Started', trackerRequest, errors);
    let fieldName;
    let reason;
    let recoverySuggestion;
    trackerRequest.entries.forEach((entryRequest) => {
      entryRequest.data.forEach((dataPointRequest) => {
        if (dataPointRequest.value < dataPointRequest.trackerComponent.minimum || dataPointRequest.value > dataPointRequest.trackerComponent.maximum) {
          fieldName = dataPointRequest.trackerComponent.title;
          reason = 'out of range field';
          recoverySuggestion = `value must be between ${dataPointRequest.trackerComponent.minimum} and ${dataPointRequest.trackerComponent.maximum} ${dataPointRequest.trackerComponent.unitOfMeasureShortDescription}`;
          errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKTrackerRequest, fieldName, reason, recoverySuggestion));
        }
      });
    });
    this.__logger.debug(currentFunction, 'Finished', errors);
    return errors;
  }

  /**
   * Find all the trackers associated with a particular {@link model.AWSDKConsumer|AWSDKConsumer} that match the trackerTemplate uuid criteria
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} for whom we're seeking trackers for
   * @param {model.AWSDKTrackersSearchCriteria}  trackersSearchCriteria the searchTrackers searchCriteria object to be used
   * @returns {Promise<model.AWSDKTrackerEntry[]|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKTrackerEntry[]|AWSDKTrackerEntry[]}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  searchTrackers(consumer, trackersSearchCriteria) {
    const currentFunction = 'ConsumerService.searchTrackers';
    this.__logger.debug(currentFunction, 'Started', consumer, trackersSearchCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(trackersSearchCriteria instanceof AWSDKTrackersSearchCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria must be of type AWSDKTrackersSearchCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!trackersSearchCriteria.trackerTemplate) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria.trackerTemplate must be set');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!trackersSearchCriteria.timeZone) {
      const error = AWSDKError.AWSDKIllegalArgument('timeZone must be set');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const startDate = trackersSearchCriteria.startDate;
    const endDate = trackersSearchCriteria.endDate;
    if (startDate && endDate && (endDate - startDate) < 0) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria.endDate must be a Date referencing a later date than trackersSearchCriteria.startDate', 'endDate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'trackers');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "trackers" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.form.set('trackerId', trackersSearchCriteria.__uuid());
    options.form.set('timeZone', trackersSearchCriteria.timeZone);
    if (startDate) options.form.set('startDate', Util.formatISODateTime(startDate));
    if (endDate) options.form.set('endDate', Util.formatISODateTime(endDate));
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKTrackerEntriesList)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.trackers;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Delete all the trackers associated with a particular {@link model.AWSDKConsumer|AWSDKConsumer} that match the search criteria
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} for whom we're deleting trackers for
   * @param {model.AWSDKTrackersSearchCriteria} trackerSearchCriteria the trackers search criteria to be used to delete tracker information
   * @returns {Promise<boolean|error.AWSDKError>} a Promise that resolves to a boolean true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  deleteTrackers(consumer, trackersSearchCriteria) {
    const currentFunction = 'ConsumerService.deleteTrackers';
    this.__logger.debug(currentFunction, 'Started', consumer, trackersSearchCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(trackersSearchCriteria instanceof AWSDKTrackersSearchCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria must be an instance of AWSDKTrackersSearchCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!trackersSearchCriteria.trackerTemplate) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria.trackerTemplate must be set');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!trackersSearchCriteria.timeZone) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria.timeZone must be set');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const startDate = trackersSearchCriteria.startDate;
    const endDate = trackersSearchCriteria.endDate;
    if (startDate && endDate && (endDate - startDate) < 0) {
      const error = AWSDKError.AWSDKIllegalArgument('trackersSearchCriteria.endDate must be a Date referencing a later date than trackersSearchCriteria.startDate', 'endDate');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'trackers');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "trackers" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('DELETE', link.url);
    options.form.set('trackerId', trackersSearchCriteria.__uuid());
    options.form.set('timeZone', trackersSearchCriteria.timeZone);
    if (startDate) options.form.set('startDate', Util.formatISODateTime(startDate));
    if (endDate) options.form.set('endDate', Util.formatISODateTime(endDate));
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Add a tracker to a {@link model.AWSDKConsumer|AWSDKConsumer}'s profile
   * @param {model.AWSDKConsumer} consumer the consumer whose tracker we want to add
   * @param {model.AWSDKTrackerRequest} trackerRequest the request object used to add trackers for a given {@link model.AWSDKConsumer|AWSDKConsumer}. This object is obtained by a call to {@see ConsumerService#getNewTrackerRequest}.
   * @returns {Promise<boolean|error.AWSDKError>} a Promise that resolves to a boolean true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the values in trackerDataPointRequest are invalid. The errors property will return an array of validation errors.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  addTracker(consumer, trackerRequest) {
    const currentFunction = 'ConsumerService.addTracker';
    this.__logger.debug(currentFunction, 'Started', consumer, trackerRequest);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(trackerRequest instanceof AWSDKTrackerRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('trackerRequest must be an instance of AWSDKTrackerRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errArray = this.validateTrackerRequest(trackerRequest);
    if (errArray.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errArray);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'trackers');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "trackers" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.headers['Content-Type'] = 'application/json';
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    options.body = trackerRequest.__getAsRequestBody();
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * This method returns an instance of {@link model.AWSDKTrackerRequest|AWSDKTrackerRequest} to be used for adding a tracker to a consumer's profile
   * @returns {model.AWSDKTrackerRequest} an instance of {@link model.AWSDKTrackerRequest}
   */
  getNewTrackerRequest() {
    return new AWSDKTrackerRequest();
  }

  /**
   * This method returns an instance of {@link model.AWSDKTrackerEntryRequest|AWSDKTrackerEntryRequest} to be used for adding a tracker entry to a tracker request
   * @returns {model.AWSDKTrackerEntryRequest} an instance of {@link model.AWSDKTrackerEntryRequest}
   */
  getNewTrackerEntryRequest() {
    return new AWSDKTrackerEntryRequest();
  }

  /**
   * This method returns an instance of {@link model.AWSDKTrackerDataPointRequest|AWSDKTrackerDataPointRequest} to be used for adding a tracker data point to a tracker entry request
   * @returns {model.AWSDKTrackerDataPointRequest} an instance of {@link model.AWSDKTrackerDataPointRequest}
   */
  getNewTrackerDataPointRequest() {
    return new AWSDKTrackerDataPointRequest();
  }

  /**
   * This method returns an instance of {@link model.AWSDKTrackersSearchCriteria|AWSDKTrackersSearchCriteria} to be used to create search criteria for a {@link service.ConsumerService#searchTrackers} or {@link service.ConsumerService#deleteTrackers} request
   * @param {Object} searchCriteria the search criteria object to be used
   * @returns {model.AWSDKTrackersSearchCriteria} an instance of {@link model.AWSDKTrackersSearchCriteria}
   */
  getNewTrackersSearchCriteria(searchCriteria) {
    return new AWSDKTrackersSearchCriteria(searchCriteria);
  }

  /**
   * Get an instance of {@link model.AWSDKExamDataRequest|AWSDKExamDataRequest} to use when creating an exam data request for a device integration request.
   * @returns {model.AWSDKExamDataRequest} an instance of {@link model.AWSDKExamDataRequest}
   * @since 1.4.0
   */
  getNewExamDataRequest() {
    return new AWSDKExamDataRequest();
  }

  /**
   * Get an instance of {@link model.AWSDKDeviceIntegrationRequest|AWSDKDeviceIntegrationRequest} to use when adding device integration data. <br>
   * @param {model.AWSDKExamDataRequest[]} examDataEntries the list of exam data entries associated with the device integration request
   * @returns {model.AWSDKDeviceIntegrationRequest} returns an instance of a {@link model.AWSDKDeviceIntegrationRequest|AWSDKDeviceIntegrationRequest} object
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the items in examDataEntries is invalid. The errors property will return the array of validation errors.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  getNewDeviceIntegrationRequest(examDataEntries, visit = null) {
    return new AWSDKDeviceIntegrationRequest(examDataEntries, visit);
  }

  /**
   * This method validates the fields in the {@link model.AWSDKDeviceIntegrationRequest|AWSDKDeviceIntegrationRequest}
   * @param {model.AWSDKDeviceIntegrationRequest} deviceIntegrationRequest the device integration request to be validated
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>id</td>
   * <td>invalid format</td>
   * <td>Set to a string value.</td>
   * </tr>
   * <tr>
   * <td>id</td>
   * <td>required field</td>
   * <td>This field is required.</td>
   * </tr>
   * <tr>
   * <td>type</td>
   * <td>invalid format</td>
   * <td>Set to a string value.</td>
   * </tr>
   * <tr>
   * <td>type</td>
   * <td>required field</td>
   * <td>This field is required.</td>
   * </tr>
   * <tr>
   * <td>dateTime</td>
   * <td>invalid format</td>
   * <td>Set to a dateTime value.</td>
   * </tr>
   * <tr>
   * <td>dateTime</td>
   * <td>required field</td>
   * <td>This field is required.</td>
   * </tr>
   * <tr>
   * </table>
   * @since 1.4.0
   */
  validateDeviceIntegrationRequest(deviceIntegrationRequest) {
    const currentFunction = 'ConsumerService.validateDeviceIntegrationRequest';
    this.__logger.debug(currentFunction, 'Started', deviceIntegrationRequest);
    const errArrayResult = [];
    this.__validateDeviceIntegrationRequest(true, deviceIntegrationRequest, errArrayResult);
    this.__logger.debug(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateDeviceIntegrationRequest(fieldsRequired, deviceIntegrationRequest, errors) {
    const currentFunction = 'ConsumerService.__validateDeviceIntegrationRequest';
    this.__logger.debug(currentFunction, 'Started', fieldsRequired, deviceIntegrationRequest, errors);
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (!Array.isArray(deviceIntegrationRequest.examDataEntries)) {
      fieldName = 'deviceIntegrationRequest.examDataEntries';
      reason = 'invalid type';
      recoverySuggestion = 'examDataEntries must be of type Array';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
    }
    if (deviceIntegrationRequest.examDataEntries.length === 0) {
      fieldName = 'deviceIntegrationRequest.examDataEntries';
      reason = 'empty exam data array';
      recoverySuggestion = 'examDataEntries must have at least one element';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
    }
    deviceIntegrationRequest.examDataEntries.forEach((examDataEntry) => {
      if (!(examDataEntry instanceof AWSDKExamDataRequest)) {
        fieldName = 'deviceIntegrationRequest.examDataEntries';
        reason = 'invalid type';
        recoverySuggestion = 'entry must be of type AWSDKExamDataRequest';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !Validator.isValidString(examDataEntry.id)) {
        fieldName = 'id';
        reason = 'field required';
        recoverySuggestion = 'See valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (!Validator.isStringType(examDataEntry.id)) {
        fieldName = 'id';
        reason = 'invalid type';
        recoverySuggestion = 'id must be of type string';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !Validator.isValidString(examDataEntry.type)) {
        fieldName = 'type';
        reason = 'field required';
        recoverySuggestion = 'See valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (!Validator.isStringType(examDataEntry.type)) {
        fieldName = 'type';
        reason = 'invalid type';
        recoverySuggestion = 'type must be of type String';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !examDataEntry.dateTime) {
        fieldName = 'dateTime';
        reason = 'field required';
        recoverySuggestion = 'See valid format in docs';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
      if (fieldsRequired && !Validator.isValidDate(examDataEntry.dateTime)) {
        fieldName = 'dateTime';
        reason = 'invalid type';
        recoverySuggestion = 'dateTime must be of type Date';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKDeviceIntegrationRequest, fieldName, reason, recoverySuggestion));
      }
    });
    this.__logger.debug(currentFunction, 'Finished', errors);
    return errors;
  }

  /**
   * Updates the given {@link model.AWSDKConsumer}'s profile with the data provided in the given {@link model.AWSDKDeviceIntegrationRequest} object
   * Also updates {@link model.AWSDKVisit} with the data provided in the given {@link model.AWSDKDeviceIntegrationRequest} object
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} associated with the device integration data request
   * @param {model.AWSDKDeviceIntegrationRequest} deviceIntegrationRequest the {@link model.AWSDKDeviceIntegrationRequest|AWSDKDeviceIntegrationRequest}
   * @returns {Promise<boolean|error.AWSDKError>} a Promise that resolves to a boolean true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the deviceIntegrationRequest values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  addDeviceIntegrationData(consumer, deviceIntegrationRequest) {
    const currentFunction = 'ConsumerService.addDeviceIntegrationData';
    this.__logger.debug(currentFunction, 'Started', deviceIntegrationRequest);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(deviceIntegrationRequest instanceof AWSDKDeviceIntegrationRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('deviceIntegrationRequest is not an instance of AWSDKDeviceIntegrationRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const validationErrors = this.validateDeviceIntegrationRequest(deviceIntegrationRequest);
    if (validationErrors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(validationErrors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'deviceIntegrationData');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "deviceIntegrationData" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    options.body = deviceIntegrationRequest.__toRequestBody();
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.trace(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.debug(currentFunction, 'Finished');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get {@link model.AWSDKOnlineVisitFollowUpItems|AWSDKOnlineVisitFollowUpItems}s for a given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to search {@link model.AWSDKOnlineVisitFollowUpItem|AWSDKOnlineVisitFollowUpItem}s for
   * @returns {Promise<model.AWSDKPaginatedOnlineVisitFollowUpItems|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKPaginatedOnlineVisitFollowUpItem|AWSDKPaginatedOnlineVisitFollowUpItem}s, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 3.0.0
   */
  getOnlineVisitFollowUpItems(consumer) {
    const currentFunction = 'ConsumerService.getOnlineVisitFollowUpItems';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(consumer.links, 'agendaItemFollowUps');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "agendaItemFollowUps" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('GET', link.url, false);
    request.auth = this.getUserAuth(consumer);
    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.executeRequest(request, AWSDKPaginatedOnlineVisitFollowUpItemsResponse)
      .then((response) => {
        this.__logger.trace(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.debug(currentFunction, 'Finished');
        return response.paginatedOnlineVisitFollowUpItems;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Request all unresolved Practice Follow ups
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to search AWSDKPracticeFollowUpItems for
   * @param {Object} [options] the object containing pagination parameters
   * @param {number} [options.pageSize] if provided will limit the pageSize to the value of the provided integer
   * @returns {Promise<model.AWSDKPaginatedPracticeFollowUpItems|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKPaginatedPracticeFollowUpItems|AWSDKPaginatedPracticeFollowUpItems}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 3.2.0
   */
  searchPracticeFollowUpItems(consumer, options = {}) {
    const currentFunction = 'ConsumerService.searchPracticeFollowUpItems';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(consumer.links, 'practiceFollowUps');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "practiceFollowUps" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('POST', link.url, false);
    request.auth = this.getUserAuth(consumer);
    request.headers['Content-Type'] = 'application/json';

    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.__buildPaginatedRequest(consumer, options, link.url)
      .then(request => this.executeRequest(request, AWSDKPaginatedPracticeFollowUpItemsResponse))
      .then((response) => {
        this.__logger.trace(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.debug(currentFunction, 'Finished');
        return response.paginatedPracticeFollowUpItems;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Search {@link model.PostVisitFollowUpItem}s for a given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to search PostVisitFollowUpItems for
   * @param {Object} [options] the object containing search criteria and parameters
   * @param {number}  [options.pageSize] if provided will limit the pageSize to the value of the provided integer
   * @param {Date}  [options.startDate] if provided will return only items after the date
   * @param {Date}  [options.endDate] if provided will return only items before the date
   * @param {model.AWSDKPostVisitFollowUpItemsTypeFilter}  [options.type] if provided will return only items of the specified type
   * @param {boolean}  [options.resolved] if provided will filter items to only those with a matching resolved status
   * @returns {Promise<model.AWSDKPaginatedPostVisitFollowUpItems|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKPaginatedPostVisitFollowUpItems|AWSDKPaginatedPostVisitFollowUpItems}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  searchPostVisitFollowUpItems(consumer, options = {}) {
    const currentFunction = 'ConsumerService.searchPostVisitFollowUpItems';
    this.__logger.debug(currentFunction, 'Started', consumer, options);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'postVisitFollowUpItems');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "postVisitFollowUpItems" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options.type && !Validator.isValidStringEnumValue(options.type, AWSDKPostVisitFollowUpItemsTypeFilter)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.type must be a AWSDKPostVisitFollowUpItemsTypeFilter');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options.resolved != null && typeof options.resolved !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('options.resolved must a boolean');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    return this.__buildPaginatedRequest(consumer, options, link.url)
      .then(request => this.executeRequest(request, AWSDKPaginatedPostVisitFollowUpItemsResponse))
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return result.paginatedPostVisitFollowUpItems;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }


  /**
   * Mark an individual {@link model.AWSDKPracticeFollowUpItem} as resolved.
   *
   * The resolved attribute on {@link model.AWSDKPracticeFollowUpItem} must be false.
   *
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} that owns the {@link model.AWSDKPracticeFollowUpItem}
   * @param {model.AWSDKPracticeFollowUpItem} AWSDKPracticeFollowUpItem the {@link model.AWSDKPracticeFollowUpItem} to mark as resolved.
   * @returns {Promise<Boolean|error.AWSDKError>} a Promise that resolves to true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 3.2.0
   */
  markPracticeFollowUpItemResolved(consumer, practiceFollowUpItem) {
    const currentFunction = 'ConsumerService.markPracticeFollowUpItemResolved';
    this.__logger.debug(currentFunction, 'Started', consumer, practiceFollowUpItem);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(practiceFollowUpItem instanceof AWSDKPracticeFollowUpItem)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFollowUpItem" must be of type AWSDKPractice');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(practiceFollowUpItem.links, 'resolve');

    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('practiceFollowUpItem  does not have a valid "resolve" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (practiceFollowUpItem.resolved) {
      const error = AWSDKError.AWSDKIllegalArgument('practiceFollowUpItem.resolved must be FALSE');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('PUT', link.url, false);
    request.auth = this.getUserAuth(consumer);

    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.executeRequest(request, AWSDKResponse)
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Mark an individual {@link model.PostVisitFollowUpItem} as resolved.
   *
   * The resolved attribute on {@link model.PostVisitFollowUpItem} must be false.
   *
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} that owns the {@link model.PostVisitFollowUpItem}
   * @param {model.PostVisitFollowUpItem} postVisitFollowUpItem the {@link model.PostVisitFollowUpItem} to mark as resolved.
   * @returns {Promise<Boolean|error.AWSDKError>} a Promise that resolves to true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  markPostVisitFollowUpItemResolved(consumer, postVisitFollowUpItem) {
    const currentFunction = 'ConsumerService.markPostVisitFollowUpItemResolved';
    this.__logger.debug(currentFunction, 'Started', consumer, postVisitFollowUpItem);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(postVisitFollowUpItem instanceof AWSDKPostVisitFollowUpItem)) {
      const error = AWSDKError.AWSDKIllegalArgument('postVisitFollowUpItem must be an instance of AWSDKPostVisitFollowUpItem');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(postVisitFollowUpItem.links, 'resolve');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('postVisitFollowUpItem does not have a valid "resolve" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (postVisitFollowUpItem.resolved) {
      const error = AWSDKError.AWSDKIllegalArgument('postVisitFollowUpItem.resolved must be FALSE');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('PUT', link.url, false);
    request.auth = this.getUserAuth(consumer);

    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.executeRequest(request, AWSDKResponse)
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Dismiss an individual {@link model.AWSDKOnlineVisitFollowUpItem|AWSDKOnlineVisitFollowUpItem}.
   *
   * The resolved attribute on the {@link model.AWSDKOnlineVisitFollowUpItem|AWSDKOnlineVisitFollowUpItem} must be false.
   *
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} that owns the {@link model.AWSDKOnlineVisitFollowUpItem|AWSDKOnlineVisitFollowUpItem}
   * @param {model.AWSDKOnlineVisitFollowUpItem} onlineVisitFollowUpItem the {@link model.AWSDKOnlineVisitFollowUpItem|AWSDKOnlineVisitFollowUpItem} to dismiss.
   * @returns {Promise<Boolean|error.AWSDKError>} a Promise that resolves to true, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 3.0.0
   */
  dismissOnlineVisitFollowUpItem(consumer, onlineVisitFollowUpItem) {
    const currentFunction = 'ConsumerService.dismissOnlineVisitFollowUpItem';
    this.__logger.debug(currentFunction, 'Started', consumer, onlineVisitFollowUpItem);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(onlineVisitFollowUpItem instanceof AWSDKOnlineVisitFollowUpItem)) {
      const error = AWSDKError.AWSDKIllegalArgument('onlineVisitFollowUpItem must be an instance of AWSDKOnlineVisitFollowUpItem');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(onlineVisitFollowUpItem.links, 'disposition');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('onlineVisitFollowUpItem does not have a valid "disposition" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (onlineVisitFollowUpItem.resolved) {
      const error = AWSDKError.AWSDKIllegalArgument('onlineVisitFollowUpItem.resolved must be FALSE');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('PUT', link.url, false);
    request.auth = this.getUserAuth(consumer);

    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.executeRequest(request, AWSDKResponse)
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method fetches a non-appointment type {@link model.AWSDKPostVisitFollowUpItem|AWSDKPostVisitFollowUpItem} for a consumer in PDF format.
   *
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose postVisitFollowUpItem we want to fetch
   * @param {model.AWSDKPostVisitFollowUpItem} postVisitFollowUpItem the non-appointment item to fetch a PDF of
   * @returns {Promise<Blob|error.AWSDKError>} a Promise that will resolve to a Blob or rejected with an {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  getPostVisitFollowUpItemPDF(consumer, postVisitFollowUpItem) {
    const currentFunction = 'ConsumerService.getPostVisitFollowUpItemPDF';
    this.__logger.debug(currentFunction, 'Started', consumer, postVisitFollowUpItem);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(postVisitFollowUpItem instanceof AWSDKPostVisitFollowUpItem)) {
      const error = AWSDKError.AWSDKIllegalArgument('postVisitFollowUpItem is not an instance of AWSDKPostVisitFollowUpItem');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (postVisitFollowUpItem.type === AWSDKPostVisitFollowUpItemType.APPOINTMENT) {
      const error = AWSDKError.AWSDKIllegalArgument('postVisitFollowUpItem.type must not be APPOINTMENT');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(postVisitFollowUpItem.links, 'download');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('postVisitFollowUpItem does not have a valid "download" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.headers.Accept = 'application/pdf';
    const auth = this.getUserAuth(consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.auth = auth;
    return this.executeRequest(options)
      .then((blob) => {
        this.__logger.debug(currentFunction, 'Got response', blob);
        return blob;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Search {@link model.AWSDKVisitReport}s for a given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to search AWSDKVisitReports for
   * @param {Object} [options] the object containing search criteria and parameters
   * @param {number}  [options.pageSize] if provided will limit the pageSize to the value of the provided integer
   * @param {Date}  [options.startDate] if provided will return only items after the date
   * @param {Date}  [options.endDate] if provided will return only items before the date
   * @param {model.AWSDKDisposition[]}  [options.dispositions] if provided will filter visit reports to only matching dispositions, otherwise will return only Completed visits
   * @param {boolean}  [options.scheduledOnly] if provided will filter visit reports to those for scheduled visits
   * @returns {Promise<model.AWSDKPaginatedVisitReports|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKPaginatedVisitReports|AWSDKPaginatedVisitReports}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  searchVisitReports(consumer, options = {}) {
    const currentFunction = 'ConsumerService.searchVisitReports';
    this.__logger.debug(currentFunction, 'Started', consumer, options);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'visitReportList');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "visitReportList" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options.scheduledOnly != null && typeof options.scheduledOnly !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('options.scheduledOnly must a boolean');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options.dispositions && (!Array.isArray(options.dispositions) || !options.dispositions.every(d => Validator.isValidEnumValue(d, AWSDKDisposition)))) {
      const error = AWSDKError.AWSDKIllegalArgument('options.dispositions must be a valid array of AWSDKDisposition types');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    // convert to correct format for request
    if (options.dispositions) {
      options.dispositions = options.dispositions.map(d => GenericParser.parseAndConvertEnumValue(d));
    }

    return this.__buildPaginatedRequest(consumer, options, link.url)
      .then(request => this.executeRequest(request, AWSDKPaginatedVisitReportsResponse))
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return result.paginatedVisitReports;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Search {@link model.AWSDKHealthDocumentRecords}s for a given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to search AWSDKHealthDocumentRecords for
   * @param {Object} [options] the object containing search criteria and parameters
   * @param {number}  [options.pageSize] if provided will limit the pageSize to the value of the provided integer
   * @param {Date}  [options.startDate] if provided will return only items after the date
   * @param {Date}  [options.endDate] if provided will return only items before the date
   * @param {boolean}  [options.sortAsc] set to TRUE to sort the documents by uploadDate in ascending order. Defaults to descending order.
   * @returns {Promise<model.AWSDKPaginatedHealthDocumentRecords|error.AWSDKError>} a Promise that resolves to {@link model.AWSDKPaginatedHealthDocumentRecords|AWSDKPaginatedHealthDocumentRecords}, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  searchHealthDocumentRecords(consumer, options = {}) {
    const currentFunction = 'ConsumerService.searchHealthDocumentRecords';
    this.__logger.debug(currentFunction, 'Started', consumer, options);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'healthDocumentRecordList');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "healthDocumentRecordList" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options.sortAsc != null && typeof options.sortAsc !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('options.sortAsc must a boolean');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    return this.__buildPaginatedRequest(consumer, options, link.url)
      .then(request => this.executeRequest(request, AWSDKPaginatedHealthDocumentRecordsResponse))
      .then((result) => {
        this.__logger.debug(currentFunction, 'Got response', result);
        this.updateUserAuthEntry(consumer, result.authToken);
        return result.paginatedHealthDocumentRecords;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}
export default ConsumerService;
