/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKConsumer from './model/consumer/awsdk_consumer';
import AWSDKPaymentMethod from './model/billing/awsdk_payment_method';
import AWSDKPractice from './model/practice/awsdk_practice';
import AWSDKProviderDetails from './model/provider/awsdk_provider_details';
import AWSDKVisit from './model/visit/awsdk_visit';
import AWSDKVisitContext from './model/visit/awsdk_visit_context';
import AWSDKVisitSummary from './model/visit/awsdk_visit_summary';
import AWSDKAppointment from './model/appointment/awsdk_appointment';

import GenericParser from './internal/parser/generic_parser';
import AWSDKOnDemandSpecialty from './model/practice/awsdk_on_demand_specialty';
import AWSDKVideoParticipant from './model/visit/awsdk_video_participant';
import AWSDKAuthentication from './model/authentication/awsdk_authentication';
import AWSDKTrackerTemplate from './model/tracker/awsdk_tracker_template';
import AWSDKOnlineVisitFollowUpItem from './model/visit/awsdk_online_visit_follow_up_item';
import AWSDKHealthSummaryPrescriptionRecord from './model/health/awsdk_health_summary_prescription_record';
import AWSDKMedication from './model/medication/awsdk_medication';
import AWSDKPharmacy from './model/pharmacy/awsdk_pharmacy';
import AWSDKPracticeFollowUpItem from './model/visit/awsdk_practice_follow_up_item';

/**
 * This is the object factory for the American Well Consumer Web SDK<br>
 */
class AWSDKFactory {
  /**
   * This method is used to parse and instantiate an AWSDKConsumer from the provided stringifiedConsumer
   * @param {String} stringifiedConsumer the stringified consumer to restore
   * @returns {model.AWSDKConsumer} the restored consumer
   */
  static restoreConsumer(stringifiedConsumer) {
    const newState = JSON.parse(stringifiedConsumer);
    return GenericParser.parseObject(newState, AWSDKConsumer);
  }

  /**
   * This method is used to parse and instantiate an AWSDKAuthentication from the provided stringifiedAuthentication
   * @param {String} stringifiedAuthentication the stringified authentication to restore
   * @returns {model.AWSDKAuthentication} the restored authentication
   */
  static restoreAuthentication(stringifiedAuthentication) {
    const newState = JSON.parse(stringifiedAuthentication);
    return GenericParser.parseObject(newState, AWSDKAuthentication);
  }

  /**
   * This method is used to parse and instantiate an AWSDKPractice from the provided stringifiedPractice
   * @param {String} stringifiedPractice the stringified practice to restore
   * @returns {model.AWSDKPractice} the restored practice
   */
  static restorePractice(stringifiedPractice) {
    const newState = JSON.parse(stringifiedPractice);
    return GenericParser.parseObject(newState, AWSDKPractice);
  }

  /**
   * This method is used to parse and instantiate an AWSDKProviderDetails from the provided stringifiedProviderDetails
   * @param {String} stringifiedProviderDetails the stringified provider details to restore
   * @returns {model.AWSDKProviderDetails} the restored provider details
   */
  static restoreProviderDetails(stringifiedProviderDetails) {
    const newState = JSON.parse(stringifiedProviderDetails);
    return GenericParser.parseObject(newState, AWSDKProviderDetails);
  }

  /**
   * This method is used to parse and instantiate an AWSDKVisitContext from the provided stringifiedVisitContext
   * @param {String} stringifiedVisitContext the stringified visit context to restore
   * @returns {model.AWSDKVisitContext} the restored visit context
   */
  static restoreVisitContext(stringifiedVisitContext) {
    const newState = JSON.parse(stringifiedVisitContext);
    return GenericParser.parseObject(newState, AWSDKVisitContext);
  }

  /**
   * This method is used to parse and instantiate an AWSDKVisit from the provided stringifiedVisit
   * @param {String} stringifiedVisit the stringified visit to restore
   * @returns {model.AWSDKVisit} the restored visit
   */
  static restoreVisit(stringifiedVisit) {
    const newState = JSON.parse(stringifiedVisit);
    return GenericParser.parseObject(newState, AWSDKVisit);
  }

  /**
   * This method is used to parse and instantiate an AWSDKVisitSummary from the provided stringifiedVisitSummary
   * @param {String} stringifiedVisitSummary the stringified visit summary to restore
   * @returns {model.AWSDKVisitSummary} the restored visit summary
   */
  static restoreVisitSummary(stringifiedVisitSummary) {
    const newState = JSON.parse(stringifiedVisitSummary);
    return GenericParser.parseObject(newState, AWSDKVisitSummary);
  }

  /**
   * This method is used to parse and instantiate an AWSDKPaymentMethod from the provided stringifiedPaymentMethod
   * @param {String} stringifiedPaymentMethod the stringified payment method to restore
   * @returns {model.AWSDKPaymentMethod} the restored payment method
   */
  static restorePaymentMethod(stringifiedPaymentMethod) {
    const newState = JSON.parse(stringifiedPaymentMethod);
    return GenericParser.parseObject(newState, AWSDKPaymentMethod);
  }

  /**
   * This method is used to parse and instantiate an AWSDKAppointment from the provided stringifiedAppointment
   * @param {String} stringifiedAppointment the stringified appointment to restore
   * @returns {model.AWSDKAppointment} the restored appointment
   */
  static restoreAppointment(stringifiedAppointment) {
    const newState = JSON.parse(stringifiedAppointment);
    return GenericParser.parseObject(newState, AWSDKAppointment);
  }

  /**
   * This method is used to parse and instantiate an AWSDKOnDemandSpecialty from the provided stringifiedOnDemandSpecialty
   * @param {String} stringifiedOnDemandSpecialty the stringified on demand specialty to restore
   * @returns {model.AWSDKOnDemandSpecialty} the restored on demand specialty
   */
  static restoreSpecialty(stringifiedOnDemandSpecialty) {
    const newState = JSON.parse(stringifiedOnDemandSpecialty);
    return GenericParser.parseObject(newState, AWSDKOnDemandSpecialty);
  }

  /**
   * This method is used to parse and instantiate an AWSDKVideoParticipant from the provided stringifiedVideoParticipant
   * @param {String} stringifiedVideoParticipant the stringified video participant to restore
   * @returns {model.AWSDKVideoParticipant} the restored video participant
   */
  static restoreParticipant(stringifiedVideoParticipant) {
    const newState = JSON.parse(stringifiedVideoParticipant);
    return GenericParser.parseObject(newState, AWSDKVideoParticipant);
  }

  /**
   * This method is used to parse and instantiate an AWSDKTrackerTemplate from the provided stringifiedTrackerTemplate
   * @param {String} stringifiedTrackerTemplate the stringified tracker template to restore
   * @returns {model.AWSDKTrackerTemplate} the restored tracker template
   */
  static restoreTrackerTemplate(stringifiedTrackerTemplate) {
    const newState = JSON.parse(stringifiedTrackerTemplate);
    return GenericParser.parseObject(newState, AWSDKTrackerTemplate);
  }

  /**
   * This method is used to parse and instantiate an AWSDKOnlineVisitFollowUpItem from the provided
   * @param {String} stringifiedOnlineVisitFollowUpItem
   * @returns {model.AWSDKOnlineVisitFollowUpItem} the restored Online Visit Follow Up Item
   * @since 3.0.0
   */
  static restoreOnlineVisitFollowUpItem(stringifiedOnlineVisitFollowUpItem) {
    const newState = JSON.parse(stringifiedOnlineVisitFollowUpItem);
    return GenericParser.parseObject(newState, AWSDKOnlineVisitFollowUpItem);
  }

  /**
   * This method is used to parse and instantiate an AWSDKHealthSummaryPrescriptionRecord from the provided
   * @param {String} stringifiedPrescription
   * @returns {model.AWSDKHealthSummaryPrescriptionRecord} the restored Prescription
   * @since 3.2.0
   */
  static restorePrescription(stringifiedPrescription) {
    const newState = JSON.parse(stringifiedPrescription);
    return GenericParser.parseObject(newState, AWSDKHealthSummaryPrescriptionRecord);
  }

  /**
   * This method is used to parse and instantiate an AWSDKMedication from the provided
   * @param {String} stringifiedMedication
   * @returns {model.AWSDKMedication} the restored Medication
   * @since 3.2.0
   */
  static restoreMedication(stringifiedMedication) {
    const newState = JSON.parse(stringifiedMedication);
    return GenericParser.parseObject(newState, AWSDKMedication);
  }

  /**
   * This method is used to parse and instantiate an AWSDKPharmacy from the provided
   * @param {String} stringifiedPharmacy
   * @returns {model.AWSDKPharmacy} the restored Pharmacy
   * @since 3.2.0
   */
  static restorePharmacy(stringifiedPharmacy) {
    const newState = JSON.parse(stringifiedPharmacy);
    return GenericParser.parseObject(newState, AWSDKPharmacy);
  }

  /**
   * This method is used to parse and instantiate an AWSDKPracticeFollowUpItem from the provided
   * @param {String} stringifiedPracticeFollowUpItem
   * @returns {model.AWSDKPharmacyFollowUpItem} the restored Practice Follow Up Item
   * @since 3.5.0
   */
  static restorePracticeFollowUpItem(stringifiedPracticeFollowUpItem) {
    const newState = JSON.parse(stringifiedPracticeFollowUpItem);
    return GenericParser.parseObject(newState, AWSDKPracticeFollowUpItem);
  }
}


export default AWSDKFactory;
