/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

export default {
  sdkVersion: 'W3.5.0',
  supportedOnlineCareVersion: 'O6.1.0-',
};
