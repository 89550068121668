/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import Service from './service';
import AWSDKError from '../error/awsdk_error';
import Validator from '../internal/validator/validator';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKInboxResponse from '../internal/model/response/awsdk_inbox_response';
import AWSDKDetailedMessageResponse from '../internal/model/response/awsdk_detailed_message_response';
import AWSDKSecureMessage from '../model/secure_message/awsdk_secure_message';
import AWSDKSentMessagesResponse from '../internal/model/response/awsdk_sent_messages_response';
import AWSDKMessageDraft from '../model/secure_message/compose/awsdk_message_draft';
import AWSDKNewMessageDraft from '../model/secure_message/compose/awsdk_new_message_draft';
import AWSDKReplyMessageDraft from '../model/secure_message/compose/awsdk_reply_message_draft';
import AWSDKForwardMessageDraft from '../model/secure_message/compose/awsdk_forward_message_draft';
import AWSDKResponse from '../internal/model/response/awsdk_response';
import AWSDKSecureMessageContact from '../model/secure_message/awsdk_secure_message_contact';
import AWSDKDetailedMessage from '../model/secure_message/awsdk_detailed_message';
import AWSDKSecureMessageContactsResponse from '../internal/model/response/awsdk_secure_message_contacts_response';
import AWSDKUploadAttachment from '../model/secure_message/compose/awsdk_upload_attachment';
import AWSDKTopicType from '../model/awsdk_topic_type';
import AWSDKUserType from '../awsdk_user_type';
import AWSDKMessageType from '../model/secure_message/awsdk_message_type';
import AWSDKInboxMessage from '../model/secure_message/awsdk_inbox_message';
import AWSDKAttachment from '../model/secure_message/awsdk_attachment';
import AWSDKInbox from '../model/secure_message/awsdk_inbox';
import AWSDKSentMessages from '../model/secure_message/awsdk_sent_messages';
import AWSDKMessageActionType from '../model/secure_message/compose/awsdk_message_action_type';
import getMimeType from '../internal/util/mimeTypes';

/**
 * This service handles everything related to secure messages and supporting infrastructure
 *
 * @since 1.1.0
 * @hideconstructor
 * @extends service.Service
 */
class SecureMessageService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
  }

  /**
   * Get an instance of {@link model.AWSDKUploadAttachment|AWSDKUploadAttachment} to use when sending an attachment with a secure message. <br>
   * @returns {model.AWSDKUploadAttachment} returns an instance of a {@link model.AWSDKUploadAttachment|AWSDKUploadAttachment} object
   * @since 1.1.0
   */
  newUploadAttachment() {
    return new AWSDKUploadAttachment();
  }

  /**
   * Get an instance of {@link model.AWSDKNewMessageDraft|AWSDKNewMessageDraft} to use when sending a new secure message. <br>
   * @returns {model.AWSDKNewMessageDraft} returns an instance of a {@link model.AWSDKNewMessageDraft|AWSDKNewMessageDraft} object
   * @since 1.1.0
   */
  getNewMessageDraft() {
    return new AWSDKNewMessageDraft();
  }

  /**
   * Get an instance of {@link model.AWSDKReplyMessageDraft|AWSDKReplyMessageDraft} to use when sending a reply secure message. <br>
   * @param {model.AWSDKDetailedMessage} detailedMessage the {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} the secure message is a reply to
   * @returns {model.AWSDKReplyMessageDraft} returns an instance of a {@link model.AWSDKReplyMessageDraft|AWSDKReplyMessageDraft} object
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getReplyMessageDraft(detailedMessage) {
    if (!(detailedMessage instanceof AWSDKDetailedMessage)) {
      const error = AWSDKError.AWSDKIllegalArgument('detailedMessage argument is null or not of type AWSDKDetailedMessage');
      this.__logger.error('getReplyMessageDraft', 'Error', error);
      throw error;
    }
    return new AWSDKReplyMessageDraft(detailedMessage);
  }

  /**
   * Get an instance of {@link model.AWSDKForwardMessageDraft|AWSDKForwardMessageDraft} to use when sending a forward secure message. <br>
   * @param {model.AWSDKSecureMessageContact} secureMessageContact the {@link model.AWSDKSecureMessageContact|AWSDKSecureMessageContact} to send the message to
   * @param {model.AWSDKDetailedMessage} detailedMessage the {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} the secure message is forwarding
   * @returns {model.AWSDKForwardMessageDraft} returns an instance of a {@link model.AWSDKForwardMessageDraft|AWSDKForwardMessageDraft} object
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getForwardMessageDraft(detailedMessage) {
    if (!(detailedMessage instanceof AWSDKDetailedMessage)) {
      const error = AWSDKError.AWSDKIllegalArgument('detailedMessage argument is null or not of type AWSDKDetailedMessage');
      this.__logger.error('getForwardMessageDraft', 'Error', error);
      throw error;
    }
    return new AWSDKForwardMessageDraft(detailedMessage);
  }

  /**
   * First-time retrieval of secure inbox messages<br>
   * This can be used in a "batch" mode by providing a value for startIndex and maxResults<br>
   * The optional SINCE parameter will ALSO include any messages that have changed after the
   * given timestamp. These are in addition to the batch messages.<br>
   * This method will retrieve all messages that are within the startIndex and maxResults
   * parameters and all messages that have changed after the specified "since" time. The since
   * parameter is not applied to the messages that are within the startIndex and maxResults
   * parameters.<br>
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve messages for
   * @param {Number} [startIndex] the index of first message to retrieve
   * @param {Number} [maxResults] the number of messages to retrieve, must be an int greater than 0 or 'null' for all results
   * @param {Date} [since] the timestamp for changed messages
   * @returns {Promise<model.AWSDKInbox|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKInbox|AWSDKInbox} or is rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided maxResults was not a number greater than 0.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getInboxMessages(consumer, startIndex = null, maxResults = null, since = null) {
    const currentFunction = 'SecureMessageService.getInboxMessages';
    this.__logger.debug(currentFunction, 'Started', consumer, startIndex, maxResults, since);

    const potentialError = this.__validateGetMessagesRequest(consumer, startIndex, maxResults, since);
    if (potentialError !== null) {
      return Promise.reject(potentialError);
    }

    const link = this.findNamedLink(consumer.links, 'inbox');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid inbox link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (startIndex !== null) options.form.set('startIndex', startIndex);
    if (since !== null) options.form.set('since', since.getMilliseconds());
    if (maxResults !== null) {
      options.form.set('maxResults', maxResults);
    } else {
      options.form.set('maxResults', -1);
    }

    return this.executeRequest(options, AWSDKInboxResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.inbox;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * First-time retrieval of sent secure messages<br>
   * This can be used in a "batch" mode by providing a value for startIndex and maxResults<br>
   * The optional SINCE parameter will ALSO include any messages that have changed after the
   * given timestamp. These are in addition to the batch messages.<br>
   * This method will retrieve all messages that are within the startIndex and maxResults
   * parameters and all messages that have changed after the specified "since" time. The since
   * parameter is not applied to the messages that are within the startIndex and maxResults
   * parameters.<br>
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve messages for
   * @param {Number} [startIndex] the index of first message to retrieve
   * @param {Number} [maxResults] the number of messages to retrieve, must be an int greater than 0 or 'null' for all results
   * @param {Date} [since] the timestamp for changed messages
   * @returns {Promise<model.AWSDKSentMessages|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKSentMessages|AWSDKSentMessages} or is rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided maxResults was not a number greater than 0.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getSentMessages(consumer, startIndex = null, maxResults = null, since = null) {
    const currentFunction = 'SecureMessageService.getSentMessages';
    this.__logger.debug(currentFunction, 'Started', consumer, startIndex, maxResults, since);

    const potentialError = this.__validateGetMessagesRequest(consumer, startIndex, maxResults, since);
    if (potentialError !== null) {
      return Promise.reject(potentialError);
    }

    const link = this.findNamedLink(consumer.links, 'sentMessages');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid sentMessages link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (startIndex !== null) options.form.set('startIndex', startIndex);
    if (since !== null) options.form.set('since', since.getMilliseconds());
    if (maxResults !== null) {
      options.form.set('maxResults', maxResults);
    } else {
      options.form.set('maxResults', -1);
    }

    return this.executeRequest(options, AWSDKSentMessagesResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.sentMessages;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * For internal Use Only!!
   * @private
   */
  __validateGetMessagesRequest(consumer, startIndex, maxResults, since) {
    const currentFunction = 'SecureMessageService.__validateGetMessagesRequest';
    this.__logger.trace(currentFunction, 'Started', consumer, startIndex, maxResults, since);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    if (startIndex != null && !Validator.isInt(startIndex)) {
      const error = AWSDKError.AWSDKIllegalArgument('startIndex is not an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    if (maxResults != null && !Validator.isInt(maxResults)) {
      const error = AWSDKError.AWSDKIllegalArgument('maxResults is not an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    if (maxResults != null && (maxResults < -1 || maxResults === 0)) {
      const error = AWSDKError.AWSDKValidationError('maxResults is not an integer greater than zero');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    if (since != null && !(since instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('since is not of type Date');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }

    this.__logger.trace(currentFunction, 'Finished', consumer, startIndex, maxResults, since);

    return null;
  }

  /**
   * Get the full detail of a given secure message
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve the message for
   * @param {model.AWSDKSecureMessage} secureMessage the message to retrieve more detail for
   * @returns {Promise<model.AWSDKInbox|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} or is rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getMessageDetail(consumer, secureMessage) {
    const currentFunction = 'SecureMessageService.getMessageDetail';
    this.__logger.debug(currentFunction, 'Started', consumer, secureMessage);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(secureMessage instanceof AWSDKSecureMessage)) {
      const error = AWSDKError.AWSDKIllegalArgument('secureMessage argument is null or not of type AWSDKSecureMessage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', secureMessage.href);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKDetailedMessageResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.message;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get the secure message contacts for a consumer
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve contacts for
   * @returns {Promise<model.AWSDKSecureMessageContact[]|error.AWSDKError>} a Promise that resolves to an array of {@link model.AWSDKSecureMessageContact|AWSDKSecureMessageContact} or is rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getContacts(consumer) {
    const currentFunction = 'SecureMessageService.getContacts';
    this.__logger.debug(currentFunction, 'Started', consumer);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'messageContacts');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid messageContacts link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKSecureMessageContactsResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.contacts;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Send a new, reply, or forward secure message.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} sending the message
   * @param {model.AWSDKMessageDraft} messageDraft the {@link model.AWSDKMessageDraft|AWSDKMessageDraft} to send
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.attachmentSizeTooLarge|AWSDKErrorCode.attachmentSizeTooLarge}</td><td>The provided UploadAttachment exceeds the max kb limit.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerRecipientError|AWSDKErrorCode.consumerRecipientError}</td><td>The recipient of a secure message sent by a consumer cannot be a consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.doesNotAcceptSecureMessages|AWSDKErrorCode.doesNotAcceptSecureMessages}</td><td>The provided secure message contact does not accept secure messages.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedMimeType|AWSDKErrorCode.unsupportedMimeType}</td><td>The provided UploadAttachment is not of a supported mime type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  async sendMessage(consumer, messageDraft) {
    const currentFunction = 'SecureMessageService.sendMessage';
    this.__logger.debug(currentFunction, 'Started', consumer, messageDraft);

    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(messageDraft instanceof AWSDKMessageDraft)) {
      const error = AWSDKError.AWSDKIllegalArgument('messageDraft argument is null or not of type AWSDKMessageDraft');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'compose');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid compose link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!messageDraft.sourceIsSystemNotification && !(messageDraft.topicType instanceof AWSDKTopicType)) {
      const error = AWSDKError.AWSDKValidationError('topicType');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (messageDraft.actionType === AWSDKMessageActionType.Forward || messageDraft.actionType === AWSDKMessageActionType.New) {
      if (!(messageDraft.recipient instanceof AWSDKSecureMessageContact)) {
        const error = AWSDKError.AWSDKValidationError('recipient');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      if (messageDraft.recipient.userType === AWSDKUserType.CONSUMER) {
        const error = AWSDKError.AWSDKConsumerRecipientError();
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      if (!messageDraft.recipient.acceptsSecureMessage) {
        const error = AWSDKError.AWSDKDoesNotAcceptSecureMessages();
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const formData = new FormData();
    const uploadAttachment = messageDraft.uploadAttachment;

    let actionType = 'New';
    if (messageDraft.actionType === AWSDKMessageActionType.Reply) actionType = 'Reply';
    if (messageDraft.actionType === AWSDKMessageActionType.Forward) actionType = 'Forward';

    if (uploadAttachment != null) {
      const potentialError = await this.__validateUploadAttachment(uploadAttachment);
      if (potentialError != null) {
        return Promise.reject(potentialError);
      }
      options.headers.Accept = `${options.headers.Accept}, ${uploadAttachment.data.type}`;
      formData.append('attachment', uploadAttachment.data, uploadAttachment.name);
      formData.append('contentType', uploadAttachment.data.type);
      formData.append('filename', uploadAttachment.name);
    }
    formData.append('from', consumer.id.encryptedId);
    formData.append('toList', messageDraft.recipientEncryptedId);
    formData.append('actionType', actionType);
    formData.append('attachHealthSummary', messageDraft.attachHealthSummary);
    formData.append('body', messageDraft.body);

    if (messageDraft.topicType) formData.append('topicType', messageDraft.topicType.key);
    if (messageDraft.sourceMessageType) formData.append('sourceMessageType', messageDraft.sourceMessageType === AWSDKMessageType.Sent ? 'Sent' : 'Inbox');
    if (messageDraft.sourceMessageId) formData.append('sourceMessageId', messageDraft.sourceMessageId);
    if (messageDraft.subject) formData.append('subject', messageDraft.subject);

    options.body = formData;
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.info(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * For internal Use Only!!
   * @private
   */
  async __validateUploadAttachment(uploadAttachment) {
    const currentFunction = 'SecureMessageService.__validateUploadAttachment';
    this.__logger.trace(currentFunction, 'Started', uploadAttachment);

    if (!(uploadAttachment.data instanceof Blob) && !(uploadAttachment.data instanceof File)) {
      const error = AWSDKError.AWSDKIllegalArgument('uploadAttachment.data must be a Blob or File');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    const size = uploadAttachment.data.size;
    const maxSizeInKb = this.__systemConfiguration.secureMessageAttachmentMaxSizeKB;
    if (maxSizeInKb < (size / 1024)) {
      const error = AWSDKError.AWSDKAttachmentSizeTooLarge('data', maxSizeInKb);
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    if (!Validator.isValidString(uploadAttachment.name)) {
      const error = AWSDKError.AWSDKIllegalArgument('uploadAttachment.name is not an instance of String');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    const type = await getMimeType(uploadAttachment.data);
    if (!this.__systemConfiguration.mimeTypeWhitelist.includes(type)) {
      const error = AWSDKError.AWSDKUnsupportedMimeType('uploadAttachment.data');
      this.__logger.error(currentFunction, 'Error', error);
      return error;
    }
    this.__logger.trace(currentFunction, 'Finished', uploadAttachment);

    return null;
  }

  /**
   * Remove a secure message
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} removing the message
   * @param {model.AWSDKSecureMessage} secureMessage the {@link model.AWSDKSecureMessage|AWSDKSecureMessage} to remove
   * @returns {Promise<model.AWSDKDetailedMessage|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.3
   */
  removeMessage(consumer, secureMessage) {
    const currentFunction = 'SecureMessageService.removeMessage';
    this.__logger.debug(currentFunction, 'Started', consumer, secureMessage);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(secureMessage instanceof AWSDKSecureMessage)) {
      const error = AWSDKError.AWSDKIllegalArgument('secureMessage argument is null or not of type AWSDKSecureMessage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('DELETE', secureMessage.href);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKDetailedMessageResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return response.message;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Update a {@link model.AWSDKInboxMessage|AWSDKInboxMessage} or {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} with {@link model.AWSDKMessageType|AWSDKMessageType} "Inbox" from unread to read.
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} who read the message
   * @param {model.AWSDKInboxMessage|model.AWSDKDetailedMessage} inboxOrDetailedMessage the {@link model.AWSDKInboxMessage|AWSDKInboxMessage} or {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} to update
   * @returns {Promise<model.AWSDKDetailedMessage|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKDetailedMessage|AWSDKDetailedMessage} indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.3
   */
  updateMessageRead(consumer, inboxOrDetailedMessage) {
    const currentFunction = 'SecureMessageService.updateMessageRead';
    this.__logger.debug(currentFunction, 'Started', consumer, inboxOrDetailedMessage);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(inboxOrDetailedMessage instanceof AWSDKInboxMessage)
      && !(inboxOrDetailedMessage instanceof AWSDKDetailedMessage
        && inboxOrDetailedMessage.messageType === AWSDKMessageType.Inbox)) {
      const error = AWSDKError.AWSDKIllegalArgument('inboxOrDetailedMessage argument is null or not of type AWSDKInboxMessage or AWSDKDetailedMessage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', inboxOrDetailedMessage.href);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKDetailedMessageResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return response.message;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieve the blob for a given {@link model.AWSDKAttachment|AWSDKAttachment}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to get the attachment for
   * @param {model.AWSDKAttachment} attachment the {@link model.AWSDKAttachment|AWSDKAttachment} to get
   * @returns {Promise<Blob|error.AWSDKError>} a promise that will resolve to a Blob of data or rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedMimeType|AWSDKErrorCode.unsupportedMimeType}</td><td>The attachment data's mime type is not supported.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.3
   */
  getAttachment(consumer, attachment) {
    const currentFunction = 'SecureMessageService.getAttachment';
    this.__logger.debug(currentFunction, 'Started', consumer, attachment);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(attachment instanceof AWSDKAttachment)) {
      const error = AWSDKError.AWSDKIllegalArgument('attachment argument is null or not of type AWSDKAttachment');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(attachment.links, 'getAttachment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('attachment does not have a valid getAttachment link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const mimeType = attachment.type;
    if (!Validator.isValidString(mimeType)) {
      const error = AWSDKError.AWSDKUnsupportedMimeType('attachment.type');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    const currAccHeader = options.headers.Accept;
    options.headers.Accept = `${currAccHeader}, ${mimeType}`;
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options)
      .then((blob) => {
        this.__logger.debug(currentFunction, 'Completed');
        return new Blob([blob], { type: mimeType });
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Refresh the given {@link model.AWSDKInbox|AWSDKInbox} with any changes that have occurred since its timestamp
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to get the refreshed inbox for
   * @param {model.AWSDKInbox} inbox the {@link model.AWSDKInbox|AWSDKInbox} to refresh
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.3
   */
  refreshInbox(consumer, inbox) {
    const currentFunction = 'SecureMessageService.refreshInbox';
    this.__logger.debug(currentFunction, 'Started', consumer, inbox);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(inbox instanceof AWSDKInbox)) {
      const error = AWSDKError.AWSDKIllegalArgument('inbox argument is null or not of type AWSDKInbox');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(inbox.links, 'changes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('inbox does not have a valid changes link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    options.form.set('since', inbox.timestamp.getMilliseconds());

    return this.executeRequest(options, AWSDKInboxResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        inbox.__assimilate(response.inbox);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Refresh the given {@link model.AWSDKSentMessages|AWSDKSentMessages} with any changes that have occurred since its timestamp
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to get the refreshed sentMessages for
   * @param {model.AWSDKSentMessages} sentMessages the {@link model.AWSDKSentMessages|AWSDKSentMessages} to refresh
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.3
   */
  refreshSentMessages(consumer, sentMessages) {
    const currentFunction = 'SecureMessageService.refreshSentMessages';
    this.__logger.debug(currentFunction, 'Started', consumer, sentMessages);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null or not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(sentMessages instanceof AWSDKSentMessages)) {
      const error = AWSDKError.AWSDKIllegalArgument('sentMessages argument is null or not of type AWSDKSentMessages');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(sentMessages.links, 'changes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('sentMessages does not have a valid changes link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    options.form.set('since', sentMessages.timestamp.getMilliseconds());

    return this.executeRequest(options, AWSDKSentMessagesResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        sentMessages.__assimilate(response.sentMessages);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}

export default SecureMessageService;
