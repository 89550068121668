/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import deprecated from 'awcoresdk/lib/util/deprecated';
import AWSDKAvailabilityList from '../model/practice/awsdk_availability_list';
import AWSDKError from './../error/awsdk_error';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKLanguage from '../model/awsdk_language';
import AWSDKPractice from '../model/practice/awsdk_practice';
import AWSDKPracticeSubCategory from '../model/practice/awsdk_practice_sub_category';
import AWSDKProvider from '../model/provider/awsdk_provider';
import AWSDKProviderDetails from '../model/provider/awsdk_provider_details';
import AWSDKProviderDetailsResponse from '../internal/model/response/awsdk_provider_details_response';
import AWSDKProvidersAvailabilityResponse from '../internal/model/response/awsdk_providers_availability_response';
import AWSDKProvidersResponse from '../internal/model/response/awsdk_providers_response';
import AWSDKPracticeSearchResult from '../model/practice/awsdk_practice_search_result';
import AWSDKOnDemandSpecialty from '../model/practice/awsdk_on_demand_specialty';
import AWSDKState from '../model/awsdk_state';
import Service from './service';
import Validator from '../internal/validator/validator';
import Util from '../internal/util/util';
import AWSDKEstimatedVisitCostResponse from '../internal/model/response/awsdk_estimated_visit_cost_response';
import AWSDKProviderType from '../model/provider/awsdk_provider_type';
import AWSDKProviderFutureAvailabilitySearchCriteria from '../model/provider/awsdk_provider_future_availability_search_criteria';
import AWSDKProviderAvailabilityCriteria from '../model/provider/awsdk_provider_availability_criteria';
import AWSDKPracticeSearchType from '../model/practice/awsdk_practice_search_type';

/**
 * This is the ProviderService class, which contains all necessary methods for getting provider related information.
 * @extends service.Service
 */
class ProviderService extends Service {
  /**
   * Retrieve an array of {@link model.AWSDKProvider|AWSDKProvider} for the given practice, via {@link model.AWSDKPractice|AWSDKPractice}
   * retrieved via {@link service.PracticeService#getPractice|PracticeService.getPractice} <br>
   * Providing a {@link model.AWSDKConsumer|AWSDKConsumer} (OPTIONAL) will ALWAYS filter results to a list of providers visible to that consumer. Omit consumer if that's not what you want.
   * The {@link model.AWSDKConsumer|AWSDKConsumer} is retrieved via {@link service.ConsumerService#getConsumer|ConsumerService.getConsumer} <br>
   *
   * @param {model.AWSDKPractice} practice REQUIRED limits the result to providers in this practice
   * @param {model.AWSDKConsumer} [consumer] limits the results to providers visible to this consumer
   * @returns {Promise<model.AWSDKProvider[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKProvider|AWSDKProvider} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.0.0
   */
  findProvidersInPractice(practice, consumer = null) {
    const currentFunction = 'ProviderService.findProvidersInPractice';
    this.__logger.debug(currentFunction, 'Started', practice, consumer);
    if (!(practice instanceof AWSDKPractice)) {
      const error = AWSDKError.AWSDKIllegalArgument('practice is null or not an instance of AWSDKPractice');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (consumer !== null && !(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(practice.links, 'providersInPracticeSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('practice does not have a "providersInPracticeSearch" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    if (consumer != null) {
      options.auth = this.getUserAuth(consumer);
      if (options.auth == null) {
        const error = AWSDKError.AWSDKConsumerNotAuthenticated();
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
      // for a get request any form parameters will be added as query strings
      options.form.set('memberId', consumer.id.encryptedId);
    } else {
      options.auth = this.getSdkAuth();
    }
    return this.executeRequest(options, AWSDKProvidersResponse)
      .then((providersResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providersResponse);
        if (consumer != null) {
          this.updateUserAuthEntry(consumer, providersResponse.authToken);
        }
        return providersResponse.providers;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Retrieve the {@link model.AWSDKProviderDetails|AWSDKProviderDetails} details for the given provider, via {@link model.AWSDKProvider|AWSDKProvider}
   * retrieved via {@link service.ProviderService#findProvidersInPractice|ProviderService.findProvidersInPractice}  <br>
   *
   * @param {model.AWSDKProvider} provider REQUIRED
   * @param {model.AWSDKConsumer} [consumer] the authenticated consumer, if any.
   * @returns {Promise<model.AWSDKProviderDetails|error.AWSDKError>}
   * Returns a promise that will be resolved to an {@link model.AWSDKProviderDetails|ProviderDetails}
   * or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.0.0
   */
  getProviderDetails(provider, consumer = null) {
    const currentFunction = 'ProviderService.getProviderDetails';
    this.__logger.debug(currentFunction, 'Started', provider, consumer);
    if (!(provider instanceof AWSDKProvider)) {
      const error = AWSDKError.AWSDKIllegalArgument('provider is null or not an instance of AWSDKProvider');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (consumer !== null && !(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const url = provider.href;
    if (!Validator.isValidString(url)) {
      const error = AWSDKError.AWSDKInternalError('provider has an invalid href');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', url);
    if (consumer != null) {
      options.auth = this.getUserAuth(consumer);
      if (options.auth == null) {
        const error = AWSDKError.AWSDKConsumerNotAuthenticated();
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
    } else {
      options.auth = this.getSdkAuth();
    }
    return this.executeRequest(options, AWSDKProviderDetailsResponse)
      .then((providerDetailsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providerDetailsResponse);
        if (consumer != null) {
          this.updateUserAuthEntry(consumer, providerDetailsResponse.authToken);
        }
        return providerDetailsResponse.providerDetails;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKProviderFutureAvailabilitySearchCriteria|AWSDKProviderFutureAvailabilitySearchCriteria} to use when searching a provider's future availability. <br>
   * @param {Object} [searchCriteria] the search criteria in JSON Format
   * @returns {model.AWSDKProviderFutureAvailabilitySearchCriteria} returns an instance of a {@link model.AWSDKProviderFutureAvailabilitySearchCriteria|AWSDKProviderFutureAvailabilitySearchCriteria} object
   * @since 1.3.1
   */
  getNewProviderFutureAvailabilitySearchCriteria(searchCriteria) {
    return new AWSDKProviderFutureAvailabilitySearchCriteria(searchCriteria);
  }

  /**
   * Retrieve an array of {@link model.AWSDKProvider|AWSDKProvider}  for the given {@link model.AWSDKPractice|AWSDKPractice} for scheduling appointments in the future<br>
   * There is an optional {@link java.util.Date} parameter for appointmentDate. Providing it will limit the search
   * to the given date.  If this date is not provided, the next date with available appointments will be used<br>
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose list of practices we seek to retrieve
   * @param {model.AWSDKProviderFutureAvailabilitySearchCriteria} AWSDKProviderFutureAvailabilitySearchCriteria the {@link model.AWSDKProviderFutureAvailabilitySearchCriteria|AWSDKProviderFutureAvailabilitySearchCriteria} to use when searching for a provider's future availability.
   * @returns {Promise<model.AWSDKProvidersAvailability|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKProvidersAvailability|AWSDKProvidersAvailability} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  providerFutureAvailabilitySearch(consumer, providerFutureAvailabilitySearchCriteria) {
    const currentFunction = 'ProviderService.providerFutureAvailabilitySearch';
    this.__logger.debug(currentFunction, 'Started', consumer, providerFutureAvailabilitySearchCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(providerFutureAvailabilitySearchCriteria instanceof AWSDKProviderFutureAvailabilitySearchCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria" must be an instance of AWSDKProviderFutureAvailabilitySearchCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const criteria = providerFutureAvailabilitySearchCriteria;
    if (!Validator.isValidString(criteria.timeZone)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.timeZone" must be a valid String');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.practiceOrSubCategory != null && !(criteria.practiceOrSubCategory instanceof AWSDKPractice) && !(criteria.practiceOrSubCategory instanceof AWSDKPracticeSubCategory)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.practiceOrSubCategory" must be an instance of AWSDKPractice or AWSDKPracticeSubCategory');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.languageSpoken != null && !(criteria.languageSpoken instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.languageSpoken" must be an instance of AWSDKLanguage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.appointmentDate != null && !(criteria.appointmentDate instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.appointmentDate" must be an instance of Date');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.limitInDays != null && !(Validator.isInt(criteria.limitInDays))) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.limitInDays" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.maxResults != null && !(Validator.isInt(criteria.maxResults))) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerFutureAvailabilitySearchCriteria.maxResults" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'providerFutureAvailabilitySearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "providerFutureAvailabilitySearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumer.id.encryptedId);
    if (criteria.practiceOrSubCategory != null) {
      if (criteria.practiceOrSubCategory instanceof AWSDKPractice) {
        options.form.set('practiceId', criteria.practiceOrSubCategory.id.encryptedId);
      } else {
        options.form.set('practiceSubCategoryId', criteria.practiceOrSubCategory.id.encryptedId);
      }
    }
    if (criteria.searchTerm != null) {
      options.form.set('searchTerm', criteria.searchTerm);
    }
    if (criteria.languageSpoken != null) {
      options.form.set('languageSpoken', criteria.languageSpoken.value);
    }
    if (criteria.appointmentDate != null) {
      options.form.set('appointmentDate', Util.formatISODateTime(criteria.appointmentDate));
    }
    options.form.set('currentTime', Util.formatISODateTime(new Date()));
    options.form.set('timeZone', criteria.timeZone);
    if (criteria.limitInDays != null) {
      options.form.set('limitInDays', criteria.limitInDays);
    }
    if (criteria.maxResults != null) {
      options.form.set('maxResults', criteria.maxResults);
    }
    return this.executeRequest(options, AWSDKProvidersAvailabilityResponse)
      .then((providersAvailablilityResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providersAvailablilityResponse);
        this.updateUserAuthEntry(consumer, providersAvailablilityResponse.authToken);
        return providersAvailablilityResponse.providers;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKProviderAvailabilityCriteria|AWSDKProviderAvailabilityCriteria} to use when searching a provider's availability. <br>
   * @param {Object} [criteria] the search criteria in JSON Format
   * @returns {model.AWSDKProviderAvailabilityCriteria} returns an instance of a {@link model.AWSDKProviderAvailabilityCriteria|AWSDKProviderFutureAvailabilitySearchCriteria} object
   * @since 1.3.1
   */
  getNewProviderAvailabilityCriteria(criteria) {
    return new AWSDKProviderAvailabilityCriteria(criteria);
  }

  /**
   * Retrieve a List of Dates for the given {@link mode.AWSDKProviderDetails} for scheduling appointments in
   * the future<br>
   * There is an optional {@link java.util.Date} field on the {@link model.AWSDKProviderAvailabilityCriteria|AWSDKProviderAvailabilityCriteria} for appointmentDate. Providing it will limit the search
   * to the given date.  If this date is not provided, the server will automatically find and return the next date
   * with available appointments<br>
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to use
   * @param {model.AWSDKProviderAvailabilityCriteria} providerAvailabilityCriteria the {@link model.AWSDKProviderAvailabilityCriteria|AWSDKProviderAvailabilityCriteria} to use
   * @returns {Promise<model.AWSDKAvailabilityList|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKAvailabilityList|AWSDKAvailabilityList} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  getProviderAvailability(consumer, providerAvailabilityCriteria) {
    const currentFunction = 'ProviderService.getProviderAvailability';
    this.__logger.debug(currentFunction, 'Started', consumer, providerAvailabilityCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(providerAvailabilityCriteria instanceof AWSDKProviderAvailabilityCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerAvailabilityCriteria" must be an instance of AWSDKProviderAvailabilityCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const criteria = providerAvailabilityCriteria;
    if (!(criteria.providerDetails instanceof AWSDKProviderDetails)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerAvailabilityCriteria.providerDetails" must be an instance of AWSDKProviderDetails');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(criteria.timeZone)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerAvailabilityCriteria.timeZone" must be a valid String');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (criteria.appointmentDate != null && !(criteria.appointmentDate instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerAvailabilityCriteria.appointmentDate" must be an instance of Date');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.limitInDays != null && !(Validator.isInt(criteria.limitInDays))) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "providerAvailabilityCriteria.limitInDays" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(criteria.providerDetails.links, 'availability');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('providerDetails does not have a valid "availability" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.appointmentDate != null) {
      options.form.set('appointmentDate', Util.formatISODateTime(criteria.appointmentDate));
    }
    options.form.set('currentTime', Util.formatISODateTime(new Date()));
    options.form.set('timeZone', criteria.timeZone);
    if (criteria.limitInDays != null) {
      options.form.set('limitInDays', criteria.limitInDays);
    }
    return this.executeRequest(options, AWSDKAvailabilityList)
      .then((availablilityList) => {
        this.__logger.debug(currentFunction, 'Got response', availablilityList);
        this.updateUserAuthEntry(consumer, availablilityList.authToken);
        return availablilityList;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Retrieve an array of past {@link model.AWSDKProvider|AWSDKProvider}s for the given consumer.<br>
   *
   * @param {model.AWSDKConsumer} consumer the consumer to find past providers for
   * @param {object} [options] an object of optional search parameters
   * @param {Number} [options.maxResults] limits the max number of providers returned by the search
   * @param {model.AWSDKPractice} [options.practice] when provided narrows the search to only providers within the practice
   * @param {model.AWSDKPracticeSearchType} [options.practiceSearchType] when provided narrows the search to only providers in practices of the specified type
   * @returns {Promise<model.AWSDKProvider[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKProvider|AWSDKProvider} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 3.0.0
   */
  searchPastProviders(consumer, options = {}) {
    const currentFunction = 'ProviderService.searchPastProviders';
    this.__logger.debug(currentFunction, 'Started', consumer, options);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (options.maxResults && (!Validator.isInt(options.maxResults) || options.maxResults < 0)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.maxResults is not a positive integer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (options.practice && !(options.practice instanceof AWSDKPractice)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.practice is not an instance of AWSDKPractice');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (options.practiceSearchType && !(options.practiceSearchType instanceof AWSDKPracticeSearchType)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.practiceSearchType is not an instance of AWSDKPracticeSearchType');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'pastProviders');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "pastProviders" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const requestOptions = this.generateOptions('GET', link.url);
    requestOptions.auth = this.getUserAuth(consumer);
    if (requestOptions.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (options.maxResults) requestOptions.form.set('limit', options.maxResults);
    if (options.practice) requestOptions.form.set('practiceId', options.practice.id.encryptedId);
    if (options.practiceSearchType) requestOptions.form.set('practiceSearchType', options.practiceSearchType.type);

    return this.executeRequest(requestOptions, AWSDKProvidersResponse)
      .then((providersResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providersResponse);
        this.updateUserAuthEntry(consumer, providersResponse.authToken);
        return providersResponse.providers;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method allows for the retrieval of an array of {@link model.AWSDKProvider|AWSDKProvider} based on an authenticated or unauthenticated search. <br>
   * All the parameters to this method are optional when making an <em>unauthenticated</em> search. The parameters allow to filter the result obtained. Only the consumer <br>
   * parameter is required when making an <em>authenticated</em> request.
   * @param {model.AWSDKConsumer} [consumer] the consumer whose context to use for providers search. Only needed if making authenticated searches.
   * @param {model.AWSDKPractice|model.AWSDKPracticeSearchResult} [practice] the practice for which to get the list of providers from.
   * @param {model.AWSDKOnDemandSpecialty} [onDemandSpecialty] the on-demand specialty practice to filter by.
   * @param {String} [searchTerm] the name or regular expression to use to search for provider by name. Needs to be at least 3 chars long
   * @param {model.AWSDKProviderType[]} [specialties] an array of provider specialties to filter by.
   * @param {String[]} [sourceIds] the array of sourceIds to filter by
   * @param {model.AWSDKState} [licensedInState] the state where the provider is licensed to practice
   * @param {model.AWSDKLanguage} [languageSpoken] a language that the provider speaks
   * @param {Number} [maxResults] the maximum number of results to return from the search. Must be a positive integer
   * @returns {Promise<model.AWSDKProvider[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKProvider|AWSDKProvider} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  findProviders(consumer, practice, onDemandSpecialty, searchTerm, specialties, sourceIds, licensedInState, languageSpoken, maxResults) {
    const currentFunction = 'ProviderService.findProviders';
    this.__logger.debug(currentFunction, 'Started',
      consumer, practice, onDemandSpecialty, searchTerm, specialties, sourceIds, licensedInState, languageSpoken, maxResults);
    if (consumer != null && !(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (practice != null) {
      if (!(practice instanceof AWSDKPractice) && !(practice instanceof AWSDKPracticeSearchResult)) {
        const error = AWSDKError.AWSDKIllegalArgument('practice is not an instance of neither AWSDKPractice nor AWSDKPracticeSearchResult');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    if (onDemandSpecialty != null && !(onDemandSpecialty instanceof AWSDKOnDemandSpecialty)) {
      const error = AWSDKError.AWSDKIllegalArgument('onDemandSpecialty is not an instance of AWSDKOnDemandSpecialty');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (searchTerm != null) {
      if (!(Validator.isValidString(searchTerm))) {
        const error = AWSDKError.AWSDKIllegalArgument('searchTerm must be an instance of String');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      } else if (!Validator.hasValidFormat(searchTerm, 100, 3, /./)) {
        const error = AWSDKError.AWSDKValidationError(searchTerm);
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    if (specialties && (!Array.isArray(specialties) || specialties.find(pt => !(pt instanceof AWSDKProviderType)))) {
      const error = AWSDKError.AWSDKIllegalArgument('specialties optional argument is not an array AWSDKProviderType');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (sourceIds != null) {
      if (!Array.isArray(sourceIds)) {
        const error = AWSDKError.AWSDKIllegalArgument('Argument "sourceIds" must be an Array of Strings');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      const errors = sourceIds.filter(item => !Validator.isValidString(item));
      if (errors.length > 0) {
        const error = AWSDKError.AWSDKValidationError('sourceIds');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    if (licensedInState != null && !(licensedInState instanceof AWSDKState)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "licensedInState" must be of type AWSDKState');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (languageSpoken != null && !(languageSpoken instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "language" must be of type AWSDKLanguage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (maxResults != null && (!Validator.isInt(maxResults) || maxResults <= 0)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "maxResults" must be a positive integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'providerSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('PracticeService does not have a "providerSearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    if (consumer != null) {
      options.auth = this.getUserAuth(consumer);
      if (options.auth == null) {
        const error = AWSDKError.AWSDKConsumerNotAuthenticated();
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    } else {
      options.auth = this.getSdkAuth();
    }
    if (consumer) {
      options.form.set('memberId', consumer.id.encryptedId);
    }
    if (practice) {
      options.form.set('practiceId', practice.id.encryptedId);
    }
    if (onDemandSpecialty) {
      options.form.set('onDemandSpecialtyId', onDemandSpecialty.id.encryptedId);
    }
    if (licensedInState) {
      options.form.set('state', licensedInState.code);
    }
    if (languageSpoken) {
      options.form.set('languageSpoken', languageSpoken.resourceKey);
    }
    if (sourceIds) {
      sourceIds.forEach(entry => options.form.append('sourceIds', entry));
    }
    if (specialties) {
      specialties.forEach(entry => options.form.append('providerTypes', entry.resourceKey));
    }
    options.form.set('maxResults', maxResults);
    options.form.set('searchTerm', searchTerm);
    return this.executeRequest(options, AWSDKProvidersResponse)
      .then((providersResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providersResponse);
        if (consumer != null) {
          this.updateUserAuthEntry(consumer, providersResponse.authToken);
        }
        return providersResponse.providers;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method allows for the retrieval of the estimated cost of a visit, before insurance, with a given Provider.
   * @param {model.AWSDKProviderDetails} providerDetails the Provider whose cost of visit with we wish to obtain.
   * @param {model.AWSDKConsumer} consumer the authenticated {@link model.AWSDKConsumer|AWSDKConsumer} inquiring about the pricing of a visit with this Provider
   * @returns {Promise<model.AWSDKEstimatedVisitCost>|error.AWSDKError} a Promise that will resolve to an instance of {@link model.AWSDKEstimatedVisitCost|AWSDKEstimatedVisitCost}
   * or will be rejected with an instance of {@link error.AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  getEstimatedVisitCost(providerDetails, consumer) {
    const currentFunction = 'ProviderService.getEstimatedVisitCost';
    this.__logger.debug(currentFunction, 'Started', providerDetails, consumer);
    this.__logger.info(currentFunction, 'Started');
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(providerDetails instanceof AWSDKProviderDetails)) {
      const error = AWSDKError.AWSDKIllegalArgument('providerDetails is not an instance of AWSDKProviderDetails');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(providerDetails.links, 'estimatedCost');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('A "estimatedCost" link entry was not found');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumer.id.encryptedId);
    return this.executeRequest(options, AWSDKEstimatedVisitCostResponse)
      .then((estimatedCostResponse) => {
        this.__logger.debug(currentFunction, 'Got response', estimatedCostResponse);
        this.updateUserAuthEntry(consumer, estimatedCostResponse.authToken);
        return estimatedCostResponse.estimatedCost;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}
export default ProviderService;
