/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import 'whatwg-fetch';
import empty from 'awcoresdk/lib/util/empty';
import deprecated from 'awcoresdk/lib/util/deprecated';
import replace from 'awcoresdk/lib/util/replace';
import isWebRTCSupported from 'awcoresdk/lib/util/rtc_supported';
import messages from 'awcoresdk/lib/i18n/index';
import 'awcoresdk/lib/styles/main.scss';

import AWSDK from './awsdk/awsdk';
import version from './awsdk/version';
import AWSDKError from './awsdk/error/awsdk_error';
import AWSDKErrorCode from './awsdk/error/awsdk_error_code';
import AWSDKFactory from './awsdk/awsdk_factory';
import AWSDKConsumerMiddleNameHandling from './awsdk/model/consumer/awsdk_consumer_middle_name_handling';
import AWSDKGender from './awsdk/model/consumer/awsdk_gender';
import AWSDKDisclaimerEntityType from './awsdk/model/awsdk_disclaimer_entity_type';
import AWSDKFirstAvailableStatus from './awsdk/model/visit/awsdk_first_available_status';
import AWSDKEligibilityRequestStatus from './awsdk/model/visit/awsdk_eligibility_request_status';
import AWSDKConnectionType from './awsdk/model/visit/awsdk_connection_type';
import AWSDKConsumerInitiatedIVRStatus from './awsdk/model/visit/awsdk_consumer_initiated_ivr_status';
import AWSDKDisposition from './awsdk/model/visit/awsdk_disposition';
import AWSDKEndReason from './awsdk/model/visit/awsdk_end_reason';
import AWSDKConferenceStatus from './awsdk/model/visit/awsdk_conference_status';
import AWSDKUserType from './awsdk/awsdk_user_type';
import AWSDKMessageType from './awsdk/model/secure_message/awsdk_message_type';
import AWSDKRecoverEmailStatus from './awsdk/model/authentication/awsdk_recover_email_status';
import AWSDKProviderVisibility from './awsdk/model/provider/awsdk_provider_visibility';
import AWSDKCheckInStatus from './awsdk/model/appointment/awsdk_check_in_status';
import AWSDKAgendaItemFollowUpType from './awsdk/model/visit/awsdk_agenda_item_follow_up_type';
import AWSDKMessageActionType from './awsdk/model/secure_message/compose/awsdk_message_action_type';
import AWSDKVisitModalityType from './awsdk/model/visit/awsdk_visit_modality_type';
import AWSDKDevicePairingStatusEnum from './awsdk/model/device_live_streaming/awsdk_device_pairing_status_enum';
import AWSDKDeviceLiveStreamType from './awsdk/model/device_live_streaming/awsdk_device_live_stream_type';
import AWSDKDeviceIntegrationMode from './awsdk/model/device_integration/awsdk_device_integration_mode';
import AWSDKGenderIdentity from './awsdk/model/consumer/awsdk_gender_identity';
import AWSDKPostVisitFollowUpItemsTypeFilter from './awsdk/model/post_visit_follow_up_items/awsdk_post_visit_follow_up_items_type_filter';
import AWSDKPostVisitFollowUpItemType from './awsdk/model/post_visit_follow_up_items/awsdk_post_visit_follow_up_item_type';
import AWSDKPostVisitFollowUpItemCategory from './awsdk/model/post_visit_follow_up_items/awsdk_post_visit_follow_up_item_category';
import AWSDKIntegrationContext from './awsdk/model/integration/awsdk_integration_context';
import AWSDKIntegrationStatus from './awsdk/model/integration/awsdk_integration_status';
import AWSDKTwoFactorRequiredAction from './awsdk/model/authentication/awsdk_two_factor_required_action';
import AWSDKTwoFactorConfiguration from './awsdk/model/authentication/awsdk_two_factor_configuration';

AWSDK.version = version.sdkVersion;
AWSDK.supportedOnlineCareVersion = version.supportedOnlineCareVersion;

export default { AWSDK,
  AWSDKError,
  AWSDKErrorCode,
  AWSDKFactory,
  AWSDKConnectionType,
  AWSDKConsumerMiddleNameHandling,
  AWSDKGender,
  AWSDKGenderIdentity,
  AWSDKDisclaimerEntityType,
  AWSDKFirstAvailableStatus,
  AWSDKEligibilityRequestStatus,
  AWSDKConsumerInitiatedIVRStatus,
  AWSDKDisposition,
  AWSDKEndReason,
  AWSDKConferenceStatus,
  AWSDKUserType,
  AWSDKMessageType,
  AWSDKRecoverEmailStatus,
  AWSDKProviderVisibility,
  AWSDKCheckInStatus,
  AWSDKAgendaItemFollowUpType,
  AWSDKPostVisitFollowUpItemType,
  AWSDKPostVisitFollowUpItemCategory,
  AWSDKPostVisitFollowUpItemsTypeFilter,
  AWSDKMessageActionType,
  AWSDKVisitModalityType,
  AWSDKTwoFactorRequiredAction,
  AWSDKTwoFactorConfiguration,
  AWSDKDevicePairingStatusEnum,
  AWSDKDeviceLiveStreamType,
  AWSDKDeviceIntegrationMode,
  AWSDKIntegrationStatus,
  AWSDKIntegrationContext,
  utils: {
    empty,
    deprecated,
    replace,
    WebRTCHelper: {
      isWebRTCSupported,
    },
  },
  i18n: messages,
};
