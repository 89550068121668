/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from '../awsdk_dataobject';

/**
 * Represents the particular vitals measurements of a {@link model.AWSDKConsumer}
 * @property {Number} systolic the consumer's reported systolic value
 * @property {Number} diastolic the consumer's systolic value
 * @property {Number} temperature the consumer's temperature
 * @property {Number} weight the consumer's weight
 * @property {Number} weightMajor the consumer's weight in its major unit
 * @property {Number} weightMinor the consumer's weight in its minor unit
 * @property {Number} heightMajor the consumer's height in its major unit
 * @property {Number} heightMinor the consumer's height in its minor unit
 * @extends model.AWSDKDataObject
 */
class AWSDKVitals extends AWSDKDataObject {
  get systolic() {
    return this.__data.systolic;
  }

  get diastolic() {
    return this.__data.diastolic;
  }

  get temperature() {
    return this.__data.temperature;
  }

  /**
   * @deprecated please use weightMinor and weightMajor instead.
   */
  get weight() {
    return this.__data.weight;
  }

  /**
    * @since 3.4.0
    */
  get weightMajor() {
    return this.__data.weightMajor;
  }

  /**
    * @since 3.4.0
    */
  get weightMinor() {
    return this.__data.weightMinor;
  }

  /**
    * @since 3.4.0
    */
  get heightMajor() {
    return this.__data.heightMajor;
  }

  /**
    * @since 3.4.0
    */
  get heightMinor() {
    return this.__data.heightMinor;
  }

  set systolic(systolic) {
    this.__data.systolic = systolic;
  }

  set diastolic(diastolic) {
    this.__data.diastolic = diastolic;
  }

  set temperature(temperature) {
    this.__data.temperature = temperature;
  }

  set weight(weight) {
    this.__data.weight = weight;
  }

  set weightMajor(weightMajor) {
    this.__data.weightMajor = weightMajor;
  }

  set weightMinor(weightMinor) {
    this.__data.weightMinor = weightMinor;
  }

  set heightMajor(heightMajor) {
    this.__data.heightMajor = heightMajor;
  }

  set heightMinor(heightMinor) {
    this.__data.heightMinor = heightMinor;
  }

}

export default AWSDKVitals;
