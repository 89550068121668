/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKAvailabilityList from '../model/practice/awsdk_availability_list';
import AWSDKError from './../error/awsdk_error';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKLanguage from '../model/awsdk_language';
import AWSDKPractice from '../model/practice/awsdk_practice';
import AWSDKPracticeList from '../model/practice/awsdk_practice_list';
import AWSDKPracticeBase from '../internal/model/awsdk_practice_base';
import AWSDKPracticeResponse from '../internal/model/response/awsdk_practice_response';
import AWSDKPracticeSearchCriteria from '../model/practice/awsdk_practice_search_criteria';
import AWSDKPracticeSearchResponse from '../internal/model/response/awsdk_practice_search_response';
import AWSDKPracticeCategory from '../model/practice/awsdk_practice_category';
import AWSDKPracticeSubCategory from '../model/practice/awsdk_practice_sub_category';
import AWSDKOnDemandSpecialtiesResponse from '../internal/model/response/awsdk_on_demand_specialties_response';
import Service from './service';
import Validator from '../internal/validator/validator';
import Util from '../internal/util/util';
import AWSDKPracticeFutureAvailabilitySearchCriteria from '../model/practice/awsdk_practice_future_availability_search_criteria';

/**
 * This PracticeService class contains all necessary service methods for getting practice related information from the American Well telehealth platform.<br>
 * An instance of this class is obtained via a call to {@link awsdk.AWSDK#practiceService|AWSDK.practiceService}
 * @extends service.Service
 */
class PracticeService extends Service {
  /**
   * Retrieve an array of {@link model.AWSDKPractice|AWSDKPractice} for the given {@link model.AWSDKConsumer|AWSDKConsumer}, which is retrieved via a call {@link service.ConsumerService#getConsumer|ConsumerService.getConsumer} <br>
   * The resulting response will also have an array of {@link model.AWSDKPracticeCategory|AWSDKPracticeCategory}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose list of practices we seek to retrieve
   * @returns {Promise<model.AWSDKPracticeList|error.AWSDKError>} Returns a promise that will be resolved to a {@link model.AWSDKPracticeList|AWSDKPracticeList} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>When the consumer is not an instance of {@link model.AWSDKConsumer|AWSDKConsumer}.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.0.0
   */
  getPractices(consumer) {
    const currentFunction = 'PracticeService.getPractices';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'practicesWithCategories');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "practicesWithCategories" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKPracticeList)
      .then((practiceList) => {
        this.__logger.debug(currentFunction, 'Got response', practiceList);
        this.updateUserAuthEntry(consumer, practiceList.authToken);
        return practiceList;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieve the practice details for the given {@link model.AWSDKPractice|AWSDKPractice}, which is retrieved via a call to {@link service.PracticeService#getPractices|PracticeService.getPractices} <br>
   * @param {model.AWSDKPractice|model.AWSDKPracticeSearchResult|model.AWSDKPracticeInfo} practice the {@link model.AWSDKPractice|AWSDKPractice} or {@link model.AWSDKPracticeSearchResult|AWSDKPracticeSearchResult} whose details we want to retrieve.
   * @returns {Promise<model.AWSDKPractice|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKPractice|AWSDKPractice} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>The practice argument is not an instance of {@link model.AWSDKPractice|AWSDKPractice}</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.0.0
   */
  getPracticeDetails(practice) {
    const currentFunction = 'PracticeService.getPracticeDetails';
    this.__logger.debug(currentFunction, 'Started', practice);
    if ((!(practice instanceof AWSDKPracticeBase))) {
      const error = AWSDKError.AWSDKIllegalArgument('practice is not an instance of AWSDKPracticeSearchResult, AWSDKPractice, or AWSDKPracticeInfo');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const url = practice.href;
    if (!Validator.isValidString(url)) {
      const error = AWSDKError.AWSDKInternalError('The practice argument does not contain a valid href property');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKPracticeResponse)
      .then((practiceResponse) => {
        this.__logger.debug(currentFunction, 'Got response', practiceResponse);
        return practiceResponse.practiceDetails;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKPracticeFutureAvailabilitySearchCriteria|AWSDKPracticeFutureAvailabilitySearchCriteria} to use when searching for a practice's future availability. <br>
   * @param {Object} [searchCriteria] the search criteria in JSON Format
   * @returns {model.AWSDKPracticeFutureAvailabilitySearchCriteria} returns an instance of a {@link model.AWSDKPracticeFutureAvailabilitySearchCriteria|AWSDKPracticeFutureAvailabilitySearchCriteria} object
   * @since 1.3.1
   */
  getNewPracticeFutureAvailabilitySearchCriteria(searchCriteria) {
    return new AWSDKPracticeFutureAvailabilitySearchCriteria(searchCriteria);
  }

  /**
   * Retrieve a List of Dates for the given {@link model.AWSDKPractice|AWSDKPractice} for scheduling appointments in the future<br>
   * There is an optional {@link java.util.Date} parameter for appointmentDate. Providing it will limit the search
   * to the given date.  If this date is not provided, the next date with available appointments will be used<br>
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} or whose list of practices we seek to retrieve
   * @param {model.AWSDKPracticeFutureAvailabilitySearchCriteria} practiceFutureAvailabilitySearchCriteria the {@link model.AWSDKPracticeFutureAvailabilitySearchCriteria|AWSDKPracticeFutureAvailabilitySearchCriteria} to use when searching for a practice's future availability.
   * @returns {Promise<model.AWSDKAvailabilityList|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKAvailabilityList|AWSDKAvailabilityList} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  practiceFutureAvailabilitySearch(consumer, practiceFutureAvailabilitySearchCriteria) {
    const currentFunction = 'PracticeService.practiceFutureAvailabilitySearch';
    this.__logger.debug(currentFunction, 'Started', consumer, practiceFutureAvailabilitySearchCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(practiceFutureAvailabilitySearchCriteria instanceof AWSDKPracticeFutureAvailabilitySearchCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria" must be an instance of AWSDKPracticeFutureAvailabilitySearchCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const criteria = practiceFutureAvailabilitySearchCriteria;
    if (!Validator.isValidString(criteria.timeZone)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria.timeZone" must be a valid String');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.practiceOrSubCategory != null && !(criteria.practiceOrSubCategory instanceof AWSDKPractice) && !(criteria.practiceOrSubCategory instanceof AWSDKPracticeSubCategory)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria.practiceOrSubCategory" must be an instance of AWSDKPractice or AWSDKPracticeSubCategory');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.languageSpoken != null && !(criteria.languageSpoken instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria.languageSpoken" must be an instance of AWSDKLanguage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.appointmentDate != null && !(criteria.appointmentDate instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria.appointmentDate" must be an instance of Date');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (criteria.maxResults != null && !(Validator.isInt(criteria.maxResults))) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "practiceFutureAvailabilitySearchCriteria.maxResults" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'practiceFutureAvailabilitySearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "practiceFutureAvailabilitySearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumer.id.encryptedId);
    if (criteria.practiceOrSubCategory != null) {
      if (criteria.practiceOrSubCategory instanceof AWSDKPractice) {
        options.form.set('practiceId', criteria.practiceOrSubCategory.id.encryptedId);
      } else {
        options.form.set('practiceSubCategoryId', criteria.practiceOrSubCategory.id.encryptedId);
      }
    }
    if (criteria.searchTerm != null) {
      options.form.set('searchTerm', criteria.searchTerm);
    }
    if (criteria.languageSpoken != null) {
      options.form.set('languageSpoken', criteria.languageSpoken.value);
    }
    if (criteria.appointmentDate != null) {
      options.form.set('appointmentDate', Util.formatISODateTime(criteria.appointmentDate));
    }
    options.form.set('currentTime', Util.formatISODateTime(new Date()));
    options.form.set('timeZone', criteria.timeZone);
    if (criteria.maxResults != null) {
      options.form.set('maxResults', criteria.maxResults);
    }
    return this.executeRequest(options, AWSDKAvailabilityList)
      .then((availablilityList) => {
        this.__logger.debug(currentFunction, 'Got response', availablilityList);
        this.updateUserAuthEntry(consumer, availablilityList.authToken);
        return availablilityList;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieve the collection of {@link model.AWSDKOnDemandSpecialty[]} associated with the given {@link model.AWSDKPractice}, satisfying the optional search term
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} for whom to perform the search.
   * @param {model.AWSDKPractice} practice the {@link model.AWSDKPractice|AWSDKPractice} to search in.
   * @param {String} [searchTerm] a term by which to limit the search
   * @returns {Promise<model.AWSDKPractice|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKPractice|AWSDKPractice} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>The practice argument is not an instance of {@link model.AWSDKPractice|AWSDKPractice}
   * or the consumer argument is not an instance of {@link model.AWSDKConsumer}</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  getOnDemandSpecialties(consumer, practice, searchTerm) {
    const currentFunction = 'PracticeService.getOnDemandSpecialties';
    this.__logger.debug(currentFunction, 'Started', consumer, practice, searchTerm);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "consumer" must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(practice instanceof AWSDKPractice)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "practice" must be an instance of AWSDKPractice');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(practice.links, 'specialtiesInPracticeSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('practice does not have a "specialtiesInPracticeSearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumer.id.encryptedId);
    options.form.set('searchTerm', searchTerm);
    return this.executeRequest(options, AWSDKOnDemandSpecialtiesResponse).then((response) => {
      this.__logger.debug(currentFunction, 'Got response', response);
      this.updateUserAuthEntry(consumer, response.authToken);
      return response.specialties;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    });
  }

  /**
   * Get an instance of {@link model.AWSDKPracticeSearchCriteria|AWSDKPracticeSearchCriteria} to use when searching for practices. <br>
   * @param {Object} searchCriteria the search criteria in JSON Format
   * @returns {model.AWSDKPracticeSearchCriteria} returns an instance of a {@link model.AWSDKPracticeSearchCriteria|AWSDKPracticeSearchCriteria} object
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </tbody>
   * </table>
   * @since 1.3.0
   */
  getNewPracticeSearchCriteria(searchCriteria) {
    const currentFunction = 'PracticeService.getNewPracticeSearchCriteria';
    if (searchCriteria === null) {
      const error = AWSDKError.AWSDKIllegalArgument('searchCriteria argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    return new AWSDKPracticeSearchCriteria(searchCriteria);
  }

  /**
   * This method allows for an authenticated and unauthenticated search for a list of {@link model.AWSDKPracticeSearchResult|AWSDKPracticeSearchResult} based on type. Additionally, the caller can pass in the language and maxResults
   * as a way of filtering the response list further.
   * @param {String} [practiceType] the practice type to search for. Possible values are: DEFAULT, EXCLUSIVE, NORMAL. If no practiceType is passed, this method returns practices of DEFAULT type.
   * @param {model.AWSDKConsumer} [consumer] the {@link model.AWSDKConsumer|AWSDKConsumer} context for authenticated searches
   * @param {model.AWSDKLanguage} [language] filters the list of practice based on language
   * @param {Number} [maxResults] an integer which specifies the maximum number of results to return.
   * @returns {Promise<model.AWSDKPracticeSearchResult[]|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKPracticeSearchResult[]|AWSDKPracticeSearchResult[]} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>One of the arguments is not the correct type</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  findPracticesByType(practiceType, consumer, language, maxResults) {
    const currentFunction = 'PracticeService.findPracticesByType';
    this.__logger.debug(currentFunction, 'Started', consumer, practiceType, language, maxResults);
    return this.findPractices(this.getNewPracticeSearchCriteria({ practiceType, sourceId: null, consumer, language, maxResults }));
  }

  /**
   * This method allows for an authenticated and unauthenticated search for a list of {@link model.AWSDKPracticeSearchResult|AWSDKPracticeSearchResult} based on a set of search criteria. The caller can set the practice type, sourceId, language and maxResults of the AWSDKPracticeSearchCriteria.
   * as a way of filtering the response list further.
   * @param {model.AWSDKPracticeSearchCriteria} practiceSearchCriteria the {@link model.AWSDKPracticeSearchCriteria|AWSDKPracticeSearchCriteria} context for searches
   * @returns {Promise<model.AWSDKPracticeSearchResult[]|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKPracticeSearchResult[]|AWSDKPracticeSearchResult[]} or will
   * be rejected with a {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>One of the arguments is not the correct type</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.3.0
   */
  findPractices(practiceSearchCriteria) {
    const currentFunction = 'PracticeService.findPractices';
    this.__logger.debug(currentFunction, 'Started', practiceSearchCriteria);
    if (!(practiceSearchCriteria instanceof AWSDKPracticeSearchCriteria)) {
      const error = AWSDKError.AWSDKIllegalArgument('practiceSearchCriteria is null or not an instance of AWSDKPracticeSearchCriteria');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (practiceSearchCriteria.consumer != null && !(practiceSearchCriteria.consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (practiceSearchCriteria.practiceType != null) {
      if (!Validator.isValidString(practiceSearchCriteria.practiceType)) {
        const error = AWSDKError.AWSDKIllegalArgument('Argument "practiceType" must be a valid String');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      } else if (['NORMAL', 'DEFAULT', 'EXCLUSIVE'].indexOf(practiceSearchCriteria.practiceType.toUpperCase()) === -1) {
        const error = AWSDKError.AWSDKValidationError(practiceSearchCriteria.practiceType.toString());
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    if (practiceSearchCriteria.sourceId != null) {
      if (!Validator.isValidString(practiceSearchCriteria.sourceId)) {
        const error = AWSDKError.AWSDKIllegalArgument('Argument "sourceId" must be a valid String');
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    }
    if (practiceSearchCriteria.maxResults != null && !Validator.isInt(practiceSearchCriteria.maxResults)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "maxResults" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (practiceSearchCriteria.language != null && !(practiceSearchCriteria.language instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "language" must be of type AWSDKLanguage');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'practiceSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('PracticeService does not have a "practiceSearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    if (practiceSearchCriteria.consumer != null) {
      options.auth = this.getUserAuth(practiceSearchCriteria.consumer);
      if (options.auth == null) {
        const error = AWSDKError.AWSDKConsumerNotAuthenticated();
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
    } else {
      options.auth = this.getSdkAuth();
    }
    if (practiceSearchCriteria.consumer) {
      options.form.set('memberId', practiceSearchCriteria.consumer.id.encryptedId);
    }
    if (practiceSearchCriteria.practiceType) {
      options.form.set('type', practiceSearchCriteria.practiceType.toUpperCase());
    }
    if (practiceSearchCriteria.sourceId) {
      options.form.set('practiceSourceId', practiceSearchCriteria.sourceId);
    }
    if (practiceSearchCriteria.language) {
      options.form.set('languageSpoken', practiceSearchCriteria.language.resourceKey);
    }
    options.form.set('maxResults', practiceSearchCriteria.maxResults);
    return this.executeRequest(options, AWSDKPracticeSearchResponse)
      .then((practiceSearchResponse) => {
        this.__logger.debug(currentFunction, 'Got response', practiceSearchResponse);
        if (practiceSearchCriteria.consumer != null) {
          this.updateUserAuthEntry(practiceSearchCriteria.consumer, practiceSearchResponse.authToken);
        }
        return practiceSearchResponse.practiceSearchResults;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method returns a filtered list of {@link model.AWSDKPractice|AWSDKPractice} objects based on the provided {@link model.AWSDKPracticeCategory|AWSDKPracticeCategory}
   * @param {model.AWSDKPractice[]} practices the list of {@link model.AWSDKPractice|AWSDKPractice} objects to filter
   * @param {model.AWSDKPracticeCategory} practiceCategory the {@link model.AWSDKPracticeCategory|AWSDKPracticeCategory} used to filter the list of practices
   * @returns {model.AWSDKPractice[]} the list of practices filtered by the practiceCategory
   * @throws {error.AWSDKError} with an {@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument} if practices is not a {model.AWSDKPractice[]} or practiceCategory is not a {model.AWSDKPracticeCategory}
   * @since 1.2.0
   */
  getPracticesByCategory(practices, practiceCategory) {
    const currentFunction = 'PracticeService.getPracticesByCategory';
    this.__logger.debug(currentFunction, 'Started', practices, practiceCategory);
    if (!practices || !Array.isArray(practices)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "practices" must be of type AWSDKPractice[]');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (!(practiceCategory instanceof AWSDKPracticeCategory)) {
      const error = AWSDKError.AWSDKIllegalArgument('Argument "practiceCategory" must be of type AWSDKPracticeCategory');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }

    const subCategoryIds = practiceCategory.subCategories.map(s => s.id.encryptedId);

    return practices.filter((p) => {
      if (!(p instanceof AWSDKPractice)) {
        const error = AWSDKError.AWSDKIllegalArgument('Argument "practices" must be of type AWSDKPractice[]');
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      }
      return p.subCategoryIds.map(s => s.encryptedId).some(s => subCategoryIds.includes(s));
    });
  }
}
export default PracticeService;
