/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
/**
 * Enum for AWSDKErrorCode.
 * @readonly
 * @enum {string}
 */
const AWSDKErrorCode = {
  /**
   * A client attempted to access a resource that it does not have permission to access. For example, a consumer tried
   * to get secure messages that belong to another consumer.
   * @since 1.0.0
   */
  authenticationAccessDenied: 'authenticationAccessDenied',
  /**
   * The authenticate session has expired.
   * @since 1.0.0
   */
  authenticationSessionExpired: 'authenticationSessionExpired',
  /**
   * certificatePinningError while making a request.
   * @since 1.0.0
   */
  certificatePinningError: 'certificatePinningError',
  /**
   * Consumer already in a visit.
   * @since 1.0.0
   */
  consumerAlreadyInVisit: 'consumerAlreadyInVisit',
  /**
   * Consumer is busy.
   * @since 1.0.0
   */
  consumerBusy: 'consumerBusy',
  /**
   * Consumer not authenticated.
   * @since 1.0.0
   */
  consumerNotAuthenticated: 'consumerNotAuthenticated',
  /**
   * Consumer not found.
   * @since 1.0.0
   */
  consumerNotFound: 'consumerNotFound',
  /**
   * Cost validation has not been finished.
   * @since 1.0.0
   */
  costCalculationNotFinishedError: 'costCalculationNotFinishedError',
  /**
   * Credit card used as payment method has been declined.
   * @since 1.0.0
   */
  creditCardDeclinedError: 'creditCardDeclinedError',
  /**
   * Invalid CVV code for credit card used as payment method.
   * @since 1.0.0
   */
  creditCardInvalidCVV: 'creditCardInvalidCVV',
  /**
   * Invalid zip code for credit card used as payment method.
   * @since 1.0.0
   */
  creditCardInvalidZipCode: 'creditCardInvalidZipCode',
  /**
   * Credit card used as payment method validation has failed.
   * @since 1.0.0
   */
  creditCardValidationError: 'creditCardValidationError',
  /**
   * Dependent not found.
   * @since 1.1.0
   */
  dependentNotFound: 'dependentNotFound',
  /**
   * Dependent overage.
   * @since 1.1.0
   */
  dependentOverage: 'dependentOverage',
  /**
   * Dependent's date of birth is in the future.
   * @since 1.1.0
   */
  dependentDobInFuture: 'dependentDobInFuture',
  /**
   * Dependent match found
   * @since 1.3.0
   */
  dependentMatchFound: 'dependentMatchFound',
  /**
   * Empty response from rest call.
   * @since 1.0.0
   */
  emptyResponseError: 'emptyResponseError',
  /**
   * Feedback not sent.
   * @since 1.0.0
   */
  feedbackNotSubmittedError: 'feedbackNotSubmittedError',
  /**
   * A required field in an object is not set or has an incorrect value.
   *
   * @since 1.0.0
   */
  fieldValidationError: 'fieldValidationError',
  /**
   * Illegal Argument.
   * @since 1.0.0
   */
  illegalArgument: 'illegalArgument',
  /**
   * Internal Error.
   * @since 1.0.0
   */
  internalError: 'internalError',
  /**
   * Validation of coupon code failed.
   * @since 1.0.0
   */
  invalidCouponError: 'invalidCouponError',
  /**
   * Validation of date failed.
   * @since 1.1.0
   */
  invalidDate: 'invalidDate',
  /**
   * Validation of date format failed.
   * @since 1.1.0
   */
  invalidDateFormat: 'invalidDateFormat',
  /**
   * Validation of enum failed.
   * @since 1.1.0
   */
  invalidEnum: 'invalidEnum',
  /**
   * Validation of enum format failed.
   * @since 1.1.0
   */
  invalidEnumFormat: 'invalidEnumFormat',
  /**
   * Invalid password.
   * @since 1.1.0
   */
  invalidPassword: 'invalidPassword',
  /**
   * Validation of reminder option failed.
   * @since 1.1.0
   */
  invalidReminderOption: 'invalidReminderOption',
  /**
   * Validation of service key failed.
   * @since 1.0.0
   */
  invalidServiceKeyError: 'invalidServiceKeyError',
  /**
   * locale is not supported by caretalks.
   * @since 1.0.0
   */
  localeNotSupported: 'localeNotSupported',
  /**
   * No payment information was found for consumer.
   *
   * @since 1.0.0
   */
  noPaymentInformationFound: 'noPaymentInformationFound',
  /**
   * The error contains an thrown Error.
   *
   * @since AWSDK 1.0
   */
  otherError: 'otherError',
  /**
   * Error parsing the response.
   * @since 1.0.0
   */
  parsingError: 'parsingError',
  /**
   * The authenticated user has not completed the registration process.
   * @since 1.0.0
   */
  partiallyRegisteredUser: 'partiallyRegisteredUser',
  /**
   * The server is not configured with a consumer provider policy disclaimer.
   * @since 1.0.0
   */
  privacyPolicyDisclaimerMissing: 'privacyPolicyDisclaimerMissing',
  /**
   * The provider is busy and no longer available.
   * @since 1.0.0
   */
  providerBusy: 'providerBusy',
  /**
   * Provider's waiting room is closed.
   * @since 1.0.0
   */
  providerNotAvailable: 'providerNotAvailable',
  /**
   * The provider could not be found.
   * @since 1.0.0
   */
  providerNotFound: 'providerNotFound',
  /**
   * Provider is not licensed for consumer's location.
   * @since 1.0.0
   */
  providerNotLicensedForConsumerLocation: 'providerNotLicensedForConsumerLocation',
  /**
   * The provider is not enabled to use video.
   * @since 1.0.0
   */
  providerVideoNotEnabled: 'providerVideoNotEnabled',
  /**
   * Rating not sent.
   * @since 1.0.0
   */
  ratingNotSubmittedError: 'ratingNotsubmittedError',
  /**
   * Generic Server Error Response.
   * @since 1.0.0
   */
  responseError: 'responseError',
  /**
   * Schedule appointment failed.
   * @since 1.1.0
   */
  scheduleAppointmentFailed: 'scheduleAppointmentFailed',
  /**
   * Start visit failed.
   * @since 1.0.0
   */
  startVisitFailed: 'startVisitFailed',
  /**
   * Appointment not found.
   * @since 1.1.0
   */
  appointmentNotFound: 'appointmentNotFound',
  /**
   * The SDK is uninitialized. Initialize it using {@link AWSDK#initialize};
   * @since 1.0.0
   */
  uninitialized: 'uninitialized',
  /**
   * Attempt to update the consumerAuthKey on the American Well telehealth platform failed.
   * @since 1.0.0
   */
  updateConsumerAuthKeyFailed: 'updateConsumerAuthKeyFailed',
  /**
   * Cannot register underage consumer.
   *
   * @since 1.0.0
   */
  validationConsumerUnderage: 'validationConsumerUnderage',
  /**
   * This email address is already being used by another user.
   *
   * @since 1.0.0
   */
  validationEmailInUse: 'validationEmailInUse',
  /**
   * This source id is already being used by another consumer.
   *
   * @since 1.0.0
   */
  validationSourceIdInUse: 'validationSourceIdInUse',
  /**
   * Validation of parameter failed.
   * @since 1.0.0
   */
  validationError: 'validationError',
  /**
   * The provided address is incomplete or malformed.
   *
   * @since 2.0.0
   */
  validationIncompleteAddress: 'validationIncompleteAddress',
  /**
   * More then one validation error.
   * @since 1.0.0
   */
  validationErrors: 'validationErrors',
  /**
   * One or more required parameters were missing or invalid in the request.
   * @since 1.0.0
   */
  validationRequiredParameterMissing: 'validationRequiredParameterMissing',
  /**
   * Visit has an invalid consumer callback number.
   * @since 1.0.0
   */
  visitConsumerCallbackInvalid: 'visitConsumerCallbackInvalid',
  /**
   * Visit has expired.
   * @since 1.0.0
   */
  visitExpired: 'visitExpired',
  /**
   * Visit is invalid.
   * @since 1.0.0
   */
  visitInvalid: 'visitInvalid',
  /**
   * Visit Summary not sent.
   * @since 1.0.0
   */
  visitSummaryNotSentError: 'visitSummaryNotSentError',
  /**
   * Visit not found.
   * @since 1.1.0
   */
  visitNotFound: 'visitNotFound',
  /**
   * Provider does not accept waiting room requests from consumer.
   * @since 1.0.0
   */
  waitingRoomAccessDenied: 'waitingRoomAccessDenied',
  /**
   * The length of the medication search text String does not meet requirements.
   * @since 1.0.0
   */
  medicationSearchTextTooShort: 'medicationSearchTextTooShort',
  /**
   * Consumer already registered.
   * @since 1.0.0
   */
  consumerAlreadyRegistered: 'consumerAlreadyRegistered',
  /**
   * The size of the attachment in kilobytes is larger than that supported by the American Well telehealth platform.
   * @since 1.0.0
   */
  attachmentSizeTooLarge: 'attachmentSizeTooLarge',
  /**
   * The mime type of the data is not supported by the American Well telehealth platform
   * @since 1.0.0
   */
  unsupportedMimeType: 'unsupportedMimeType',
  /**
   * The attachment failed the virus scan by the American Well telehealth platform.
   * @since 1.2.0
   */
  failedVirusScan: 'failedVirusScan',
  /**
   * The attachment already exists on the American Well telehealth platform.
   * @since 1.2.0
   */
  fileExists: 'fileExists',
  /**
   * The attachment couldnt be read by the American Well telehealth platform.
   * @since 1.2.0
   */
  fileReadError: 'fileReadError',
  /**
   * Consumer's health plan is feed controlled.
   * @since 1.0.0
   */
  healthPlanIsFeedControlled: 'healthPlanIsFeedControlled',
  /**
   * Inaccurate subscriber information was rejected by the eligibility check
   * @since 1.2.0
   */
  inaccurateSubscriberInfo: 'inaccurateSubscriberInfo',
  /**
   * Invalid values for either the subscriber id or suffix id of the health plan
   * @since 1.3.0
   */
  healthPlanSubscriptionEnrollmentError: 'healthPlanSubscriptionEnrollmentError',
  /**
   * The eligibility check returned an error.
   * @since 1.0.0
   */
  eligibilityCheckError: 'eligibilityCheckError',
  /**
   * No providers were found during the First Available search
   * @since 1.1.0
   */
  noProvidersAvailable: 'noProvidersAvailable',

  /**
   * A poll exceeded its alloted time
   * @since 1.1.0
   */
  pollingTimeout: 'pollingTimeout',

  /**
   * The specified on-demand specialty was not found
   * @since 1.1.0
   */
  onDemandSpecialtyNotFound: 'onDemandSpecialtyNotFound',

  /**
   * An API of the AWSDK was invoked improperly
   * @since 1.1.0
   */
  illegalUsage: 'illegalUsage',
  /**
   * The recipient of a secure message sent from a consumer cannot be a consumer.
   * @since 1.1.0
   */
  consumerRecipientError: 'consumerRecipientError',
  /**
   * The recipient of this secure message does not accept secure messages.
   * @since 1.1.0
   */
  doesNotAcceptSecureMessages: 'doesNotAcceptSecureMessages',
  /**
   * The email address associated with the request was not found.
   * @since 1.1.0
   */
  emailAddressNotFound: 'emailAddressNotFound',
  /**
   * The Telehealth platform is incompatible with this version of the sdk
   * @since 1.1.0
   */
  versionIncompatible: 'versionIncompatible',
  /**
   * The modified field is protected.
   * @since 1.1.0
   */
  modifiedProtectedField: 'modifiedProtectedField',
  /**
   * No preferred pharmacy was found for the consumer.
   * @since 1.2.0
   */
  noPreferredPharmacyFound: 'noPreferredPharmacyFound',
  /**
   * No shipping address was found for the consumer.
   * @since 1.2.0
   */
  noShippingAddressFound: 'noShippingAddressFound',
  /**
   * SpeedPass is not enabled on the system.
   * @since 1.2.0
   */
  speedPassDisabled: 'speedPassDisabled',
  /**
   * Consumer is not eligible for speedPass.
   * @since 1.2.0
   */
  consumerNotEligibleForSpeedPass: 'consumerNotEligibleForSpeedPass',
  /**
   * TimeZone is not a valid canonical timezone.
   * @since 1.4.0
   */
  invalidTimeZone: 'invalidTimeZone',
  /**
   * Invalid parameter
   * @since 1.2.0
   */
  invalidParameter: 'invalidParameter',
  /**
   * Guest email is already invited.
   * @since 2.0.0
   */
  guestEmailAlreadyInvited: 'guestEmailAlreadyInvited',
  /**
   * The requested video platform is unsupported by the SDK
   * @since 3.0.0
   */
  unsupportedVideoPlatform: 'unsupportedVideoPlatform',
  /**
   * The two-factor code is invalid.
   * @since 3.3.0
   */
  twoFactorAuthenticationInvalidCode: 'twoFactorAuthenticationInvalidCode',
  /**
   * The two-factor code has expired.
   * @since 3.3.0
   */
  twoFactorAuthenticationExpiredCode: 'twoFactorAuthenticationExpiredCode',
  /**
   * The consumer has reached the limit for incorrect two-factor code validation attempts.
   * @since 3.3.0
   */
  twoFactorAuthenticationMaxRetryReached: 'twoFactorAuthenticationMaxRetryReached',
  /**
   * The phone number provided for two-factor authentication does not have a valid format.
   * @since 3.3.0
   */
  twoFactorAuthenticationInvalidPhoneNumber: 'twoFactorAuthenticationInvalidPhoneNumber',
  /**
   * The operation is not supported, most likely due to server configurations.
   * @since 3.3.0
   */
  unsupportedOperation: 'unsupportedOperation',
  /**
   * The required height and weight measurements are missing
   * @since 3.4.0
   */
  requiredHeightAndWeightMeasurementsMissing: 'requiredHeightAndWeightMeasurementsMissing',
  /**
   * Missing authentication information
   * @since 3.5.0
   */
  authenticationInfoMissing: 'authenticationInfoMissing',
  /**
   * Authorization denied
   * @since 3.5.0
   */
  authorizationForbidden: 'authorizationForbidden',
  /**
   * Waiting room count is less than the required threshold to use the Video Callback feature
   * @since 3.5.0
   */
  waitingRoomPastVideoCallbackThreshold: 'waitingRoomPastVideoCallbackThreshold',
  /**
   * The Amwell Home platform wasn't able to use the provided phone number to initiate the video callback workflow
   * @since 3.5.0
   */
  videoCallbackIVRException: 'videoCallbackIVRException',
};

/**
 * @private
 */
AWSDKErrorCode.__codes = Object.values(AWSDKErrorCode);
Object.freeze(AWSDKErrorCode);
export default AWSDKErrorCode;
