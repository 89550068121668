/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKError from '../../error/awsdk_error';

import Logger from '../logger/logger';

class ResponseParser {
  /**
   * Parses response to an object.
   *
   * @param {Mixed} response the response to parse
   * @param {Object} Klass the class to return
   * @returns {Klass|String} The response parsed into an instance of the Klass or a string
   */
  static parse(response, Klass) {
    const logger = new Logger();
    logger.debug('responseParser.parse', `Response status ${response.status}`);
    let parsedResponse = null;
    switch (response.status) {
      case 200:
      case 201:
      case 202:
        logger.debug('responseParser.parse', 'Calling parseValidResponseBody');
        parsedResponse = ResponseParser.parseValidResponseBody(response, Klass);
        break;
      case 204: // no content
        parsedResponse = ResponseParser.parseEmptyResponseBody(response, Klass);
        break;
      case 400:
      case 401:
      case 403:
      case 404:
      case 409:
      case 500:
      case 503:
      default:
        logger.debug('responseParser.parse', 'Calling parseErrorResponse');
        parsedResponse = ResponseParser.parseErrorResponse(response).then((error) => { throw error; });
    }
    return parsedResponse;
  }

  static parseValidResponseBody(response, Klass) {
    const logger = new Logger();
    const contentType = response.headers.get('Content-type');
    const authToken = response.headers.get('X-Authentication-Token');
    logger.debug('responseParser.parseValidResponseBody', `Content type ${contentType}`);
    if (Klass == null && contentType != null && contentType !== 'application/json' && !(contentType.includes('text/'))) {
      logger.warn('responseParser.parseValidResponseBody', 'will return blob');
      return response.blob().then((blob) => {
        logger.debug('responseParser.parseValidResponseBody', 'Response body as blob');
        return blob;
      });
    }
    if (Klass != null) {
      return response.json()
        .then((json) => {
          logger.debug('responseParser.parseValidResponseBody', 'Response body as json', json);
          const values = json;
          if (authToken != null) {
            values.authToken = authToken;
          }
          const clientVersion = response.headers.get('X-Client-Version');
          if (clientVersion != null) {
            values.amWellPlatformVersion = clientVersion;
          }
          return new Klass(values);
        })
        .catch((err) => {
          logger.error('responseParser.parseValidResponseBody', 'error parsing json', err);
          throw AWSDKError.AWSDKParsingError(err);
        });
    }
    return response.text().then((text) => {
      logger.debug('responseParser.parseValidResponseBody', 'Response body as text', text);
      return text;
    });
  }

  static parseEmptyResponseBody(response, Klass) {
    const logger = new Logger();
    logger.debug('responseParser.parseEmptyResponseBody', Klass);
    const authToken = response.headers.get('X-Authentication-Token');
    if (Klass != null) {
      const values = {};
      if (authToken != null) {
        values.authToken = authToken;
      }
      return Promise.resolve(new Klass(values));
    }
    return Promise.resolve(null);
  }

  static parseErrorResponse(response) {
    const logger = new Logger();
    const contentType = response.headers.get('Content-type');
    logger.debug('responseParser.parseErrorResponse', `Content type ${contentType}`);
    return response.text()
      .then((text) => {
        logger.debug('responseParser.parseErrorResponse', 'Response body as text', text);
        let parsedError = null;
        try {
          const json = JSON.parse(text);
          const message = json.message ? json.message : 'no message';
          const olcError = json.olcError ? json.olcError : 'unknown';
          parsedError = ResponseParser.generateAWSDKErrorResponse(json, response.status, olcError, message);
        } catch (e) {
          parsedError = ResponseParser.generateAWSDKErrorResponse(text, response.status, 'unknown', 'could not parse response body as json', e);
        }
        logger.debug('responseParser.parseErrorResponse', 'returning parsed error', parsedError);
        return parsedError;
      });
  }

  static generateAWSDKErrorResponse(body, statusCode, olcError, message, error) {
    let responseError = null;
    if (statusCode === 400 && olcError === 'CREDIT_CARD_DECLINED_ERROR') {
      responseError = AWSDKError.AWSDKCreditCardDeclinedError();
    } else if (statusCode === 400 && olcError === 'CREDIT_CARD_INCORRECT_CVV') {
      responseError = AWSDKError.AWSDKCreditCardInvalidCVV();
    } else if (statusCode === 400 && olcError === 'CREDIT_CARD_INVALID_ZIP') {
      responseError = AWSDKError.AWSDKCreditCardInvalidZipCode();
    } else if (statusCode === 400 && olcError === 'CREDIT_CARD_VALIDATION_ERROR') {
      responseError = AWSDKError.AWSDKCreditCardValidationError();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_DATE')) {
      responseError = AWSDKError.AWSDKInvalidDate();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_DATE_FORMAT')) {
      responseError = AWSDKError.AWSDKInvalidDateFormat();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_ENUM')) {
      responseError = AWSDKError.AWSDKInvalidEnum();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_ENUM_FORMAT')) {
      responseError = AWSDKError.AWSDKInvalidEnumFormat();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_GROUP_KEY')) {
      responseError = AWSDKError.AWSDKInvalidServiceKeyError();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_PASSWORD')) {
      responseError = AWSDKError.AWSDKInvalidPassword();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_REQ_PARAM_MISSING')) {
      responseError = AWSDKError.AWSDKValidationRequiredParameterMissing();
    } else if (statusCode === 400 && (olcError === 'PROVIDER_NOT_AVAILABLE' || olcError === 'PROVIDER_OFFLINE')) {
      responseError = AWSDKError.AWSDKProviderNotAvailable(statusCode);
    } else if (statusCode === 400 && (olcError === 'SDK_CONFIGURATION_ERROR')) {
      responseError = AWSDKError.AWSDKUpdateConsumerAuthKeyFailed(statusCode);
    } else if (statusCode === 400 && (olcError === 'VALIDATION_MEMBER_UNDERAGE')) {
      responseError = AWSDKError.AWSDKValidationConsumerUnderage();
    } else if (statusCode === 400 && (olcError === 'EMAIL_ADDRESS_NOT_FOUND')) {
      responseError = AWSDKError.AWSDKEmailAddressNotFound();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_DEPENDENT_OVERAGE')) {
      responseError = AWSDKError.AWSDKDependentOverage();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_DEPENDENT_DOB_IN_FUTURE')) {
      responseError = AWSDKError.AWSDKDependentDobInFuture();
    } else if (statusCode === 400 && (olcError === 'VERSION_INCOMPATIBLE')) {
      responseError = AWSDKError.AWSDKVersionIncompatible();
    } else if (statusCode === 400 && (olcError === 'ATTACHMENT_SIZE_TOO_LARGE')) {
      responseError = AWSDKError.AWSDKAttachmentSizeTooLarge();
    } else if (statusCode === 400 && (olcError === 'FILE_TYPE_INVALID')) {
      responseError = AWSDKError.AWSDKUnsupportedMimeType();
    } else if (statusCode === 400 && (olcError === 'FILE_VIRUS_SCAN_FAILED')) {
      responseError = AWSDKError.AWSDKFailedVirusScan();
    } else if (statusCode === 400 && (olcError === 'FILE_READ_ERROR')) {
      responseError = AWSDKError.AWSDKFileReadError();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_ELIG_INFO')) {
      responseError = AWSDKError.AWSDKInaccurateSubscriberInfo();
    } else if (statusCode === 400 && (olcError === 'VALIDATION_REQ_PARAM_INVALID')) {
      responseError = AWSDKError.AWSDKInvalidParameter(message);
    } else if (statusCode === 400 && (olcError === 'VALIDATION_ENROLLMENT_ERROR')) {
      responseError = AWSDKError.AWSDKHealthPlanSubscriptionEnrollmentError(message);
    } else if (statusCode === 400 && (olcError === 'VALIDATION_BAD_TIMEZONE_FORMAT')) {
      responseError = AWSDKError.AWSDKInvalidTimeZone();
    } else if (statusCode === 400 && (olcError === 'GUEST_EMAIL_ALREADY_INVITED')) {
      responseError = AWSDKError.AWSDKGuestEmailAlreadyInvited();
    } else if (statusCode === 400 && (olcError === 'AUTH_TWO_FACTOR_INVALID_CODE')) {
      responseError = AWSDKError.AWSDKTwoFactorInvalidCode();
    } else if (statusCode === 400 && (olcError === 'AUTH_TWO_FACTOR_EXPIRED_CODE')) {
      responseError = AWSDKError.AWSDKTwoFactorExpiredCode();
    } else if (statusCode === 400 && (olcError === 'AUTH_TWO_FACTOR_MAX_RETRY_REACHED')) {
      responseError = AWSDKError.AWSDKTwoFactorMaxRetryReached();
    } else if (statusCode === 400 && (olcError === 'AUTH_TWO_FACTOR_INVALID_PHONE_NUMBER')) {
      responseError = AWSDKError.AWSDKTwoFactorInvalidPhoneNumber();
    } else if (statusCode === 400 && olcError === 'WR_COUNT_PAST_VIDEO_CALLBACK_THRESHOLD') {
      responseError = AWSDKError.AWSDKWaitingRoomPastVideoCallbackThreshold();
    } else if (statusCode === 400 && olcError === 'VIDEO_CALLBACK_IVR_EXCEPTION') {
      responseError = AWSDKError.AWSDKVideoCallbackIVRException();
    } else if (statusCode === 401 && (body && body.indexOf('Token expired') !== -1)) {
      responseError = AWSDKError.AWSDKAuthenticationSessionExpired();
    } else if (statusCode === 401) {
      responseError = AWSDKError.AWSDKAuthenticationAccessDenied();
    } else if (statusCode === 403 && olcError === 'AUTH_ACCESS_DENIED') {
      responseError = AWSDKError.AWSDKAuthenticationAccessDenied();
    } else if (statusCode === 403 && olcError === 'AUTHZ_FORBIDDEN') {
      responseError = AWSDKError.AWSDKAuthorizationForbidden();
    } else if (statusCode === 404 && olcError === 'DEPENDENT_NOT_FOUND') {
      responseError = AWSDKError.AWSDKDependentNotFound();
    } else if (statusCode === 404 && olcError === 'MEMBER_NOT_FOUND') {
      responseError = AWSDKError.AWSDKConsumerNotFound();
    } else if (statusCode === 404 && olcError === 'PROVIDER_NOT_FOUND') {
      responseError = AWSDKError.AWSDKProviderNotFound();
    } else if (statusCode === 404 && olcError === 'ENG_NOT_FOUND') {
      responseError = AWSDKError.AWSDKVisitNotFound();
    } else if (statusCode === 404 && olcError === 'ONDEMAND_SPECIALTY_NOT_FOUND') {
      responseError = AWSDKError.AWSDKOnDemandSpecialtyNotFound();
    } else if (statusCode === 404 && olcError === 'CREDIT_CARD_MISSING') {
      responseError = AWSDKError.AWSDKNoPaymentInformationFound();
    } else if (statusCode === 404 && olcError === 'MEMBER_PRIMARY_PHARMACY_NOT_FOUND') {
      responseError = AWSDKError.AWSDKNoPreferredPharmacyFound();
    } else if (statusCode === 404 && olcError === 'MEMBER_SHIPPING_ADDRESS_NOT_FOUND') {
      responseError = AWSDKError.AWSDKNoShippingAddressFound();
    } else if (statusCode === 404 && olcError === 'MEMBER_NOT_ELIGIBLE_FOR_SPEEDPASS') {
      responseError = AWSDKError.AWSDKConsumerNotEligibleForSpeedPass();
    } else if (statusCode === 404 && olcError === 'AUTH_INFO_MISSING') {
      responseError = AWSDKError.AWSDKAuthenticationInfoMissing();
    } else if (statusCode === 409 && olcError === 'AUTH_ACCESS_DENIED') {
      responseError = AWSDKError.AWSDKAuthenticationAccessDenied();
    } else if (statusCode === 409 && olcError === 'ENG_GENERIC_FAILURE') {
      responseError = AWSDKError.AWSDKStartVisitFailed();
    } else if (statusCode === 409 && olcError === 'ENG_INITIATOR_INVALID_PHONE_NUMBER') {
      responseError = AWSDKError.AWSDKVisitConsumerCallbackInvalid();
    } else if (statusCode === 409 && olcError === 'ENG_SCHEDULING_FAILURE') {
      responseError = AWSDKError.AWSDKScheduleAppointmentFailed();
    } else if (statusCode === 409 && olcError === 'ENG_USER_ALREADY_ACTIVE') {
      responseError = AWSDKError.AWSDKConsumerAlreadyInVisit();
    } else if (statusCode === 409 && olcError === 'MEMBER_BUSY') {
      responseError = AWSDKError.AWSDKConsumerBusy();
    } else if (statusCode === 409 && (olcError === 'PROVIDER_NOT_AVAILABLE' || olcError === 'PROVIDER_OFFLINE')) {
      responseError = AWSDKError.AWSDKProviderNotAvailable(statusCode);
    } else if (statusCode === 409 && olcError === 'PROVIDER_BUSY') {
      responseError = AWSDKError.AWSDKProviderBusy();
    } else if (statusCode === 409 && olcError === 'PROVIDER_NOT_LICENSED_FOR_MEMBER_STATE') {
      responseError = AWSDKError.AWSDKProviderNotLicensedForConsumerLocation();
    } else if (statusCode === 409 && (olcError === 'SDK_CONFIGURATION_ERROR')) {
      responseError = AWSDKError.AWSDKUpdateConsumerAuthKeyFailed(statusCode);
    } else if (statusCode === 409 && olcError === 'VALIDATION_EMAIL_IN_USE') {
      responseError = AWSDKError.AWSDKValidationEmailInUse();
    } else if (statusCode === 409 && olcError === 'VALIDATION_SOURCE_ID_IN_USE') {
      responseError = AWSDKError.AWSDKValidationSourceIdInUse();
    } else if (statusCode === 409 && olcError === 'WAITING_ROOM_ACCESS_DENIED') {
      responseError = AWSDKError.AWSDKWaitingRoomAccessDenied();
    } else if (statusCode === 409 && olcError === 'VALIDATION_FILE_EXISTS') {
      responseError = AWSDKError.AWSDKFileExists();
    } else if (statusCode === 409 && (olcError === 'VALIDATION_DEPENDENT_MATCH_FOUND')) {
      responseError = AWSDKError.AWSDKDependentMatchFound();
    } else if (statusCode === 409 && (olcError === 'ENG_INITIATOR_REQUIRED_VITALS_MISSING')) {
      responseError = AWSDKError.AWSDKRequiredHeightAndWeightMeasurementsMissing();
    } else if (statusCode === 410 && olcError === 'ENG_EXPIRED') {
      responseError = AWSDKError.AWSDKVisitExpired();
    } else if (statusCode === 410 && olcError === 'ENG_INVALID_DISPOSITION') {
      responseError = AWSDKError.AWSDKVisitInvalid();
    } else if (statusCode === 500 && olcError === 'PRIVACY_DISCLAIMER_MISSING') {
      responseError = AWSDKError.AWSDKPrivacyPolicyDisclaimerMissing();
    } else {
      responseError = AWSDKError.AWSDKResponseError(message, olcError, statusCode);
      if (error != null) {
        responseError.errors = [error];
      }
    }
    return responseError;
  }
}

export default ResponseParser;
